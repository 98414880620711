import React, { useContext, useEffect, useMemo, useState } from 'react';
import { style } from '../../Project/projectDashboard/style';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Popover,
} from '@material-ui/core';
import codeChurnIcon from '../../../assets/codeChurn.png';
import storyPointIcon from '../../../assets/storyPoint.webp';
import slackIcon from '../../../assets/slackIcon.png';
import msg from '../../../assets/msg.png';
import check from '../../../assets/check.png';
import CardDetail from './cardDetail';
import pr from '../../../assets/pr.png';
import commit from '../../../assets/commit.png';
import HealthModal from './Modal/Health';
import ProcessModal from './Modal/Process';
import QualityModal from './Modal/Quality';
import axios from '../../../utils/axiosHelper';
import { config } from '../../../Config/permission';
import { Tooltip } from '@mui/material';
import { GlobalData } from '../../../context/globalData';
import processHealth from '../../../assets/processHealth.png';
import qualityHealth from '../../../assets/qualityHealth.png';
import communicationHealth from '../../../assets/communicationHealth.png';
import wipImg from '../../../assets/WIP.jpeg';
import executionHealth from '../../../assets/executionHealth.png';
import InfoIcon from '@material-ui/icons/Info';
import teamMemberImg from '../../../assets/users.png';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TeamCommunication from './Modal/TeamCommunication';
import utilizationVsPerformanceIcon from '../../../assets/UtilizationvsPerformance.jpeg';
import HealthColorWrapper from '../../../components/overview/healthColorWrapper';
import WIPimg from '../../../assets/WIP.jpeg';
import DxiLogo from '../../../assets/dxiImg.jpeg';
import flowEfficiencyImg from '../../../assets/FlowEfficiency.jpeg';
import devCoaching from '../../../assets/sonarcube.png';
import {
  peReivewSubStatus,
  prReivewStatusList,
} from '../../../Config/statusData';
import UtilizationPerformance from '../../../components/utilizationVsPerformance';
import WorkAlocationChart from '../../Project/projectDashboard/graphs/workAlocationChart';
import GraphCard from '../../Project/projectDashboard/graphCard';
import DeveloperCoachingPopup from './Modal/developerCoaching';
import DxiModel from './Modal/DxiModel';

const useStyles = style;
function Header({
  projectId,
  teamId,
  capacityAccuracy,
  codeChurn,
  storyPoint,
  commitReference,
  resourceHealth,
  CommitWithoutPullRequestCount,
  commitWithPr,
  commitWithoutPr,
  prReviewTime,
  firstTimePass,
  mostReviewed,
  commitPr,
  resourceProcess,
  resourceQuality,
  StoryPointCount,
  date = '',
  riskAndRange,
  teamQualityHealth,
  teamProcessHealth,
  teamProductivity,
  teamCommunication,
  teamPlanningAccuracy,
  teamPrReviewTime,
  setChildViewAll,
  teamData,
  teamAvgAging,
  flowEfficiency,
  trendItem,
  lacgacy,
  numberOfStoryPoint,
  TotalTestCasesQAValue,
  escapedDefectsQAValues,
  testEfficiencyQAValues,
  meanTimeToRepairQAValues,
  testExecutionRateQAValues,
  barData,
  slackHis,
  SpeedingTransitionsRateMetrics,
  workBreakDown,
  developerCoachingValues,
  ticketWithoutEpicAndStoryValues,
  developerAttentionTimeValues,
  ticketWithoutEstimation,
  teamKsiIndexCount,
  hyginScore,
  prSize,
  defectResolutionTime,
  TeamQualityInfrenceAiResponse,
  resourceQualityAiResponse,
  testCaseEfficiency,
  bugDoneAndIntro,
  prodBugIntroAndFeature,
  noOfProdBugIntro,
  noOfProdBugFixed,
  totalNumberFeatures,
  prodBugIntroCheckIn,
  totalCheckIn,
  metricPermission,
  defectInBacklog,
  defectInProgress,
  TicketWithoutCommitReference,
  DxiMetric,
}) {
  const classes = useStyles();
  const healthColor = {
    'Bad Health': '#EB5757',
    'Average Health': '#F2994A',
    'Good Health': '#219653',
  };
  const HealthText = {
    'Bad Health': 'Poor',
    'Average Health': 'Average',
    'Good Health': 'Good',
  };

  const healthColor1 = {
    BAD: '#EB5757',
    AVERAGE: '#F2994A',
    GOOD: '#219653',
  };
  const HealthText1 = {
    BAD: 'Poor',
    AVERAGE: 'Average',
    GOOD: 'Good',
  };

  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);
  const [viewAll, setViewAll] = useState(false);
  const [teamMember, setTeamMember] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, type, id) => {
    setAnchorEl({ open: event?.currentTarget, type, id: id ? id : null });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isUtilizationOpen, setIsUtilizationOpen] = useState(false);
  const open = Boolean(anchorEl?.open);
  const id = open ? 'simple-popover' : undefined;
  const getProductivityHealthStatus = useMemo(() => {
    if (resourceHealth?.healthScore < 40) {
      return 'Bad Health';
    } else if (resourceHealth?.healthScore > 60) {
      return 'Good Health';
    } else {
      return 'Average Health';
    }
  }, [resourceHealth?.healthScore]);

  useEffect(() => {
    if (!teamId) return;
    axios
      .get(`/api/v2/teams/teamMember/${projectId}?teamId=${teamId}`)
      .then((res) => {
        setTeamMember(res?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, [teamId]);

  useEffect(() => {
    setChildViewAll(viewAll);
  }, [viewAll]);

  const viewMoreHandler = (name, list, status) => {
    trendItem({ data: name, list: list, status: status });
  };

  useEffect(() => {
    trendItem();
  }, [isUtilizationOpen]);

  return (
    <Box>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        {/* ===========================================> Team Health Metric <============================================= */}
        {teamId !== 504 && (
          <>
            {teamProductivity && (
              <>
                {config.team.projectLevelTeamProductivity ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <span
                              style={{
                                // color: healthColor[teamProductivity?.HealthStatus],
                                fontSize: '20px',
                                display: 'flex',
                              }}
                            >
                              <div>
                                {teamProductivity?.healthScore?.toFixed()}%
                              </div>
                              {/* {HealthText[teamProductivity?.HealthStatus]} */}
                              <HealthColorWrapper
                                status={HealthText[
                                  teamProductivity?.HealthStatus
                                ].toUpperCase()}
                              />
                              <Button
                                // variant="outlined"
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  color: '#5EBDCC',
                                  fontSize: '10px',
                                }}
                                onClick={(e) =>
                                  handleClick(e, 'Team health', teamId)
                                }
                              >
                                {' '}
                                View Detail
                              </Button>
                            </span>
                          }
                          img={executionHealth}
                          name={'Team Productivity'}
                          classes={classes}
                          define={true}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}
            {config.team.projectLevelTeamQualityHealth && (
              <>
                {teamQualityHealth ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <span
                              style={{
                                fontSize: '20px',
                                display: 'flex',
                              }}
                            >
                              <div>
                                {(
                                  teamQualityHealth?.healthScore * 100
                                ).toFixed()}
                                %
                              </div>
                              <HealthColorWrapper
                                status={HealthText1[
                                  teamQualityHealth?.healthStatus.toUpperCase()
                                ].toUpperCase()}
                              />
                              <Button
                                // variant="outlined"
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  color: '#5EBDCC',
                                  fontSize: '10px',
                                }}
                                onClick={(e) =>
                                  handleClick(e, 'Team quality', teamId)
                                }
                              >
                                {' '}
                                View Detail
                              </Button>
                            </span>
                          }
                          img={qualityHealth}
                          name={'Quality Health'}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}
            {teamProcessHealth && (
              <>
                {config.team.projectLevelTeamProcessHealth ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <span
                              style={{
                                // color:
                                //   healthColor1[teamProcessHealth?.HealthStatus],
                                fontSize: '20px',
                                display: 'flex',
                              }}
                            >
                              <div>
                                {teamProcessHealth?.HealthScore?.toFixed()}%
                              </div>
                              {/* {HealthText1[teamProcessHealth?.HealthStatus]} */}
                              <HealthColorWrapper
                                status={HealthText1[
                                  teamProcessHealth?.HealthStatus
                                ].toUpperCase()}
                              />
                              <Button
                                // variant="outlined"
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  color: '#5EBDCC',
                                  fontSize: '10px',
                                }}
                                onClick={(e) =>
                                  handleClick(e, 'Team process', teamId)
                                }
                              >
                                {' '}
                                View Detail
                              </Button>
                            </span>
                          }
                          img={processHealth}
                          name={'Process Health'}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}

            {teamCommunication && (
              <>
                {config.team.projectLevelTeamCollaboration ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <span
                              style={{
                                // color:
                                //   healthColor1[teamCommunication?.HealthStatus],
                                fontSize: '20px',
                                display: 'flex',
                              }}
                            >
                              <div>
                                {teamCommunication?.HealthScore?.toFixed()}%
                              </div>
                              {/* {HealthText1[teamCommunication?.HealthStatus]} */}
                              <HealthColorWrapper
                                status={HealthText1[
                                  teamCommunication?.HealthStatus
                                ]?.toUpperCase()}
                              />
                              <Button
                                // variant="outlined"
                                color='primary'
                                style={{
                                  textTransform: 'capitalize',
                                  color: '#5EBDCC',
                                  fontSize: '10px',
                                }}
                                onClick={(e) =>
                                  handleClick(e, 'Team Communication', teamId)
                                }
                              >
                                {' '}
                                View Detail
                              </Button>
                            </span>
                          }
                          img={communicationHealth}
                          name={'Code Review Collaboration'}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}

            {metricPermission?.isMetricDxi ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <CardDetail
                      value={
                        <span
                          style={{
                            // color:
                            //   healthColor1[teamCommunication?.HealthStatus],
                            fontSize: '20px',
                            display: 'flex',
                          }}
                        >
                          <div>{DxiMetric?.DXI}%</div>
                          {/* {HealthText1[DxiMetric?.HealthStatus]} */}
                          <HealthColorWrapper
                            status={HealthText1[
                              DxiMetric?.DXIStatus
                            ]?.toUpperCase()}
                          />
                          <Button
                            // variant="outlined"
                            color='primary'
                            style={{
                              textTransform: 'capitalize',
                              color: '#5EBDCC',
                              fontSize: '10px',
                            }}
                            onClick={(e) => handleClick(e, 'Dxi', teamId)}
                          >
                            {' '}
                            View Detail
                          </Button>
                        </span>
                      }
                      img={DxiLogo}
                      name={'Developer Experience Index'}
                      classes={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </>
        )}
        {/* ===========================================> Resource Health Metric <============================================= */}

        {resourceHealth && (
          <>
            {config.contributor.projectLevelTeamContributorHealth ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <CardDetail
                      value={
                        <span
                          style={{
                            // color: healthColor[getProductivityHealthStatus],
                            fontSize: '20px',
                            display: 'flex',
                          }}
                        >
                          <div>{resourceHealth?.healthScore?.toFixed()}%</div>
                          {/* {HealthText[getProductivityHealthStatus]} */}
                          <HealthColorWrapper
                            status={HealthText[
                              getProductivityHealthStatus
                            ].toUpperCase()}
                          />
                          <Button
                            // variant="outlined"
                            color='primary'
                            style={{
                              textTransform: 'capitalize',
                              color: '#5EBDCC',
                              fontSize: '10px',
                            }}
                            onClick={(e) => handleClick(e, 'health')}
                          >
                            {' '}
                            View Detail
                          </Button>
                        </span>
                      }
                      img={executionHealth}
                      name={'Contributor Health'}
                      classes={classes}
                      define={false}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </>
        )}

        {config.contributor.projectLevelTeamContributorQualityHealth && (
          <>
            {resourceQuality ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <CardDetail
                      value={
                        <span
                          style={{
                            // color:
                            //   healthColor1[
                            //     resourceQuality?.healthStatus.toUpperCase()
                            //   ],
                            fontSize: '20px',
                            display: 'flex',
                          }}
                        >
                          {/* {
                            HealthText1[
                              resourceQuality?.healthStatus.toUpperCase()
                            ]
                          } */}
                          <div>
                            {(resourceQuality?.healthScore * 100).toFixed()}%
                          </div>
                          <HealthColorWrapper
                            status={HealthText1[
                              resourceQuality?.healthStatus.toUpperCase()
                            ].toUpperCase()}
                          />
                          <Button
                            // variant="outlined"
                            color='primary'
                            style={{
                              textTransform: 'capitalize',
                              color: '#5EBDCC',
                              fontSize: '10px',
                            }}
                            onClick={(e) => handleClick(e, 'quality')}
                          >
                            {' '}
                            View Detail
                          </Button>
                        </span>
                      }
                      img={qualityHealth}
                      name={'Quality Health'}
                      classes={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </>
        )}
        {resourceProcess && (
          <>
            {config.contributor.projectLevelTeamContributorProcessHealth ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <CardDetail
                      value={
                        <span
                          style={{
                            // color: healthColor1[resourceProcess?.HealthStatus],
                            fontSize: '20px',
                            display: 'flex',
                          }}
                        >
                          <div>
                            {(resourceProcess?.HealthScore * 100).toFixed()}%
                          </div>
                          {/* {HealthText1[resourceProcess?.HealthStatus]} */}
                          <HealthColorWrapper
                            status={HealthText1[
                              resourceProcess?.HealthStatus
                            ].toUpperCase()}
                          />
                          <Button
                            // variant="outlined"
                            color='primary'
                            style={{
                              textTransform: 'capitalize',
                              color: '#5EBDCC',
                              fontSize: '10px',
                            }}
                            onClick={(e) => handleClick(e, 'process')}
                          >
                            {' '}
                            View Detail
                          </Button>
                        </span>
                      }
                      img={processHealth}
                      name={'Process Health'}
                      classes={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </>
        )}
        {/* <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <CardDetail
                value={capacityAccuracy}
                img={capacityAccuracyIcon}
                name={"Capacity Accuracy"}
                classes={classes}
                status={riskAndRange?.capacityAccuracyData}
              />
            </CardContent>
          </Card>
        </Grid> */}
        {teamData && (
          <>
            {config.team.projectLevelTeamNumberOfTeamMember ? (
              <Grid item xs={3}>
                <Card className={`${classes.card} customCard`}>
                  <CardContent>
                    <CardDetail
                      value={teamMember}
                      img={teamMemberImg}
                      name={'Number Of Team Member'}
                      classes={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </>
        )}
        {teamId === 504 && (
          <>
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <CardDetail
                    value={`${TotalTestCasesQAValue} (Count)`}
                    img={check}
                    name={'Test Case Completed'}
                    classes={classes}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: '#fdeeee' }}
                    >
                      <img src={codeChurnIcon} alt='basket' />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: '24px' }}>
                        {escapedDefectsQAValues} (Count)
                      </h3>
                      <h6>
                        Escaped Defects{' '}
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI('Escaped Defects');
                          }}
                        >
                          {/* <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          /> */}
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler('Escaped Defects', teamId)
                          }
                        >
                          View More
                        </span>
                      </h6>
                      {/* <Tooltip
                        title={
                          <>
                            <h6>
                              Status : {riskAndRange?.codeChurnData?.risk} Risk
                            </h6>
                            <h6>
                              Target : {riskAndRange?.codeChurnData?.from}% to{" "}
                              {riskAndRange?.codeChurnData?.to}%
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Ask for a pre-commit code review and design
                                documentation.
                              </li>
                              <li>Ask to split the work or assign a mentor.</li>
                              <li>
                                Ask a more senior engineer to assess what “good
                                enough” is in the context of the project.{" "}
                              </li>
                              <li>
                                If the problem is difficult, or if the domain is
                                unfamiliar, bring in another engineer to pair
                                program.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus"> 
                          <HealthColorWrapper
                            status={riskAndRange?.codeChurnData?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: '#fdeeee' }}
                    >
                      <img src={codeChurnIcon} alt='basket' />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: '24px' }}>
                        {testEfficiencyQAValues} %
                      </h3>
                      <h6>
                        Test Efficiency
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI('Test Efficiency');
                          }}
                        >
                          {/* <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          /> */}
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() => viewMoreHandler('Test Efficiency')}
                        >
                          View More
                        </span>
                      </h6>
                      {/* <Tooltip
                        title={
                          <>
                            <h6>
                              Status : {riskAndRange?.codeChurnData?.risk} Risk
                            </h6>
                            <h6>
                              Target : {riskAndRange?.codeChurnData?.from}% to{" "}
                              {riskAndRange?.codeChurnData?.to}%
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Ask for a pre-commit code review and design
                                documentation.
                              </li>
                              <li>Ask to split the work or assign a mentor.</li>
                              <li>
                                Ask a more senior engineer to assess what “good
                                enough” is in the context of the project.{" "}
                              </li>
                              <li>
                                If the problem is difficult, or if the domain is
                                unfamiliar, bring in another engineer to pair
                                program.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus"> 
                          <HealthColorWrapper
                            status={riskAndRange?.codeChurnData?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: '#fdeeee' }}
                    >
                      <img src={codeChurnIcon} alt='basket' />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: '24px' }}>
                        {meanTimeToRepairQAValues} (Day's)
                      </h3>
                      <h6>
                        Mean Time To Repair
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI('Mean Time To Repair');
                          }}
                        >
                          {/* <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          /> */}
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() => viewMoreHandler('Mean Time To Repair')}
                        >
                          View More
                        </span>
                      </h6>
                      {/* <Tooltip
                        title={
                          <>
                            <h6>
                              Status : {riskAndRange?.codeChurnData?.risk} Risk
                            </h6>
                            <h6>
                              Target : {riskAndRange?.codeChurnData?.from}% to{" "}
                              {riskAndRange?.codeChurnData?.to}%
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Ask for a pre-commit code review and design
                                documentation.
                              </li>
                              <li>Ask to split the work or assign a mentor.</li>
                              <li>
                                Ask a more senior engineer to assess what “good
                                enough” is in the context of the project.{" "}
                              </li>
                              <li>
                                If the problem is difficult, or if the domain is
                                unfamiliar, bring in another engineer to pair
                                program.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus"> 
                          <HealthColorWrapper
                            status={riskAndRange?.codeChurnData?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: '#fdeeee' }}
                    >
                      <img src={codeChurnIcon} alt='basket' />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: '24px' }}>
                        {testExecutionRateQAValues} %
                      </h3>
                      <h6>
                        Test Execution Rate
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI('Test Execution Rate');
                          }}
                        >
                          {/* <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          /> */}
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() => viewMoreHandler('Test Execution Rate')}
                        >
                          View More
                        </span>
                      </h6>
                      {/* <Tooltip
                        title={
                          <>
                            <h6>
                              Status : {riskAndRange?.codeChurnData?.risk} Risk
                            </h6>
                            <h6>
                              Target : {riskAndRange?.codeChurnData?.from}% to{" "}
                              {riskAndRange?.codeChurnData?.to}%
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Ask for a pre-commit code review and design
                                documentation.
                              </li>
                              <li>Ask to split the work or assign a mentor.</li>
                              <li>
                                Ask a more senior engineer to assess what “good
                                enough” is in the context of the project.{" "}
                              </li>
                              <li>
                                If the problem is difficult, or if the domain is
                                unfamiliar, bring in another engineer to pair
                                program.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus"> 
                          <HealthColorWrapper
                            status={riskAndRange?.codeChurnData?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {teamId !== 504 && (
          <>
            {storyPoint && (
              <>
                {config.team.projectLevelTeamTaskCompleted ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={storyPoint}
                          img={check}
                          name={'Task Completed'}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}
            {/* Temp Comment */}
            {/* <Grid item xs={3}>
              <Card className={`${classes.card} customCard position-relative`}>
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={codeChurnIcon} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>{codeChurn}</h3>
                      <h6>
                        Code Churn{" "}
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Code Churn");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler(
                              teamId ? "Team Code Churn" : "Resource Code Churn"
                            )
                          }
                        >
                          View More
                        </span>
                      </h6>
                      <Tooltip
                        title={
                          <>
                            <h6>
                              Status : {riskAndRange?.codeChurnData?.risk} Risk
                            </h6>
                            <h6>
                              Target : {riskAndRange?.codeChurnData?.from}% to{" "}
                              {riskAndRange?.codeChurnData?.to}%
                            </h6>
                            <h6>Recommendations :</h6>
                            <ul>
                              <li>
                                Ask for a pre-commit code review and design
                                documentation.
                              </li>
                              <li>Ask to split the work or assign a mentor.</li>
                              <li>
                                Ask a more senior engineer to assess what “good
                                enough” is in the context of the project.{" "}
                              </li>
                              <li>
                                If the problem is difficult, or if the domain is
                                unfamiliar, bring in another engineer to pair
                                program.
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <div className="metricStatus"> 
                          <HealthColorWrapper
                            status={riskAndRange?.codeChurnData?.risk}
                          ></HealthColorWrapper>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid> */}
            {metricPermission?.isUnReviewPr ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={pr} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {CommitWithoutPullRequestCount
                            ? CommitWithoutPullRequestCount
                            : '0%'}
                        </h3>
                        <h6>
                          Unreviewed PR
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Unreviewed PRs');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Unreviewed PR'
                                  : 'Resource Unreviewed PR',
                                prReivewStatusList,
                                peReivewSubStatus
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.UnreviwedPRRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.UnreviwedPRRisk?.from}%
                                to {riskAndRange?.UnreviwedPRRisk?.to}%
                              </h6>
                              <h6>Recommendations :</h6>
                              <ul>
                                <li>
                                  Please insist on merging PR only after review.
                                </li>
                                <li>
                                  {' '}
                                  Disable ability for the engineer to merge on
                                  repeated mistakes.
                                </li>
                                <li>
                                  Setup the process or pre-merge hooks to make
                                  sure review was done by another engineer.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            {/* <RiskColorWrapperWithIcon
                            className="metricChips"
                            riskLevel={riskAndRange?.UnreviewedPRData?.risk}
                          ></RiskColorWrapperWithIcon> */}
                            <HealthColorWrapper
                              status={riskAndRange?.UnreviwedPRRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {commitWithPr ? (
              <>
                {metricPermission?.isCommitWithPr ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <div className={classes.cardContaner}>
                          <div
                            className={classes.icon}
                            style={{ backgroundColor: '#fdeeee' }}
                          >
                            <img src={commit} alt='basket' />
                          </div>
                          <div className={classes.textContianer}>
                            <h3 style={{ fontSize: '24px' }}>
                              {commitWithPr ? commitWithPr : '0%'}
                            </h3>
                            <h6>
                              Commit with pull request
                              <span
                                onClick={() => {
                                  setIsDefinaionPopup(true);
                                  setDefinationKPI(
                                    'Commit without pull request'
                                  );
                                }}
                              >
                                <InfoIcon
                                  style={{
                                    color: 'gray',
                                    marginLeft: '8px',
                                  }}
                                />
                              </span>
                              <br />
                              <span
                                className={classes.viewMore}
                                onClick={() =>
                                  viewMoreHandler(
                                    teamId
                                      ? 'Team Commit with pull request'
                                      : 'Resource Commit with pull request'
                                  )
                                }
                              >
                                View More
                              </span>
                            </h6>
                            <Tooltip
                              title={
                                <>
                                  <h6>
                                    Status:{' '}
                                    {riskAndRange?.CommitWithPRRisk?.risk} Risk
                                  </h6>
                                  <h6>
                                    Target:{' '}
                                    {riskAndRange?.CommitWithPRRisk?.from}% to{' '}
                                    {riskAndRange?.CommitWithPRRisk?.to}%
                                  </h6>
                                  <h6>Recommendations :</h6>
                                  <ul>
                                    <li>
                                      Disable direct commits into master branch.
                                    </li>
                                  </ul>
                                </>
                              }
                            >
                              <div className='metricStatus'>
                                {/* <RiskColorWrapperWithIcon
                              className="metricChips"
                              riskLevel={
                                riskAndRange?.CommitWithPRRisk?.risk
                              }
                            ></RiskColorWrapperWithIcon> */}
                                <HealthColorWrapper
                                  status={riskAndRange?.CommitWithPRRisk?.risk}
                                ></HealthColorWrapper>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {metricPermission?.isCommitWithoutPr ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={commit} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {commitWithoutPr ? commitWithoutPr : '0%'}
                        </h3>
                        <h6>
                          Commit Without Pull Request
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Commit without pull request');
                            }}
                          >
                            <InfoIcon
                              style={{
                                color: 'gray',
                                marginLeft: '8px',
                              }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Commit without pull request'
                                  : 'Resource Commit without pull request'
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status:{' '}
                                {riskAndRange?.commitWithoutPrRiskAdvice?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target:{' '}
                                {riskAndRange?.commitWithoutPrRiskAdvice?.from}%
                                to {riskAndRange?.commitWithoutPrRiskAdvice?.to}
                                %
                              </h6>
                              <h6>Recommendations :</h6>
                              <ul>
                                <li>
                                  Disable direct commits into master branch.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            {/* <RiskColorWrapperWithIcon
                              className="metricChips"
                              riskLevel={
                                riskAndRange?.CommitWithPRRisk?.risk
                              }
                            ></RiskColorWrapperWithIcon> */}
                            <HealthColorWrapper
                              status={
                                riskAndRange?.commitWithoutPrRiskAdvice?.risk
                              }
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            <>
              {teamPrReviewTime ? (
                <>
                  {config.team.projectLevelTeamPrReviewTime && (
                    <Grid item xs={3}>
                      <Card
                        className={`${classes.card} customCard position-relative`}
                      >
                        <CardContent>
                          <div className={classes.cardContaner}>
                            <div
                              className={classes.icon}
                              style={{ backgroundColor: '#fdeeee' }}
                            >
                              <img src={commit} alt='basket' />
                            </div>
                            <div className={classes.textContianer}>
                              <h3 style={{ fontSize: '24px' }}>
                                {teamPrReviewTime} Days
                              </h3>
                              <h6>
                                Pr Review Time
                                <span
                                  onClick={() => {
                                    setIsDefinaionPopup(true);
                                    setDefinationKPI('Pr review time');
                                  }}
                                >
                                  <InfoIcon
                                    style={{ color: 'gray', marginLeft: '8px' }}
                                  />
                                </span>
                                <br />
                                <span
                                  className={classes.viewMore}
                                  onClick={() =>
                                    viewMoreHandler(
                                      'Team Pr Review Time'
                                      // ResourceAllocationStatusList
                                    )
                                  }
                                >
                                  View More
                                </span>
                              </h6>
                              <Tooltip
                                title={
                                  <>
                                    <h6>
                                      Status:{' '}
                                      {riskAndRange?.PrReviewTimestatus?.risk}{' '}
                                      Risk
                                    </h6>
                                    <h6>
                                      Target:
                                      {riskAndRange?.PrReviewTimestatus?.from}
                                      (Days) to{' '}
                                      {riskAndRange?.PrReviewTimestatus?.to}
                                      (Days)
                                    </h6>
                                  </>
                                }
                              >
                                <div className='metricStatus'>
                                  {/* <RiskColorWrapperWithIcon
                              className="metricChips"
                              riskLevel={"High"}
                            ></RiskColorWrapperWithIcon> */}
                                  <HealthColorWrapper
                                    status={
                                      riskAndRange?.PrReviewTimestatus?.risk
                                    }
                                  ></HealthColorWrapper>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </>
              ) : null}
            </>
            {teamAvgAging && (
              <>
                {config.team.projectLevelTeamWorkInProgress ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <div className={classes.cardContaner}>
                          <div
                            className={classes.icon}
                            style={{ backgroundColor: '#fdeeee' }}
                          >
                            <img src={WIPimg} alt='basket' />
                          </div>
                          <div className={classes.textContianer}>
                            <>
                              <div
                                style={{
                                  fontSize: '18px',
                                }}
                              >
                                <div>
                                  Work In Progress - {teamAvgAging?.StoryPoints}
                                  <span
                                    onClick={() => {
                                      setIsDefinaionPopup(true);
                                      setDefinationKPI('Work In Progress');
                                    }}
                                  >
                                    <InfoIcon
                                      style={{
                                        color: 'gray',
                                        marginLeft: '8px',
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: '18px',
                                }}
                                className='mb-1'
                              >
                                <div> Avg Age - {teamAvgAging?.Time}</div>
                              </div>
                            </>
                            <h6>
                              <span
                                className={classes.viewMore}
                                onClick={() =>
                                  viewMoreHandler('Team WIP Average Age')
                                }
                              >
                                View More
                              </span>
                            </h6>
                            <Tooltip
                              title={
                                <>
                                  <h6>
                                    Status:{' '}
                                    {riskAndRange?.WorkInProgessAging?.risk}{' '}
                                    Risk
                                  </h6>
                                  <h6>
                                    Target:
                                    {riskAndRange?.WorkInProgessAging?.from}% to{' '}
                                    {riskAndRange?.WorkInProgessAging?.to}%
                                  </h6>
                                  <h6>Recommendations</h6>
                                  <ul>
                                    <li>
                                      Insist on doing one thing at a time. If
                                      blocked, change the status appropriately.
                                    </li>
                                  </ul>
                                </>
                              }
                            >
                              <div className='metricStatus'>
                                {/* <RiskColorWrapperWithIcon
                              className="metricChips"
                              riskLevel={"High"}
                            ></RiskColorWrapperWithIcon> */}
                                <HealthColorWrapper
                                  status={
                                    riskAndRange?.WorkInProgessAging?.risk
                                  }
                                ></HealthColorWrapper>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}
            {metricPermission?.isAvgFlowEfficiency ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={flowEfficiencyImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>{flowEfficiency} %</h3>
                        <h6>
                          Average Flow Efficiency
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Average Flow Efficiency');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Average Flow Efficiency'
                                  : 'Resource Average Flow Efficiency'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.FlowEfficiencyRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target:
                                {riskAndRange?.FlowEfficiencyRisk?.from}% to{' '}
                                {riskAndRange?.FlowEfficiencyRisk?.to}%
                              </h6>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            {/* <RiskColorWrapperWithIcon
                              className="metricChips"
                              riskLevel={"High"}
                            ></RiskColorWrapperWithIcon> */}
                            <HealthColorWrapper
                              status={riskAndRange?.FlowEfficiencyRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isCommitWithoutTicketRef ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={msg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>{commitReference}</h3>
                        <h6>
                          Commit Without Ticket Reference
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(
                                'Commit Without Ticket Reference'
                              );
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Commit Without Ticket Reference'
                                  : 'Resource Commit Without Ticket Reference'
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status:{' '}
                                {riskAndRange?.commitWithoutTicketRef?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target:{' '}
                                {riskAndRange?.commitWithoutTicketRef?.from}% to{' '}
                                {riskAndRange?.commitWithoutTicketRef?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Insist on ticket reference in all commits to
                                  get better visibility
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={riskAndRange?.commitWithoutTicketRef?.risk}
                        ></RiskColorWrapperWithIcon> */}
                            <HealthColorWrapper
                              status={
                                riskAndRange?.commitWithoutTicketRef?.risk
                              }
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </>
        )}
        {/* {firstTimePass && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard`}>
              <CardContent>
                <CardDetail
                  value={firstTimePass}
                  img={commit}
                  name={"First Time Pass Issue"}
                  classes={classes}
                  // risk={getStatus(firstTimePassRisk, 80)}
                />
              </CardContent>
            </Card>
          </Grid>
        )} */}
        {teamId !== 504 && (
          <>
            {mostReviewed !== undefined && commitPr !== undefined ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorReviewAndSubmittedPr ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <div
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                <b>Reviewed PR - {mostReviewed}</b>
                              </div>
                              <div
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                <b> Submitted PR - {commitPr}</b>
                              </div>
                            </>
                          }
                          img={communicationHealth}
                          name={'Collaboration'}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {StoryPointCount?.AssignedStoryPoint !== undefined &&
            StoryPointCount?.CompletedStoryPoint !== undefined ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorAssignedStoryPoint ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <div
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                Assigned {config.type}:{' '}
                                {StoryPointCount?.AssignedStoryPoint}
                              </div>
                              <div
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                Completed {config.type}:{' '}
                                {StoryPointCount?.CompletedStoryPoint}
                              </div>
                            </>
                          }
                          img={storyPointIcon}
                          name={''}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {numberOfStoryPoint ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorAvgNumberOfStoryPoint ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <h3 style={{ fontSize: '24px' }}>
                                {numberOfStoryPoint}
                              </h3>
                              <div
                                style={{
                                  fontSize: '16px',
                                }}
                              >
                                Average Number Of Story Points Completed Per
                                Sprint
                              </div>
                            </>
                          }
                          img={storyPointIcon}
                          name={''}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : (
              ''
            )}
            {slackHis ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorTotalTimeOnInterruption ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <h3 style={{ fontSize: '24px' }}>
                                {slackHis.Interruptions}
                              </h3>
                              <h6>
                                Total time spent on interruptions
                                {/* <span
                              onClick={() => {
                                setIsDefinaionPopup(true);
                                setDefinationKPI("Pr review time");
                              }}
                            >
                              <InfoIcon
                                style={{ color: "gray", marginLeft: "8px" }}
                              />
                            </span>*/}
                                <br />
                                <span
                                  className={classes.viewMore}
                                  onClick={() =>
                                    viewMoreHandler(
                                      'Total time spent on interruptions'
                                    )
                                  }
                                >
                                  View More
                                </span>
                              </h6>
                            </>
                          }
                          img={slackIcon}
                          name={''}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {slackHis ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorTotalTimeOnFragment ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <h3 style={{ fontSize: '24px' }}>
                                {slackHis.Fragment || 0}
                              </h3>
                              <h6>
                                Total time spent on fragments
                                {/* <span
                              onClick={() => {
                                setIsDefinaionPopup(true);
                                setDefinationKPI("Pr review time");
                              }}
                            >
                              <InfoIcon
                                style={{ color: "gray", marginLeft: "8px" }}
                              />
                            </span>*/}
                                <br />
                                <span
                                  className={classes.viewMore}
                                  onClick={() =>
                                    viewMoreHandler(
                                      'Total time spent on fragments'
                                    )
                                  }
                                >
                                  View More
                                </span>
                              </h6>
                            </>
                          }
                          img={slackIcon}
                          name={''}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {slackHis ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorTotalTimeOnMeeting ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <h3 style={{ fontSize: '24px' }}>
                                {slackHis.Meetings || 0}
                              </h3>
                              <h6>
                                Total time spent on meeting
                                {/* <span
                              onClick={() => {
                                setIsDefinaionPopup(true);
                                setDefinationKPI("Pr review time");
                              }}
                            >
                              <InfoIcon
                                style={{ color: "gray", marginLeft: "8px" }}
                              />
                            </span>*/}
                                <br />
                                <span
                                  className={classes.viewMore}
                                  onClick={() =>
                                    viewMoreHandler(
                                      'Total time spent on meeting'
                                    )
                                  }
                                >
                                  View More
                                </span>
                              </h6>
                            </>
                          }
                          img={slackIcon}
                          name={''}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {slackHis ? (
              <>
                {config.contributor
                  .projectLevelTeamContributorTotalTimeOnDeepWork ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative`}
                    >
                      <CardContent>
                        <CardDetail
                          value={
                            <>
                              <h3 style={{ fontSize: '24px' }}>
                                {slackHis.DeepWork || 0}
                              </h3>
                              <h6>
                                Total time spent on deep work
                                {/* <span
                              onClick={() => {
                                setIsDefinaionPopup(true);
                                setDefinationKPI("Pr review time");
                              }}
                            >
                              <InfoIcon
                                style={{ color: "gray", marginLeft: "8px" }}
                              />
                            </span>*/}
                                <br />
                                <span
                                  className={classes.viewMore}
                                  onClick={() =>
                                    viewMoreHandler(
                                      'Total time spent on deep work'
                                    )
                                  }
                                >
                                  View More
                                </span>
                              </h6>
                            </>
                          }
                          img={slackIcon}
                          name={''}
                          classes={classes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {metricPermission?.isSpeedingTransitionRate ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {SpeedingTransitionsRateMetrics}
                        </h3>
                        <h6>
                          Speeding Transitions Rate
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Speedy Transition');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Speeding Transitions Rate'
                                  : 'Resource Speeding Transitions Rate'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.speedyRateRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.speedyRateRisk?.from}% to{' '}
                                {riskAndRange?.speedyRateRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            <HealthColorWrapper
                              status={riskAndRange?.speedyRateRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isTicketWithoutEpicAndStory && (
              <>
                {ticketWithoutEpicAndStoryValues ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative py-2`}
                    >
                      <CardContent>
                        <div className={classes.cardContaner}>
                          <div
                            className={classes.icon}
                            style={{ backgroundColor: '#fdeeee' }}
                          >
                            <img src={wipImg} alt='basket' />
                          </div>
                          <div className={classes.textContianer}>
                            <h3 style={{ fontSize: '24px' }}>
                              {ticketWithoutEpicAndStoryValues || 0}
                            </h3>
                            <h6>
                              Ticket Without Epics/Stories
                              <span
                                onClick={() => {
                                  setIsDefinaionPopup(true);
                                  setDefinationKPI(
                                    'Ticket Without Epics/Stories'
                                  );
                                }}
                              >
                                <InfoIcon
                                  style={{ color: 'gray', marginLeft: '8px' }}
                                />
                              </span>
                              <br />
                              <span
                                className={classes.viewMore}
                                onClick={() =>
                                  viewMoreHandler(
                                    teamId
                                      ? 'Team Ticket Without Epics/Stories'
                                      : 'Resource Ticket Without Epics/Stories'
                                    // resourceAllocationStatusListData
                                  )
                                }
                              >
                                View More
                              </span>
                            </h6>
                            <Tooltip
                              title={
                                <>
                                  <h6>
                                    Status:{' '}
                                    {riskAndRange?.ticketWithoutEpicRisk?.risk}{' '}
                                    Risk
                                  </h6>
                                  <h6>
                                    Target:{' '}
                                    {riskAndRange?.ticketWithoutEpicRisk?.from}%
                                    to {riskAndRange?.ticketWithoutEpicRisk?.to}
                                    %
                                  </h6>
                                  <h6>Recommendations</h6>Make sure that you
                                  connecting every ticket to either stories or
                                  epics.
                                  <ul>
                                    <li>
                                      Make sure that you connecting every ticket
                                      to either stories or epics.
                                    </li>
                                  </ul>
                                </>
                              }
                            >
                              <div className='metricStatus'>
                                <HealthColorWrapper
                                  status={
                                    riskAndRange?.ticketWithoutEpicRisk?.risk
                                  }
                                ></HealthColorWrapper>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}
            {metricPermission?.isCommitWithoutEstimation ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {ticketWithoutEstimation}
                        </h3>
                        <h6>
                          Ticket Without Estimation
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Ticket Without Estimation');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Ticket Without Estimation'
                                  : 'Resource Ticket Without Estimation'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status:{' '}
                                {riskAndRange?.ticketWithoutEstimation?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target:{' '}
                                {riskAndRange?.ticketWithoutEstimation?.from}%
                                to {riskAndRange?.ticketWithoutEstimation?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using estimation for
                                  every ticket , ensuring better delivery.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            <HealthColorWrapper
                              status={
                                riskAndRange?.ticketWithoutEstimation?.risk
                              }
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isDevAttentionTime ? (
              <>
                {developerAttentionTimeValues ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative py-2`}
                    >
                      <CardContent>
                        <div className={classes.cardContaner}>
                          <div
                            className={classes.icon}
                            style={{ backgroundColor: '#fdeeee' }}
                          >
                            <img src={wipImg} alt='basket' />
                          </div>
                          <div className={classes.textContianer}>
                            <h3 style={{ fontSize: '24px' }}>
                              {developerAttentionTimeValues?.totalTimeElapsed}{' '}
                              Hrs
                            </h3>
                            <h6>
                              Dev Attention Time
                              <span
                                onClick={() => {
                                  setIsDefinaionPopup(true);
                                  setDefinationKPI('Dev Attention Time');
                                }}
                              >
                                <InfoIcon
                                  style={{ color: 'gray', marginLeft: '8px' }}
                                />
                              </span>
                              <br />
                              <span
                                className={classes.viewMore}
                                onClick={() =>
                                  viewMoreHandler(
                                    teamId
                                      ? 'Team Dev Attention Time'
                                      : 'Resource Dev Attention Time'
                                    // resourceAllocationStatusListData
                                  )
                                }
                              >
                                View More
                              </span>
                            </h6>
                            <Tooltip
                              title={
                                <>
                                  <h6>
                                    Status: {riskAndRange?.speedyRateRisk?.risk}{' '}
                                    Risk
                                  </h6>
                                  <h6>
                                    Target: {riskAndRange?.speedyRateRisk?.from}
                                    % to {riskAndRange?.speedyRateRisk?.to}%
                                  </h6>
                                  <h6>Recommendations</h6>
                                </>
                              }
                            >
                              <div className='metricStatus'>
                                <HealthColorWrapper
                                  status={riskAndRange?.speedyRateRisk?.risk}
                                ></HealthColorWrapper>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {config.contributor
              .projectLevelTeamContributorMetricsDeveloperCoaching && (
              <>
                {developerCoachingValues ? (
                  <Grid item xs={3}>
                    <Card
                      className={`${classes.card} customCard position-relative py-2`}
                    >
                      <CardContent>
                        <div className={classes.cardContaner}>
                          <div
                            className={classes.icon}
                            style={{ backgroundColor: '#fdeeee' }}
                          >
                            <img src={devCoaching} alt='basket' />
                          </div>
                          <div className={classes.textContianer}>
                            {/* <h3 style={{ fontSize: "24px" }}>
                              {SpeedingTransitionsRateMetrics}
                            </h3> */}
                            <b>Developer Coaching </b>
                            {/* <h6>
                              Average Knowledge Area:{" "}
                              {developerCoachingValues?.avgKnowledgeAreaValues}
                            </h6> */}
                            <br />
                            <Button
                              color='primary'
                              style={{
                                textTransform: 'capitalize',
                                color: '#5EBDCC',
                                fontSize: '10px',
                              }}
                              onClick={(e) =>
                                handleClick(e, 'Dev Coaching', teamId)
                              }
                            >
                              {' '}
                              View Detail
                            </Button>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </>
            )}
            {config.team.projectLevelTeamMetricKsi && (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {teamKsiIndexCount?.toFixed(2)}
                        </h3>
                        <h6>
                          Knowledge Sharing Index
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Knowledge Sharing Index');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                'Team Knowledge Sharing Index'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {metricPermission?.isHyginScore ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {hyginScore?.toFixed(2) || 0} %
                        </h3>
                        <h6>
                          Hygin Score
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Hygin Score');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Hygin Score'
                                  : 'Resource Hygin Score'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isPrSize ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {prSize?.toFixed()} (Count)
                        </h3>
                        <h6>
                          Pr Size (Number of code chages)
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Pr Size');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId ? 'Team Pr Size' : 'Resource Pr Size'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isTestCaseEfficiency ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {testCaseEfficiency?.defectResolutionPercentage?.toFixed()}{' '}
                          %
                        </h3>
                        <h6>
                          Test Case Efficiency
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Test Case Efficiency');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Test Case Efficiency'
                                  : 'Resource Test Case Efficiency'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isDefectResolutionTime ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {defectResolutionTime?.toFixed() || 0} (Day's)
                        </h3>
                        <h6>
                          Defect resolution time
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Defect resolution time');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Defect resolution time'
                                  : 'Resource Defect resolution time'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{' '}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className='metricStatus'>
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isDefectInBacklog ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {defectInBacklog || 0} (Count)
                        </h3>
                        <h6>
                          Defect In Backlog
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Defect In Backlog');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Defect In Backlog'
                                  : 'Resource Defect In Backlog'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isDefectInProgress ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {defectInProgress || 0} (Count)
                        </h3>
                        <h6>
                          Defect In Progress
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Defect In Progress');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Defect In Progress'
                                  : 'Resource Defect In Progress'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {/* ========================= GoFynd metric ======================== */}
            {metricPermission?.isBugFixAndIntroduce ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {bugDoneAndIntro?.totalBugsDone || 0}/
                          {bugDoneAndIntro?.totalBugsIntroduced || 0} (
                          {bugDoneAndIntro?.bugDoneRatio?.toFixed(2) || 0})
                        </h3>
                        <h6>
                          Bug Fixed / Bug Introduced
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Bug Introduced / Bug Fixed');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Bug Introduced / Bug Fixed'
                                  : 'Resource Bug Introduced / Bug Fixed'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        {/* <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{" "}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className="metricStatus">
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip> */}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isBugIntroAndFeature ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {prodBugIntroAndFeature?.totalBugsIntroduced || 0}/
                          {prodBugIntroAndFeature?.totalTicketIntroduced || 0} (
                          {prodBugIntroAndFeature?.bugRatio?.toFixed(2) || 0})
                        </h3>
                        <h6>
                          Production Bugs Introduce / Features
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(
                                'Production Bugs Introduce / Features'
                              );
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Production Bugs Introduce / Features'
                                  : 'Resource Production Bugs Introduce / Features'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        {/* <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{" "}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className="metricStatus">
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip> */}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isProdBugIntroInWeek ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {noOfProdBugIntro?.totalBugsIntroduced || 0}
                        </h3>
                        <h6>
                          No of Prod Bugs introduced in a week
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(
                                'No of Prod Bugs introduced in a week'
                              );
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team No of Prod Bugs introduced in a week'
                                  : 'Resource No of Prod Bugs introduced in a week'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        {/* <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{" "}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className="metricStatus">
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip> */}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isNoOfProdBugFix ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {noOfProdBugFixed?.totalBugsIntroduced || 0}
                        </h3>
                        <h6>
                          No of Prod Bugs Fixed
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('No of Prod Bugs Fixed');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team No of Prod Bugs Fixed'
                                  : 'Resource No of Prod Bugs Fixed'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                        {/* <Tooltip
                          title={
                            <>
                              <h6>
                                Status: {riskAndRange?.hygineScoreRisk?.risk}{" "}
                                Risk
                              </h6>
                              <h6>
                                Target: {riskAndRange?.hygineScoreRisk?.from}%
                                to {riskAndRange?.hygineScoreRisk?.to}%
                              </h6>
                              <h6>Recommendations</h6>
                              <ul>
                                <li>
                                  Make sure that you are using your project
                                  manageement tool properly , ensuring
                                  consistent data hygiene.
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <div className="metricStatus">
                            <HealthColorWrapper
                              status={riskAndRange?.hygineScoreRisk?.risk}
                            ></HealthColorWrapper>
                          </div>
                        </Tooltip> */}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isTotalNumberOfFeature ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {totalNumberFeatures?.totalBugsIntroduced || 0}
                        </h3>
                        <h6>
                          Total Number of Features
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI('Total Number of Features');
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Total Number of Features'
                                  : 'Resource Total Number of Features'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isProdBugIntroAndCheckIn ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {prodBugIntroCheckIn?.totalBugsIntroduced || 0}/
                          {prodBugIntroCheckIn?.totalBugsCheckedIn || 0}(
                          {prodBugIntroCheckIn?.ratio?.toFixed(2) || 0})
                        </h3>
                        <h6>
                          Production Bugs Introduce / Check in
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(
                                'Production Bugs Introduce / Check in'
                              );
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Production Bugs Introduce / Check in'
                                  : 'Resource Production Bugs Introduce / Check in'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isNumberOfCheckInWeek ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {totalCheckIn?.totalBugsIntroduced || 0}
                        </h3>
                        <h6>
                          Total Number of Checkins in a week
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(
                                'Total Number of Checkins in a week'
                              );
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Total Number of Checkins in a week'
                                  : 'Resource Total Number of Checkins in a week'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {metricPermission?.isMetricTicketWithoutCommitRef ? (
              <Grid item xs={3}>
                <Card
                  className={`${classes.card} customCard position-relative py-2`}
                >
                  <CardContent>
                    <div className={classes.cardContaner}>
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <img src={wipImg} alt='basket' />
                      </div>
                      <div className={classes.textContianer}>
                        <h3 style={{ fontSize: '24px' }}>
                          {TicketWithoutCommitReference || 0}
                        </h3>
                        <h6>
                          Ticket Without Commit Reference
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI(
                                'Ticket Without Commit Reference'
                              );
                            }}
                          >
                            <InfoIcon
                              style={{ color: 'gray', marginLeft: '8px' }}
                            />
                          </span>
                          <br />
                          <span
                            className={classes.viewMore}
                            onClick={() =>
                              viewMoreHandler(
                                teamId
                                  ? 'Team Ticket Without Commit Reference'
                                  : 'Resource Ticket Without Commit Reference'
                                // resourceAllocationStatusListData
                              )
                            }
                          >
                            View More
                          </span>
                        </h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {teamData && (
              <>
                {config.team.projectLevelTeamUtilizationVsPerformance && (
                  <Grid item xs={3}>
                    <Card className={`${classes.card} customCard`}>
                      <CardContent>
                        <div className={classes.cardContaner}>
                          <div
                            className={classes.icon}
                            style={{ backgroundColor: '#fdeeee' }}
                          >
                            <img
                              src={utilizationVsPerformanceIcon}
                              alt='basket'
                            />
                          </div>
                          <div className={classes.textContianer}>
                            <>
                              <div
                                style={{
                                  fontSize: '18px',
                                }}
                                className='mb-1'
                              >
                                <div>Utilization vs Performance</div>
                              </div>
                            </>
                            <h6>
                              <span
                                className={classes.viewMore}
                                onClick={() => setIsUtilizationOpen(true)}
                              >
                                View More
                              </span>
                            </h6>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </>
            )}

            <UtilizationPerformance
              open={isUtilizationOpen}
              handleClose={() => setIsUtilizationOpen(false)}
              classes={classes}
              teamId={teamId}
            />
            {teamData && config.type === 'Story Point' && (
              <Grid item xs={3}>
                <Card className={'customCard'}>
                  <CardContent>
                    <div
                      className={classes.sideCard}
                      onClick={() => setViewAll(!viewAll)}
                    >
                      <div
                        className={classes.icon}
                        style={{ backgroundColor: '#fdeeee' }}
                      >
                        <ViewModuleIcon />
                      </div>
                      <span> {viewAll ? 'Hide' : 'View'} Other Metrics</span>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        {workBreakDown && (
          <>
            {config.contributor.projectLevelTeamContributorWorkBreakDown && (
              <Grid item xs={6}>
                <GraphCard
                  title={'Work Breakdown'}
                  subTitle={
                    <div className={classes.subTitle}>
                      <span className={classes.subTitleAmt}></span>
                      <span className={classes.subTitleAmt}>
                        {workBreakDown?.codeChange}
                      </span>
                      <span className={classes.subTitleText}>Code changes</span>
                      <div
                        onClick={() =>
                          viewMoreHandler(
                            'Resource Work Breakdown'
                            // PlanningAccuracyStatusList
                          )
                        }
                        className={classes.viewMore}
                      >
                        View More
                      </div>
                    </div>
                  }
                >
                  <WorkAlocationChart data={workBreakDown?.graph} />
                </GraphCard>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl?.open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {anchorEl?.type === 'health' ? (
            <HealthModal
              classes={classes}
              resourceHealth={resourceHealth}
              date={date}
              handleClose={handleClose}
              getProductivityHealthStatus={getProductivityHealthStatus}
            ></HealthModal>
          ) : anchorEl?.type === 'process' ? (
            <ProcessModal
              classes={classes}
              resourceHealth={resourceProcess}
              date={date}
              handleClose={handleClose}
            ></ProcessModal>
          ) : anchorEl?.type === 'quality' ? (
            <QualityModal
              classes={classes}
              resourceHealth={{ ...resourceQuality, codeChurn, lacgacy }}
              date={date}
              handleClose={handleClose}
              infrenceSuggetion={resourceQualityAiResponse}
            ></QualityModal>
          ) : anchorEl?.type === 'Team quality' ? (
            <QualityModal
              classes={classes}
              resourceHealth={{ ...teamQualityHealth, codeChurn }}
              date={date}
              handleClose={handleClose}
              type={anchorEl}
              infrenceSuggetion={TeamQualityInfrenceAiResponse}
            ></QualityModal>
          ) : anchorEl?.type === 'Team process' ? (
            <ProcessModal
              classes={classes}
              resourceHealth={{ ...teamProcessHealth, codeChurn }}
              date={date}
              handleClose={handleClose}
              type={anchorEl}
            ></ProcessModal>
          ) : anchorEl?.type === 'Team Communication' ? (
            <TeamCommunication
              classes={classes}
              resourceHealth={teamCommunication}
              date={date}
              handleClose={handleClose}
              type={anchorEl}
            ></TeamCommunication>
          ) : anchorEl?.type === 'Team health' ? (
            <HealthModal
              classes={classes}
              resourceHealth={teamProductivity}
              date={date}
              handleClose={handleClose}
              getProductivityHealthStatus={teamProductivity?.HealthStatus}
              type={anchorEl}
            ></HealthModal>
          ) : anchorEl?.type === 'Dev Coaching' ? (
            <DeveloperCoachingPopup
              classes={classes}
              data={developerCoachingValues}
              handleClose={handleClose}
            />
          ) : anchorEl?.type === 'Dxi' ? (
            <DxiModel
              classes={classes}
              DxiData={DxiMetric}
              date={date}
              handleClose={handleClose}
              type={anchorEl}
            ></DxiModel>
          ) : (
            ''
          )}
        </Popover>
      </>
    </Box>
  );
}

export default Header;
