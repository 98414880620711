import { Box, Dialog } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { fix } from "../../../../utils/changeDecimal";
import { Alert, DialogTitle } from "@mui/material";
import MStepper from "../../../../components/mobileSteper";
import TrendWithCategoryAxis from "../../../../components/trendGraph/trendWithCategory";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import instance from "../../../../utils/axiosHelper";
import CommonPopup from "../../../../components/commonPopup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { datePayload } from "../../../../utils/moduleTabApi";
import RangeDatePicker from "../../../../components/RangeDatePicker";
import EpicDropDown from "../../../../components/epicDropDown";
import { GlobalData } from "../../../../context/globalData";
import Button from "@mui/material/Button";

function DxiModel({ classes, DxiData, handleClose, type }) {
  const { iterationValueGlobal, projectRangePickerValue: rangePickerValue } =
    useContext(GlobalData);
  const [value, setValue] = useState(0);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [date, setDate] = useState(rangePickerValue);
  const [showAlert, setShowAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showTrend, setShowTrend] = useState(false);
  const [trendData, setTrendData] = useState([]);
  const [showInsights, setShowInsights] = useState(false);
  const [allSuggestion, setAllSuggestion] = useState([]);
  const [inferenceAi, setInferenceAi] = useState([]);
  const [loader, setLoader] = useState(false);
  const { state } = useLocation();
  const { projectId } = useParams();

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/calculateDxiTrend?${dateUrl}&projectId=${projectId}$teamId=${type?.id}`;
    instance
      .get(url)
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.Date);
          return {
            ...e,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  const formatItems = (items) =>
    Object.keys(items)?.map((item) => `${item}: ${items[item]}`);

  const memoAllSuggestion = useMemo(() => {
    return (res) => [
      { actions: formatItems(res?.data?.[0]?.Actions), title: "Actions" },
      {
        actions: formatItems(res?.data?.[0]?.Suggestions),
        title: "Suggestions",
      },
    ];
  }, []);

  const memoizedInference = useMemo(() => {
    return (res) =>
      Object.keys(res?.data?.[0]?.Inference).map(
        (item) => `${item}:${res?.data?.[0]?.Inference[item]}`
      );
  }, []);

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/calculateDxiInference?${dateUrl}&projectId=${projectId}&teamId=${type?.id}`;
    setLoader(true);
    instance
      .get(url)
      .then((res) => {
        setLoader(false);
        const suggest = memoAllSuggestion(res);
        const infere = memoizedInference(res);
        setAllSuggestion(suggest);
        setInferenceAi(infere);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  return (
    <>
      <Box
        padding={3}
        display={"grid"}
        gridGap={4}
        gridColumnGap={8}
        gridTemplateColumns="1fr 1fr"
      >
        <div
          className={classes.closeIconSingleRes}
          onClick={() => handleClose(null)}
        >
          <div>
            <HighlightOffIcon />
          </div>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Developer Experience Index Score
          </label>
          <p>{DxiData?.DXI || 0} %</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Developer Experience Index Status
          </label>
          <p>{DxiData?.DXIStatus}</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Defect Resolution Time
          </label>
          <p>{DxiData?.defectResolutionTime?.toFixed() || 0} (Hrs) </p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>deploy Time</label>
          <p>{DxiData?.deployTime?.toFixed() || 0} (Hrs)</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Mean Time To Restore Failed Build
          </label>
          <p>{DxiData?.meanTimeToRestoreFailedBuild?.toFixed() || 0} (Hrs)</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Pickup Time</label>
          <p>{DxiData?.pickupTime?.toFixed() || 0 } (Hrs)</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Pr Merge Frequency
          </label>
          <p>{DxiData?.prMergeFrequency?.toFixed() || 0} (Count)</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>Review Time</label>
          <p>{DxiData?.reviewTime?.toFixed() || 0} (Hrs)</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Time Spent On Bug Fixing
          </label>
          <p>{DxiData?.timeSpentOnBugFixing?.toFixed() || 0} (Hrs)</p>
        </div>
        <div>
          <label className={classes.resourceHealthLabel}>
            Utilization Factor
          </label>
          <p>{DxiData?.utilizationFactor?.toFixed() || 0} (%)</p>
        </div>
      </Box>
      <Box paddingX={3} paddingBottom={2} display={"flex"} gridGap={10}>
        <Button
          variant="outlined"
          style={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => setShowInsights(!showInsights)}
          disabled={loader}
        >
          {showInsights ? "Hide" : "Show"} Inference
        </Button>
        <Button
          variant="outlined"
          style={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => setShowAlert(!showAlert)}
          disabled={loader}
        >
          {showAlert ? "Hide" : "Show"} Suggestions
        </Button>
        <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => setShowTrend(!showTrend)}
        >
          {showTrend ? "Hide" : "Show"} Trend
        </Button>
      </Box>
      <CommonPopup open={showAlert} setOpen={setShowAlert}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="info"
          >
            <h6>{allSuggestion[activeStep]?.title}</h6>
            <ul style={{ paddingLeft: 20 }}>
              {allSuggestion[activeStep]?.actions.map((action, i) => (
                <li key={i}>{action}</li>
              ))}
            </ul>
          </Alert>
          <MStepper
            steps={allSuggestion}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>
      </CommonPopup>
      <CommonPopup open={showInsights} setOpen={setShowInsights} width={600}>
        <Box padding={3}>
          <Alert
            sx={{ mb: 3, fontSize: 12, textAlign: "left", width: "500px" }}
            severity="success"
          >
            <h6>Inference:</h6>

            <ul>
              {" "}
              {inferenceAi?.map((item) => (
                <>
                  <li>{item}</li>
                </>
              ))}
            </ul>
          </Alert>
        </Box>
      </CommonPopup>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box display={"flex"} gap={2}>
          {(value === 0 || value === 3) && (
            <>
              <Box marginLeft={2}>
                <RangeDatePicker
                  setDate={setDate}
                  date={date}
                  isNextDate={false}
                />
              </Box>
            </>
          )}
        </Box>
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trendData} />
        </Box>
      </CommonPopup>
    </>
  );
}

export default DxiModel;
