import sprint from "../../src/assets/sprint.png";
import { config } from "../Config/permission";

export const definations = {
  "Cycle Time": {
    definition: [
      "The Cycle Time is the sum of four metrics, each of these metrics corresponding to a stage in the software development process",
      "The Cycle Time measures the amount of time from first commit to production release. It is a metric borrowed from lean manufacturing, and it is one of the most important metrics for software development teams.",
    ],
    use: "N.A.",
    benchmark: ["50 hours to 200 Hours"],
    tool: "Jira/Azure",
  },
  Velocity: {
    definition: [
      "The amount of work that the team completes measures delivery throughput. This provides a breakdown of how many tasks were completed during an iteration. Issues are determined by the ticket-types available in the project management board linked to your team.",
      "The summary value of velocity is expressed as an average of your past 3 sprints, 5 sprints, or all your sprints. You can change this in the metric settings. ",
      "Sprints with a large variance of velocity over a three-sprint period would indicate a high velocity.",
      "The lower the volatility score, the more predictable your sprints are. This means there is also a higher probability you can accurately estimate and plan your sprints based on the average sprint velocity.",
      "The Velocity report provides a breakdown of how many tasks were completed during the iteration according to the ticket type in Jira.",
      "The Velocity report analyses your connected Project Management boards (Jira or Trello).",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira/Azure/Trello ",
  },
  "Commits (Count)": {
    definition: [
      "Commits is a metric that aggregates all the available commits pushed by the team or the individual across all the relevant branches in all the repositories scanned, during the selected time frame.",
      "It represents the number of commits done by the developer.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket ",
  },
  "Commits ": {
    definition: [
      "Commits is a metric that aggregates all the available commits pushed by the team or the individual across all the relevant branches in all the repositories scanned, during the selected time frame.",
      "It represents the number of commits done by the developer.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket ",
  },
  "Commit Size (Line of Code)": {
    definition: [
      "Commit size is a measure of the size of each commit—the sum of additions and deletions—integrated to trunk/main. Commit size is a measure of the size of each commit—the sum of additions and deletions—integrated to trunk/main",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket ",
  },
  "Commit Size ": {
    definition: [
      "Commit size is a measure of the size of each commit—the sum of additions and deletions—integrated to trunk/main. Commit size is a measure of the size of each commit—the sum of additions and deletions—integrated to trunk/main",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket ",
  },
  "Total Work Done": {
    definition: [
      "The metric Total Work Done is used to measure the total amount of work completed by a team or individual over a specific period. It provides a quantitative measure of the volume of tasks, user stories, or other work items that have been successfully completed and delivered.",
      "Tracking TWD can help teams or individuals identify areas where they can improve their productivity.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "% Commits without Pull Requests": {
    definition: [
      "The metric % Commits Without Pull Request is used to measure the percentage of code commits in a version control system (e.g., Git) that were made directly to a branch without going through a formal pull request process.",
      "Pull requests are a common practice in collaborative software development, as they facilitate code review, discussion, and validation of code changes before they are merged into the main codebase.",
    ],
    use: "N.A.",
    benchmark: [
      "Lower is the percentage, better it is.  Aim for not more than 20%",
    ],
    tool: "Jira and GitHub/Bitbucket ",
  },
  "Ticket Without Commit Reference": {
    definition: [
      "The metric Ticket Without Commit Reference is used to measure the number of tickets or issues in a project management or issue tracking system that have been closed or resolved without any corresponding reference to commits in the version control system (e.g., Git).",
      "This metric is essential for ensuring that code changes are adequately linked to the associated tasks or issues, facilitating traceability and enhancing the understanding of changes made during the development process.",
    ],
    use: "N.A",
    benchmark: [
      "Lower is the percentage, the better it us. Aim for not more than 20%",
    ],
    tool: "Jira and GitHub/Bitbucket ",
  },
  "Deployment Frequency": {
    definition: [
      "Frequency of deployments summarised as a number of deployments per day, a great core metric to monitor as you strive toward continuous delivery. Deployment frequency measures the cadence of new code being released successfully into production and is a key indicator of efficient development pipelines.Lead Time for Changes measures the elapsed time between the first commit and the deployment to the final environment.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies",
      "1 or more deployment per day is : Elite Performance.",
      "1 to 2 deployment per week is : Medium Performance.",
      "less than 1 deployment per week is: Need Improvement.",
    ],
    tool: "Jenkins",
  },
  "Lead Time for Changes": {
    definition: [
      "Lead time for changes is the average time it takes from the first commit until changes are deployed into Production.",
      "Lead Time for Changes measures the elapsed time between the first commit and the deployment to the final environment.",
      "The Lead Time for Changes can be broken down into Coding Time (time elapsed between the first commit made and a Pull Request being opened), Review Lag Time (time elapsed between a Pull Request being opened and the first review made), Review Time (time elapsed between the first review and the Pull Request merged), and Deploy Time (time elapsed between the Pull Request being merged and its deployment to the final environment).",
    ],
    use: "N.A.",
    benchmark: ["1 day to 1 Month is industry average."],
    tool: "Jenkins",
  },
  "Mean Time to Recovery": {
    definition: [
      "The Mean Time to Recovery measures the time it takes to recover from a failure in production. It analyses the elapsed time between a failed deployment and a successful deployment on the same repository and branch.",
    ],
    use: "N.A.",
    benchmark: ["1 day to 1 week is industry average."],
    tool: "Jenkins",
  },
  "Change Failure Rate": {
    definition: [
      "Change failure rate measures the percentage of deploys that cause a failure in production.",
      "The Change Failure Rate metric indicates the percentage of deployments that lead to failures in production.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 1% : Elite Performance",
      "1% to 39% : Medium Performance ",
      "Greater than 39%: Need improvement",
    ],
    tool: "Jenkins",
  },
  "Pull Request Without Review": {
    definition: [
      "The metric Pull Request Without Review is used to measure the number of pull requests in a code repository that have been created and merged without undergoing any code review. Code reviews are an essential practice in software development, as they help ensure code quality, identify potential issues, and facilitate knowledge sharing among team members.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket",
  },
  "Pull Request Size": {
    definition: [
      "Pull request size is a metric calculating the average code changes (in lines of code) of a pull request.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket",
  },
  "Lead Time": {
    definition: [
      "The Lead Time breaks down into Coding Time (time spent between the first commit and a pull request being opened), Review Lag Time (time spent between a Pull Request being opened and the first review), and Review Time (Time spent between the first review and merge). ",
      'The modal also contains a "Go to Pull Request" button, which takes you directly to the PR page in the Git Provider.',
      "The Lead Time for Changes can be broken down into Coding Time (time elapsed between the first commit made and a Pull Request being opened), Review Lag Time (time elapsed between a Pull Request being opened and the first review made), Review Time (time elapsed between the first review and the Pull Request merged), and Deploy Time (time elapsed between the Pull Request being merged and its deployment to the final environment).",
    ],
    use: "N.A.",
    benchmark: ["1 day to 30 days is industry average "],
    tool: "Jira/Bitbucket/GitHub ",
  },
  "Average Lead Time": {
    definition: [
      "The metric Average Lead Time is a key performance indicator (KPI) used to measure the average time it takes for a work item to move from the start of its development process to its completion.",
      "Average Lead Time is particularly valuable in Agile and Lean methodologies, where reducing lead times is a primary objective to achieve faster and more responsive delivery.",
    ],
    use: "N.A.",
    benchmark: ["1 day to 30 days is industry average "],
    tool: "GitHub/Bitbucket/Jira",
  },
  "Sprint Spillover": {
    definition: [
      "A spillover is a backlog item that has failed to meet the criteria defined in the Definition of Done (DoD) for the project team. It is important to note that the DoD is defined for the entire project and is applicable to any user story.",
      // "The number of backlog items that do not meet the Definition of Done (DoD) at the end of a sprint.",
      // "The amount of work that was initially planned for a sprint but remains unfinished and is carried over to subsequent sprints.",
      // "How to calculate: Number of backlog items not completed / Number of backlog items planned",
      // 'The metric Sprint Spillover is used in Agile project management to measure the amount of work that was planned for a sprint but could not be completed within the scheduled timeframe. It indicates the unfinished work that  "spills over" from one sprint to the next.',
      // "Sprint spillover can provide insights into the accuracy of sprint planning, the team's capacity to complete work, and potential bottlenecks or challenges in the development process.",
      // "A higher Sprint Spillover value may indicate overcommitment in sprint planning, inefficiencies in development processes, or unexpected challenges during the sprint. It is essential for teams to use this metric to review their sprint planning and adjust their capacity estimation for future sprints accordingly.",
    ],
    use: "N.A.",
    benchmark: ["Lower is better. less than 20% is industry benchmark"],
    tool: "Jira",
  },
  "Hygine Score": {
    definition: [
      "Jira Hygine Score refers to the measure of the overall quality and completeness of the tickets or issues created in Jira, which is a popular issue and project tracking tool used in software development and project management.",
    ],
    use: "N.A.",
    benchmark: ["Higher is the number, better it is."],
    tool: "Jira",
  },
  "Hygin Score": {
    definition: [
      "Jira Hygine Score refers to the measure of the overall quality and completeness of the tickets or issues created in Jira, which is a popular issue and project tracking tool used in software development and project management.",
    ],
    use: "N.A.",
    benchmark: ["Higher is the number, better it is."],
    tool: "Jira",
  },
  "Sprint Completion": {
    definition: [
      "Core Scrum metric so you can understand the overall success of your sprints and a closer look at the outcome of tickets at sprint end.",
      sprint,
      "Sprint completion - Percentage of tickets completed within a sprint from the tickets that either started the sprint or were added to the sprint after it started (i.e., a combination of the first two).",
      "Completion percentage of each sprint from the tickets that either started the sprint or were added to the sprint after it started. Calculation can be based on ticket count or story points, and issue type filter available to select relevant tickets.",
    ],
    use: "N.A.",
    benchmark: ["90% is industry benchmark "],
    tool: "Jira",
  },
  // "Investment Profile": {
  //   definition: [
  //     "Investment Profile incorporates one of the most critical components of engineering metrics by connecting engineering to business value. It will show the percentage of total time in the iteration spent (invested) on the different types of work. This is useful for communicating with non-technical leadership on how engineering resources are being used and ensuring that aligns with the strategic direction.",
  //     "What data is being displayed in the Investment Profile report?",
  //     [
  //       "i.	The Investment Profile report analyses your connected Project Management boards (Jira or Shortcut).",
  //       "ii.	Issue types are pulled directly from the ticket types associated with the selected PM board.",
  //       "iii.	All issues included in the Investment Profile report are completed issues (status = done).",
  //       "iv.	The time measurement is taken from when an issue is set to in progress to when an issue is resolved.",
  //     ],
  //   ],

  // },

  "Investment profiles": {
    definition: [
      "This report measures the time spent working on each issue-type in a set iteration, or series of iterations. Investment Profile incorporates one of the most critical components of engineering metrics by connecting engineering to business value. It will show the percentage of total time in the iteration spent (invested) on the different types of work. This is useful for communicating with non-technical leadership on how engineering resources are being used and ensuring that aligns with the strategic direction.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Issue management tool such As Jira and Code repository tool such as Bitbucket/GitHub ",
  },

  "Resource Allocation": {
    definition: [
      "The Resource Allocation metric is used to measure and assess how effectively and efficiently resources (such as team members, equipment, and budget) are allocated and utilized within a project or organization.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  "Planning Accuracy": {
    definition: [
      "Planning Accuracy measures planned and completed work as a ratio of planned work. In a single “accuracy score” you’ll know if your teams are scoping iterations well, whether they’re completing their tasks, and how unplanned work is affecting execution.",
    ],
    use: "N.A.",
    benchmark: ["50% is industry average"],
    tool: "Jira",
  },
  "PR Merged W/O Review": {
    definition: [
      "The metric Pull Requests Merged Without Review (also known as PRs Merged Without Approval or Unreviewed Pull Requests) measures the number of pull requests that were merged into the main codebase without undergoing any formal review or approval process. This metric helps assess the risk associated with unreviewed changes and identifies potential issues in the development workflow.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Github/Bitbucket ",
  },
  "PR Size (Code changes)": {
    definition: [
      "The Pull Request (PR) Size metric is a measure of the size or scope of code changes introduced in a pull request in a version control system. It provides insights into the complexity and impact of the proposed changes and helps in assessing the review effort required for merging the pull request into the main codebase.",
      "The PR size metric is particularly relevant in collaborative software development environments where developers work on feature branches and submit pull requests for code review before merging.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 250 line of code is : Elite Performance",
      "250 Lines of Code to  800 Lines of code is : Medium Performance ",
      "Greater than 800 line of code Need Attention",
    ],
    tool: "Github/Bitbucket ",
  },
  "PR Size ": {
    definition: [
      "The Pull Request (PR) Size metric is a measure of the size or scope of code changes introduced in a pull request in a version control system. It provides insights into the complexity and impact of the proposed changes and helps in assessing the review effort required for merging the pull request into the main codebase.",
      "The PR size metric is particularly relevant in collaborative software development environments where developers work on feature branches and submit pull requests for code review before merging.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 250 line of code is : Elite Performance",
      "250 Lines of Code to  800 Lines of code is : Medium Performance ",
      "Greater than 800 line of code Need Attention",
    ],
    tool: "Github/Bitbucket ",
  },
  "Review Depth": {
    definition: [
      "Review depth measures the average number of comments per pull request review. This metric is an indication regarding the quality of the review and how thorough reviews are done.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  "New Code": {
    definition: [
      "The New Code metric is a measure of the amount or size of code that is introduced or added to a codebase during a specific period. It provides insights into the pace of code development and the growth of the codebase over time.",
      "The New Code metric is particularly useful for tracking the progress of a project, assessing developer productivity, and identifying potential areas of code refactoring or optimization.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  Refactor: {
    definition: [
      'Refactored work represents changes to legacy code. DevBoost considers code "legacy" if it has been in your code-base for over after 28 days.',
      "Some degree of refactored code is acceptable and even required for improving systems quality.",
      "A large amount of refactoring in a single release is not recommended, as these changes have a higher probability of harming existing functionality.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Github/Bitbucket ",
  },
  "Refactor %": {
    definition: [
      'Refactored work represents changes to legacy code. DevBoost considers code "legacy" if it has been in your code-base for over after 28 days.',
      "Some degree of refactored code is acceptable and even required for improving systems quality.",
      "A large amount of refactoring in a single release is not recommended, as these changes have a higher probability of harming existing functionality.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Github/Bitbucket ",
  },
  Rework: {
    definition: [
      "Reworked code is relatively new code that is modified in a branch. We consider any changes to code that has been in your code-base for less than 28 days as reworked code.",
      "High levels of reworked code could indicate a quality issue in recent releases.",
    ],
    use: "N.A.",
    benchmark: ["8% to 20% is industry average."],
    tool: "Github/Bitbucket ",
  },
  "Rework %": {
    definition: [
      "Reworked code is relatively new code that is modified in a branch. We consider any changes to code that has been in your code-base for less than 28 days as reworked code.",
      "High levels of reworked code could indicate a quality issue in recent releases.",
    ],
    use: "N.A.",
    benchmark: ["8% to 20% is industry average."],
    tool: "Github/Bitbucket ",
  },
  "New work": {
    definition: ["New work measures how much novel code is written over time."],
    use: "N.A.",
    benchmark:
      "For a growing company, aim for more than 50% work to be new work.",
    tool: "Github/Bitbucket ",
  },
  Feature: {
    definition: ["Feature measures how much novel code is written over time."],
    use: "N.A.",
    benchmark: [
      "For a growing company, aim for more than 50% work to be new work.",
    ],
    tool: "Github/Bitbucket ",
  },
  "Feature %": {
    definition: ["Feature measures how much novel code is written over time."],
    // use: "N.A.",
    benchmark: [
      "For a growing company, aim for more than 50% work to be new work.",
    ],
    // tool: "Github/Bitbucket ",
  },
  "Pull Request Opened": {
    definition: [
      "The Pull Request Opened metric is a measure of the frequency or rate at which pull requests are initiated in a version control system. A pull request is typically a mechanism used in distributed version control systems like Git, where a developer proposes changes to a codebase, and other team members review and approve those changes before they are merged into the main codebase.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  // "Capacity Accuracy": {
  //   definition: [
  //     "It tells you if your teams are taking on the correct amount of work. It measures all completed (planned vs unplanned) work as a ratio of planned work.",
  //   ],
  //   use: "N.A.",
  //   benchmark: ["than 75% is industry average"],
  //   tool: "Jira",
  // },
  "Unreviewed PRs": {
    definition: [
      "This metric measures the number of Pull Requests that are open and merged without ever getting a comment, which should be as close to zero as possible to ensure thoroughness of feedback.",
      // "What : The percentage of PRs without comments or approvals.",
      // "Why: This metric shows you the number of PRs merged without being reviewed. This shows whether you are limiting risk of solutions you are providing our customers, by limiting the amount of work that does not get a second pair of eyes.",
    ],
    use: "N.A.",
    // benchmark: ["Leading Contributors - 5% , Typical Contributors - 20%"],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 10% : Elite Performance.",
      "10% to 15% : Medium Performance. ",
      "greater than 15%: Need Improvement.",
    ],
    tool: "N.A.",
  },
  "Thoroughly reviewed PRs": {
    definition: [
      "What: The percentage of merged pull requests with at least one regular or robust comment.",
      "Why: Having thorough comments is related positively to code review quality and healthy team collaboration. Having too many pull requests merged without thorough review could be a sign of rubber-stamping during the code review process.",
      "Thoroughly reviewed PRs is calculated as the total number of thoroughly reviewed merged pull requests an individual, team, or organization was involved in, divided by the total number of merged pull requests the individual, team, or organization was involved in.",
      "A user is involved in a pull request if they comment on or approve the pull request, even if their comment is not regular or robust.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  "Defect density": {
    definition: [
      "Defect density is the number of defects found within a software's lines of code. This metric helps companies analyse the performance of their products, software and modules. Defect density can also help companies identify improvement opportunities within their software development teams and processes.",
      "Defect Density Example",
      "Suppose, you have 3 modules integrated into your software product. Each module has the following number of bugs discovered-",
      ["Module 1 = 10 bugs", "Module 2 = 20 bugs", "Module 3 = 10 bugs"],
      "Total bugs = 10+20+10 =40",
      "The total line of code for each module is",
      ["Module 1 = 1000 LOC", "Module 2 = 1500 LOC", "Module 3 = 500 LOC"],
      "Total Line of Code = 1000+1500+500 = 3000",
      "Defect Density is calculated as:",
      "Defect Density = 40/3000 = 0.013333 defects/loc = 13.333 defects/Kloc",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  "Merge frequency": {
    definition: [
      "This measures the total number of pull requests or merge requests merged by a team over a period of time. A high merge frequency indicates a healthy pipeline and a lack of PR lifecycle bottlenecks that frustrate developers.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "N.A.",
  },
  "Commits without Pull Requests": {
    definition: [
      "The metric % Commits Without Pull Request is used to measure the percentage of code commits in a version control system (e.g., Git) that were made directly to a branch without going through a formal pull request process.",
      "Pull requests are a common practice in collaborative software development, as they facilitate code review, discussion, and validation of code changes before they are merged into the main codebase.",
    ],
    use: "N.A.",
    benchmark:
      "Lower is the percentage, better it is.  Aim for not more than 20%",
    tool: "Jira and GitHub/Bitbucket ",
  },
  "Resolved Defects Per Team Member": {
    definition: [
      "A bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to you to a reasonably tolerable amount.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Defects Per Team Member": {
    definition: [
      "A bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to you to a reasonably tolerable amount.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Defects Lead Time": {
    definition: [
      "The time it takes for the team to find the bugs in the software and to verify and validate the fix.",
      " In short, from tracking the bugs and making sure the bugs are fixed the way they were supposed to, to closing out the issue in a reasonable time, this KPI ensures it all.Very Important for quality tracking.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Defects Cycle Time": {
    definition: [
      "A bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to you to a reasonably tolerable amount.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Defects": {
    definition: [
      "A bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to you to a reasonably tolerable amount.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defects In Progress": {
    definition: [
      "Defects in progress refers to issues or errors identified in a software or product that are currently being addressed and worked on by development or testing teams, but haven't been fully resolved yet.",
      "Number of bugs which is yet to get fixed.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defects In Backlog": {
    definition: [
      "The set of all known and unresolved defects in the project; defect backlog in stored in the defect database.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Pull Requests": {
    definition: [
      "It tells others about changes you've pushed to a branch in a repository on GitHub. Once a pull request is opened, you can discuss and review the potential changes with collaborators and add follow-up commits before your changes are merged into the base branch.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket ",
  },
  "Pull Request Resolution Time": {
    definition: ["How long it takes to resolve pull requests."],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "GitHub/Bitbucket ",
  },
  "Schedule Varient": {
    definition: [
      "This is the difference between the percentage of work completed versus the percentage expected to be completed by a particular date.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira/Azure",
  },
  "Defects In Backlog Per Team Member": {
    definition: ["Number of bugs that are there in the backlog."],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Vulnerabilities": {
    definition: ["A point in your code that's open to attack."],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "SonarQube",
  },
  "Resolved Vulnerabilities Cycle Time": {
    definition: [
      "The time it takes to start fixing the point of code that open to attack until the code is attack free.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "SonarQube",
  },
  "Resolved Vulnerabilities Lead Time": {
    definition: [
      "The time it takes to find the point of code that is open to attack until the code is attack free.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "SonarQube",
  },
  "Resolved Vulnerabilities Per Team Member": {
    definition: ["A point in your code that's open to attack.."],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "SonarQube",
  },
  "Stories Completed (Count)": {
    definition: [
      ' "Story Completed"  metric refers to a key performance indicator (KPI) or measurement used to assess the progress and status of user stories within an Agile software development or project management process.',
      "This metric is often used by Agile teams to track and communicate the status of individual user stories as they move through various stages of development, from initial backlog planning to final implementation and delivery.",
      "It provides a clear indication of whether a user story has been fully completed, tested, and is ready for deployment.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Stories Completed": {
    definition: [
      ' "Story Completed"  metric refers to a key performance indicator (KPI) or measurement used to assess the progress and status of user stories within an Agile software development or project management process.',
      "This metric is often used by Agile teams to track and communicate the status of individual user stories as they move through various stages of development, from initial backlog planning to final implementation and delivery.",
      "It provides a clear indication of whether a user story has been fully completed, tested, and is ready for deployment.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Story Points Completed Per Team Member": {
    definition: [
      ' "Story Points Completed Per Team Member" is a performance metric commonly used in Agile software development to measure the productivity and contribution of individual team members during a specific time frame, typically a sprint or iteration.',
      "It quantifies the amount of work, represented in story points, that each team member has successfully completed within the defined timeframe.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Story Points Completed": {
    definition: [
      ' "Story Completed"  metric refers to a key performance indicator (KPI) or measurement used to assess the progress and status of user stories within an Agile software development or project management process.',
      "This metric is often used by Agile teams to track and communicate the status of individual user stories as they move through various stages of development, from initial backlog planning to final implementation and delivery.",
      "It provides a clear indication of whether a user story has been fully completed, tested, and is ready for deployment.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Story Point Cycle Time": {
    definition: [
      ' "Story Completed Cycle Time" is a performance metric used in Agile software development and project management to measure the time it takes for a user story to go from the initial planning or backlog to being fully completed and ready for deployment.',
      "This metric provides valuable insights into the efficiency and speed of a development team's workflow.",
      "Story Completed Cycle Time is valuable for Agile teams because it provides real-time feedback on how quickly they can deliver value to users.",
      "Shorter cycle times often indicate higher efficiency, while longer cycle times may suggest areas for improvement.",
      "By consistently monitoring and optimizing this metric, teams can enhance their agility and responsiveness to changing project requirements.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Total Work Delivered (Story, Task, Bug) (Count)": {
    definition: ["The total number of stories, bugs , issues completed"],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Total Work Delivered (Story, Task, Bug)": {
    definition: ["The total number of stories, bugs , issues completed"],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect in Backlog (Count)": {
    definition: [
      "The level of defect backlog is then defined as the number of defects in the backlog.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect in Backlog ": {
    definition: [
      "The level of defect backlog is then defined as the number of defects in the backlog.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect Cycle Time ": {
    definition: [
      '"Defect Cycle Time" is a crucial metric used in software development and quality assurance processes to measure the time it takes to complete the entire lifecycle of a defect, from its initial identification to its resolution and verification.',
      "This metric is essential for assessing the efficiency and effectiveness of the defect management process.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Total Time Spent (Bug + Feature)": {
    definition: [
      "Total Time Spent (Bug + Feature) is a metric used in software development and project management to measure the cumulative amount of time dedicated to working on both bug fixes and feature development within a project or a specific time frame.",
      "This metric provides valuable insights into how resources are allocated between maintaining existing functionality (bug fixes) and creating new functionality (features).",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect in Progress (Count) ": {
    definition: [
      "Number of defects which are in the progress stage to be resolved.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect in Progress (Count)": {
    definition: [
      "Number of defects which are in the progress stage to be resolved.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect in Progress ": {
    definition: [
      "Number of defects which are in the progress stage to be resolved.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Defect (%)": {
    definition: [
      "Means a bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to a reasonably tolerable amount.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Resolved Defect ": {
    definition: [
      "Means a bug-fix, patch, correction code, update, upgrade or other modification, procedure, routine or workaround that corrects a Defect or eliminates the practical adverse effects of a Defect to a reasonably tolerable amount.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Defect Lead Time (Days) ": {
    definition: [
      '"Defect Lead Time" is a critical performance metric used in software development and quality assurance processes to measure the time it takes to address and resolve defects (bugs, issues, or problems) identified within a software product or system.',
      "This metric helps assess the efficiency and effectiveness of the defect management process, from defect discovery to resolution.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Time Spent in Bug Fixing": {
    definition: [
      '"Time Spent in Bug Fixing" is a metric used in software development and quality assurance processes to measure the cumulative amount of time spent by a team or an individual developer on identifying, investigating, and resolving defects (bugs, issues, or problems) within a software project or a specific time frame.',
      "This metric provides insights into the effort and resources dedicated to improving software quality and addressing issues.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Time Spent in adding new Feature": {
    definition: [
      '"Time Spent in Adding New Feature" is a metric used in software development and project management to measure the cumulative amount of time spent by a team or an individual developer on designing, developing, testing, and delivering new features or enhancements within a software project or a specific time frame.',
      "This metric provides insights into the effort and resources allocated to extending or improving the software's functionality.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Stories Point Completed ": {
    definition: [
      ' "Story Completed"  metric refers to a key performance indicator (KPI) or measurement used to assess the progress and status of user stories within an Agile software development or project management process.',
      "This metric is often used by Agile teams to track and communicate the status of individual user stories as they move through various stages of development, from initial backlog planning to final implementation and delivery.",
      "It provides a clear indication of whether a user story has been fully completed, tested, and is ready for deployment.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Avg Lead Time": {
    definition: [
      "The metric Average Lead Time is a key performance indicator (KPI) used to measure the average time it takes for a work item to move from the start of its development process to its completion.",
      "Average Lead Time is particularly valuable in Agile and Lean methodologies, where reducing lead times is a primary objective to achieve faster and more responsive delivery.",
    ],
    use: "N.A.",
    benchmark: ["1 day to 30 days is industry average "],
    tool: "GitHub/Bitbucket/Jira",
  },
  "Avg PR Resolution Time (Days) ": {
    definition: [
      "the average time taken to solve all Pr issues in a given time frame",
    ],
    use: "N.A.",
    benchmark: [
      "<1 day : Elite ",
      " 1 to 2 days : Strong ",
      " > 2 days : Needs focus",
    ],
    tool: "GitHub/Bitbucket",
  },
  "PR Merge Frequency (Count)": {
    definition: [
      "Measures the rate at which developers are able to merge code branched back into the mainline or trunk. This can be thought of as both an efficiency metric and a measure of the developer experience.",
    ],
    use: "N.A.",
    benchmark: [
      "High merge frequency indicates a healthy pipeline and a lack of PR lifecycle bottlenecks that frustrate developers",
    ],
    tool: "GitHub/Bitbucket",
  },
  "PR Merge Frequency ": {
    definition: [
      "Measures the rate at which developers are able to merge code branched back into the mainline or trunk. This can be thought of as both an efficiency metric and a measure of the developer experience.",
    ],
    use: "N.A.",
    benchmark: [
      "High merge frequency indicates a healthy pipeline and a lack of PR lifecycle bottlenecks that frustrate developers",
    ],
    tool: "GitHub/Bitbucket",
  },
  "Sprint Completion ratio": {
    definition: [
      "Core Scrum metric so you can understand the overall success of your sprints and a closer look at the outcome of tickets at sprint end.",
      sprint,
      "Sprint completion - Percentage of tickets completed within a sprint from the tickets that either started the sprint or were added to the sprint after it started (i.e., a combination of the first two).",
      "Completion percentage of each sprint from the tickets that either started the sprint or were added to the sprint after it started. Calculation can be based on ticket count or story points, and issue type filter available to select relevant tickets.",
    ],
    use: "N.A.",
    benchmark: ["90% is industry benchmark "],
    tool: "Jira",
  },
  "Work Commitment": {
    definition: [
      "The total number of story points committed before the start of the sprint.",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira",
  },
  "Avg Defect Lead Time": {
    definition: ["Measure the time between defect-found and defect-fixed."],
    use: "N.A.",
    benchmark: [
      "The lower is the average defect lead time, the more efficient your team is.",
    ],
    tool: "Jira",
  },
  "Sprint velocity": {
    definition: [
      "The amount of work that the team completes measures delivery throughput. This provides a breakdown of how many tasks were completed during an iteration. Issues are determined by the ticket-types available in the project management board linked to your team.",
      "The summary value of velocity is expressed as an average of your past 3 sprints, 5 sprints, or all your sprints. You can change this in the metric settings. ",
      "Sprints with a large variance of velocity over a three-sprint period would indicate a high velocity.",
      "The lower the volatility score, the more predictable your sprints are. This means there is also a higher probability you can accurately estimate and plan your sprints based on the average sprint velocity.",
      "The Velocity report provides a breakdown of how many tasks were completed during the iteration according to the ticket type in Jira.",
      "The Velocity report analyses your connected Project Management boards (Jira or Trello).",
    ],
    use: "N.A.",
    benchmark: ["Not applicable."],
    tool: "Jira/Azure/Trello ",
  },
  "Capacity Accuracy": {
    definition: [
      " It measures all completed (planned and unplanned) work as a ratio of planned work. It answers the question that “is my team taking the amount of work that they can reasonably complete?",
    ],
    use: "N.A.",
    benchmark: ["than 75% is industry average"],
    tool: "Jira",
  },
  "Commit Without Ticket Reference": {
    definition: [
      " Commits Without a Ticket Reference is an often-overlooked engineering quality metric.",
      "This metric tracks the percentage of code commits that do not have related issue management (e.g. Jira) Ticket References to enable a full audit history of each commit. In short, Commits Without a Ticket Reference figures out which – and how many – commits are missing Ticket References.",
      "Mature Agile DevOps organisations recognise the need to view Agile software delivery as an end-to-end process. Hence, they also understand the need to track code commits back to the team/feature/Ticket to which they relate.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "91% to 100% : Elite Performance.",
      "85% to 90% : Medium Performance. ",
      "less than 85%: Need Improvement.",
    ],
  },
  "Commit without pull request": {
    definition: [
      " it measures all merges to master that has not been peer-reviewed (i.e. do not have a related pull request). This is generally considered as poor engineering practice and a potential security concern, particularly in organisations with engineers of variable experience and varied familiarity with the code base.",
      "CWPR rates may vary widely between teams, locations, contractors etc – and hence it is a key software engineering quality metric. As such, software delivery teams at a higher level of Agile DevOps maturity will routinely focus on CWPR as a part of their broader engineering quality management process.",
      "It is also a popular metric for delivery teams focused on DevSecOps, as effective DevSecOps recognises that teams and engineers themselves need to consistently put engineering security at the heart of their daily routines and processes.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "91% to 100% : Elite Performance.",
      "85% to 90% : Medium Performance. ",
      "less than 85%: Need Improvement.",
    ],
  },
  "Pr comments": {
    definition: [
      "It measures the average number of comments per pull request review. This metric is an indication regarding the quality of the review and how thorough reviews are done.",
      "Why is it important? Reviews are an important factor for improving code quality and finding quality issues in code before it is merged and deployed.",
    ],
  },
  "Pr review time": {
    definition: ["The time it takes to review the pull request"],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "12 Hrs to 24 Hrs : Elite Performance",
      "25 Hrs to 72 Hrs : Medium Performance ",
      "Greater than 72 Hrs: Need Improvement",
    ],
  },
  "Pull Request Review Time (Days)": {
    definition: ["The time it takes to review the pull request"],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "12 Hrs to 24 Hrs : Elite Performance",
      "25 Hrs to 72 Hrs : Medium Performance ",
      "Greater than 72 Hrs: Need Improvement",
    ],
  },
  "First Time pass issue": {
    definition: [
      "It measures the percentage of Tickets that passes QA the first time without stimulating a return transition or defect sub-task. As such, this metric is an excellent measure of overall team health.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "91% to 100% : Elite Performance",
      "85% to 90% : Medium Performance ",
      "less than 85%: Need Improvement",
    ],
  },
  "First Time Pass (%)": {
    definition: [
      "It measures the percentage of Tickets that passes QA the first time without stimulating a return transition or defect sub-task. As such, this metric is an excellent measure of overall team health.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies",
      "91% to 100% : Elite Performance.",
      "85% to 90% : Medium Performance.",
      "less than 85% : Need Improvement.",
    ],
  },
  Receptiveness: {
    definition: [
      "The percentage of comments the submitter accepts as denoted by code revisions",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Greater than 20% : Elite Performance",
      "10% to 20% : Medium Performance ",
      "less than 30%: Need Improvement",
    ],
  },
  "Comments addressed": {
    definition: ["The percentage of comments to which a submitter responds."],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Greater than 45% : Elite Performance",
      "30% to 45% : Medium Performance ",
      "less than 30%: Need Improvement",
    ],
  },
  Allocation: {
    definition: [
      "Allocation is the measurement of the distribution of a software engineering team’s work across a number of axes.In order to inform decisions around investing in new products or capabilities, this metric helps leaders understand how their team’s energy is distributed, and how much capacity they have to take on new projects.Allocation demonstrates to engineering leaders through visualizations how close their engineering teams are ideal to an ideal work alignment.This is done by breaking down the work they do across axes that matter to the engineering organization.",
      "Data source : Jira/Azure , GitHub/Bitbucket/Gitlab, Jenkins , Calendar system",
    ],
  },
  Process: {
    definition: ["Process (are we following the best practices)."],
  },
  Execution: {
    definition: ["Delivery execution (what's the status of current work )."],
  },
  Quality: {
    definition: ["Quality (what's the quality of work looks like )."],
  },
  Collaboration: {
    definition: [
      " It measures developer teamwork and contribution to peer reviews.",
    ],
  },
  "Team Productivity": {
    definition: ["How to increase throughput without burning out People."],
  },
  "Resource Health": {
    definition: ["How to increase throughput without burning out People."],
  },
  "Work Breakdown": {
    definition: [
      "Devboost  divides work (code changes) into 3 categories:",
      "New work - New work is brand new code that has been added to the code base.",
      `Refactor - The process of paying down on technical debt, where code older than 28 days is rewritten`,
      "Some degree of refactored code is acceptable and even required for improving systems quality.",
      "A large amount of refactoring in a single release is not recommended, as these changes have a higher probability of harming existing functionality.",
      "Rework - The number of lines of code added and deleted over the last 28 days before the commit is merged",
      "High levels of reworked code could indicate a quality issue in recent releases.",
    ],
    use: "N.A.",
  },
  "Cycle Time Breakdown": {
    definition: [
      "It displays the total time that you needed to complete your work split by process state.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 1 Day : Elite Performance",
      "Greater than 3 Day : Medium Performance ",
      "Greater than 5 Day : Need Improvement",
    ],
    use: "N.A.",
  },
  "Cycle Time (Pull Request)": {
    definition: [
      "We divide cycle time into four different phases: coding time, pickup time, review time, and deploy time. Let’s take a closer look at what goes into calculating cycle time:",
      "Coding Time: We calculate coding time from first commit to when a pull request or merge request is created.",
      "Pickup Time: We calculate pickup time from when the PR is created to when the review begins.",
      "Review Time: We calculate review time from the start of the review to when the code is merged.",
      "Deploy Time: We calculate deploy time from when the code is merged to when it is released.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 1 Day : Elite Performance",
      "Greater than 3 Day : Medium Performance ",
      "Greater than 5 Day : Need Improvement",
    ],
  },
  "Work Allocation": {
    definition: [
      "Work Allocation refers to the process of assigning tasks, responsibilities, and resources to individuals or teams within an organization.",
    ],
    use: "N.A.",
  },
  "Team Work Allocation": {
    definition: [
      "Work Allocation refers to the process of assigning tasks, responsibilities, and resources to individuals or teams within an organization.",
    ],
    use: "N.A.",
  },
  "Work In Progress": {
    definition: [
      "The Aging Chart helps you identify where your process is slowing down while the task is still in progress",
    ],
    use: "N.A.",
  },
  "Average Flow Efficiency": {
    definition: [
      "It  analyses the flow of work throughout the end-to-end delivery life cycle and reflects the proportion of time that a Ticket was actively being worked on. In other words, this metric represents the time a Ticket was actively being worked on as a percentage of the total Lead Time.",
      "As such, Flow Efficiency is one of the most powerful metrics to help you improve the efficiency of your software delivery process.",
      "Flow Efficiency enables teams to quickly pinpoint stages in the delivery process where excessive friction or “waste” exists. By reducing the process “waste” and time spent in a queueing state, teams can quickly and quite dramatically increase their time to market and throughput without the need to increase headcount.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Greater than 45% : Elite Performance",
      "15% to 40% : Medium Performance ",
      "less than 15% : Need Improvement",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "95% to 70% : Elite Performance",
      "65% to 70% : Medium Performance ",
      "less than 65% : Need Improvement",
    ],
  },
  "Quality Assurance": {
    definition: [
      "It is a process that helps companies ensure their products reach designated standards.",
    ],
    use: "N.A.",
  },
  "Code Churn": {
    definition: [
      "A quantified representation of the event when an engineer re-writes their own code shortly after it has been checked in - less than 4 weeks old. A certain amount of Churn code can be expected for any developer.",
      "Unusual spikes in Churn can be an accurate indicator that an engineer is stuck. High Churn rates may also indicate another problem, such as inadequate specs. Knowing immediately as your team experienced churn spikes helps you have timely conversations to surface any potential problems.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "0% to 5% : Elite Performance",
      "5% to 30% : Medium Performance ",
      "Greater than 30% : Need Improvement",
    ],
  },
  "Code Churn (%)": {
    definition: [
      "A quantified representation of the event when an engineer re-writes their own code shortly after it has been checked in - less than 3 weeks old. A certain amount of Churn code can be expected for any developer.",
      "Unusual spikes in Churn can be an accurate indicator that an engineer is stuck. High Churn rates may also indicate another problem, such as inadequate specs. Knowing immediately as your team experienced churn spikes helps you have timely conversations to surface any potential problems.",
    ],
    use: "N.A.",
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "0% to 5% : Elite Performance",
      "5% to 30% : Medium Performance ",
      "Greater than 30% : Need Improvement",
    ],
  },
  "Delivery Tracker": {
    definition: [
      "It offers a high-level view of an organization's projects as they are managed by the organization's project management tool (Jira). A Devboost project is a flexible entity that can include a board, an epic, a label, fix version, or custom field value, or any combination of the entities mentioned above.",
    ],
    use: "N.A.",
  },
  "Completion Rate (%)": {
    definition: [
      " It  refers to the user stories, tasks, or backlog items that the development team successfully finished within the defined time frame of the Sprint.",
    ],
    use: "N.A.",
  },
  "Commit Without Ticket Reference (%)": {
    definition: [
      " Commits Without a Ticket Reference is an often-overlooked engineering quality metric.",
      "This metric tracks the percentage of code commits that do not have related issue management (e.g. Jira) Ticket References to enable a full audit history of each commit. In short, Commits Without a Ticket Reference figures out which – and how many – commits are missing Ticket References.",
      "Mature Agile DevOps organisations recognise the need to view Agile software delivery as an end-to-end process. Hence, they also understand the need to track code commits back to the team/feature/Ticket to which they relate.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Less than 5% : Elite Performance.",
      "10% to 5%:  : Medium Performance.",
      "Greater than 10%: Need Improvement.",
    ],
  },
  "Commit With Pull Request (%)": {
    definition: [
      " it measures all merges to master that has not been peer-reviewed (i.e. do not have a related pull request). This is generally considered as poor engineering practice and a potential security concern, particularly in organisations with engineers of variable experience and varied familiarity with the code base.",
      "CWPR rates may vary widely between teams, locations, contractors etc – and hence it is a key software engineering quality metric. As such, software delivery teams at a higher level of Agile DevOps maturity will routinely focus on CWPR as a part of their broader engineering quality management process.",
      "It is also a popular metric for delivery teams focused on DevSecOps, as effective DevSecOps recognises that teams and engineers themselves need to consistently put engineering security at the heart of their daily routines and processes.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Greater than 95% : Elite Performance.",
      "90% to 95%  : Medium Performance. ",
      "less than 90%: Need Improvement.",
    ],
  },
  "Merged Reviewed Pull Request (%)": {
    definition: ["represents the percentage of PRs reviewed and then merged"],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "91% to 100% : Elite Performance",
      "85% to 90% : Medium Performance ",
      "less than 85%: Need improvement",
    ],
  },
  "Merged Reviewed PR (%)": {
    definition: ["represents the percentage of PRs reviewed and then merged"],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "91% to 100% : Elite Performance",
      "85% to 90% : Medium Performance ",
      "less than 85%: Need improvement",
    ],
  },
  "Avg Pr Size": {
    definition: ["The number of merged pull requests of an engineer."],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Less than 250 line of code is : Elite Performance",
      "250 Lines of Code to  800 Lines of code is : Medium Performance ",
      "Greater than 800 line of code Need Attention",
    ],
  },
  "Deployment Frequency (Count)": {
    definition: [
      "It  measures how often new code is deployed to a production environment. This metric correlates with the speed and the quality of your engineering team. It tracks how quickly teams can release new features or bugs",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies",
      "1 or more deployment per day is : Elite Performance.",
      "1 or more deployment per week is : Medium Performance.",
      "less than 1 deployment per week is: Need Improvement.",
    ],
  },
  "Change Failure Rate (%)": {
    definition: [
      "(CFR) is the percentage of changes that result in unintended consequences, such as downtime, errors, or negative impact on users.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 1% : Elite Performance",
      "1% to 39% : Medium Performance ",
      "Greater than 39%: Need improvement",
    ],
  },
  "Deployment Rollback (Hours)": {
    definition: [
      "Deployment rollback is the process of restoring your system to a previous state when a deployment fails or causes issues. It is an essential part of any system deployment strategy, as it can help you minimize downtime, avoid data loss, and preserve user trust.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "0 Mins to 25 Mins : Elite Performance",
      "25 Mins to 60 Mins  : Medium Performance",
      "Greater than 1 Hrs: Need Improvement",
    ],
  },
  "Merged Pull Request (%)": {
    definition: ["The number of merged pull requests of an engineer."],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies",
      "Greater 95% : Elite Performance.",
      "90% to 95% : Medium Performance.",
      "less than 90%: Need Improvement.",
    ],
  },
  "Merged PR (%)": {
    definition: ["The number of merged pull requests of an engineer."],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies",
      "Greater 95% : Elite Performance.",
      "90% to 95% : Medium Performance.",
      "less than 90%: Need Improvement.",
    ],
  },
  "Comment Addressed (%)": {
    definition: [
      "The percentage of Reviewer comments that were responded to with a comment or a code revision.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies",
      "Greater 45% : Elite Performance.",
      "30% to 45% : Medium Performance.",
      "less than 30%: Need Improvement.",
    ],
  },
  "Pull Request Has Comment (%)": {
    definition: [
      "The number of comments addressed by an engineer in all pull requests.",
    ],
  },
  "Receptiveness (%)": {
    definition: [
      "Receptiveness is the ratio of follow-on commits to comments. It’s important to remember that Receptiveness is a ‘goldilocks’ metric—you’d never expect this metric to go up to 100%, and if you did it’d be indicative of a fairly unhealthy dynamic where every single comment lead to a change.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "Greater than 20% : Elite Performance",
      "10% to 20% : Medium Performance ",
      "less than 30%: Need Improvement",
    ],
  },
  "Avg Pull Request Idle Time": {
    definition: ["The total time through which the PR was open"],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 1 Day : Elite Performance",
      "Greater than 3 Day : Medium Performance ",
      "Greater than 5 Day : Need Improvement",
    ],
  },
  "Avg Legacy (%)": {
    definition: [
      "This metric refers to the process of paying down “technical debt” - and it is, traditionally, challenging to identify. New feature development frequently implies re-working old code, so these activities are not as clear cut as they might seem in Scrum meetings. As code-bases age, some percentage of developer attention is required to maintain the code and keep things current.",
      "The challenge is that team leads need to properly balance this kind of work with creating new features: it’s bad to have high technical debt, but it’s even worse to have a stagnant product. This balancing act is not something that should be done in the dark, particularly when it’s vital to the success of the whole company.",
      "Objectively tracking the percentage of time engineers spend on new features vs. application maintenance helps maintain a proper balance of forwarding progress with long-term code-base stability.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 9% : Elite Performance",
      "9% to 30% : Medium Performance ",
      "Greater than 30% : Need Improvement",
    ],
  },
  "Mean Time To Restore": {
    definition: [
      "Mean Time To Restore is a metric that represents the average time it takes to restore from a failure of the system or one of its components. devboost calculates this metric based on the time a production bug spends in the in progress state.",
    ],
    benchmark: [
      "Based on Industry Baseline of 10000+ companies:",
      "less than 1 Day : Elite Performance",
      "1 Day to 7 Day : Medium Performance ",
      "Greater than 7 day : Need Improvement",
    ],
  },
  "Slow Response": {
    definition: [
      "A ticket that has been assigned but has not been moved to in-progress for more than 7 days.",
    ],
    benchmark: [],
  },
  "Unassigned Work In Progress": {
    definition: [
      "A ticket that has been moved to a work-in-progress (WIP) state, but is not assigned to anyone.",
    ],
  },
  "Speedy Transition": {
    definition: [
      "Speeding Transitions Rate is a useful Agile delivery metric. It looks at how effectively workflow management tools (e.g. Jira, Azure DevOps, etc ) are being used by the engineering teams.  Specifically, it tracks how accurately teams are updating ticket statuses as work moves through the software delivery process.",
      "‘Speeding tickets’ are defined as those that spent less than 60 seconds in a particular ticket status.  As such, it shows where engineers are retrospectively updating ticket statuses (e.g. at the end of a sprint), having forgotten to do so in real time.",
      "If this is happening, it is very difficult to analyse workflow and make delivery process improvements – as there is no record of actually when tickets were in a certain status and for how long they really remained in that status.",
      "As such, when the Speeding Transitions Rate is high, it becomes impossible to analyse Cycle Time and Flow Efficiency and identify where bottlenecks may lie within the end-to-end delivery process.",
      "Formula: Ticket transitions within the Speeding threshold (seconds) / Total ticket transitions within selected time range * 100",
    ],
    benchmark: [
      // "Based on Industry Baseline of 10000+ companies:",
      // "less than 1 Day : Elite Performance",
      // "1 Day to 7 Day : Medium Performance ",
      // "Greater than 7 day : Need Improvement",
    ],
  },
  StalePr: {
    definition: [
      "A PR that has been opened but did not progress from one stage to another in more than 1 day.",
    ],
    benchmark: [],
  },
  LongRunningPr: {
    definition: [
      "A PR that has been opened but not merged for more than 3 days. This PR might be stuck or forgotten",
    ],
    benchmark: [],
  },
  RiskyChangesPr: {
    definition: [
      "A PR that potentially deserves more attention because of it complexity, meeting one of the following criteria:",
    ],
    benchmark: [
      "More than 100 LoC changed.",
      "The PR contains more than 10 commits.",
      "The PR touches more than 10 files.",
    ],
  },
  "Story Point Assigned": {
    definition: ["Number of Story Points assigned for this sprint."],
    benchmark: [],
  },
  "Task Assigned": {
    definition: ["Number of tasks assigned for this sprint."],
    benchmark: [],
  },
  "Story Point Completed (Overall)": {
    definition: ["Number of Story Points completed by the end of the sprint."],
    benchmark: [],
  },
  "Task Completed (Overall)": {
    definition: ["Number of Tasks completed by the end of the sprint."],
    benchmark: [],
  },
  "Story Point Spillover": {
    definition: ["Number of Story Point are not done."],
    benchmark: [],
  },
  "Task Spillover": {
    definition: ["Number of tasks which are not done."],
    benchmark: [],
  },
  "Planned Time": {
    definition: ["The time that was planned by the manager for this task."],
    benchmark: [],
  },
  "Time Spent": {
    definition: ["The time actually spent by the developer for this task."],
    benchmark: [],
  },
  "Sprint Overview": {
    definition: [
      "Executive level overview of the sprints and its status over a period of time.",
    ],
    benchmark: [],
  },
  Performance: {
    definition: [
      "If the actual time spent is greater than planned time and still there is spillover, Underperformed.",
      "If the actual time spent is less than planned time, and there is spillover, needs more attention time.",
      "If the actual time spent is less than planned time with no spillover, Overperformed.",
      "If the actual time spent is greater than planned time and there is no spillover, Average performance.",
      "If the actual time spent is equal to planned time, and there is no spillover, normal Performance.",
      "If the actual time spent is equal to planned time, and there is spillover, Underperformed.",
    ],
    benchmark: [],
  },
  "Performance Risk": {
    definition: [
      "If the actual time spent is greater than planned time and still there is spillover, Underperformed.",
      "If the actual time spent is less than planned time, and there is spillover, needs more attention time.",
      "If the actual time spent is less than planned time with no spillover, Overperformed.",
      "If the actual time spent is greater than planned time and there is no spillover, Average performance.",
      "If the actual time spent is equal to planned time, and there is no spillover, normal Performance.",
      "If the actual time spent is equal to planned time, and there is spillover, Underperformed.",
    ],
    benchmark: [],
  },
  "Utilization Status": {
    definition: [
      `If the tasks assigned in the current sprint are 10% above the average ${config.type} assigned to the developer over the last 7 sprints, then it will show overutilized; otherwise, same. If it is 10% below the average completed tasks over the last 7 sprints, underutilized.`,
    ],
    benchmark: [],
  },
  "New Feature %": {
    definition: ["The new line of code added."],
    benchmark: [],
  },
  "New Feature (Lines)": {
    definition: ["The new line of code added."],
    benchmark: [],
  },
  "Code Churn %": {
    definition: [
      "The number of lines of code added and deleted in the last 28 days.",
    ],
    benchmark: [],
  },
  "Code Churn (Lines)": {
    definition: [
      "The number of lines of code added and deleted in the last 28 days.",
    ],
    benchmark: [],
  },
  "Top Language": {
    definition: [
      "We calculate the percentage of lines written for each programming language relative to the total lines",
      "Expertise and Focus: Understand the developer's expertise and areas of focus by highlighting the languages they are most proficient in and where they have made the most significant contributions.",
    ],
    benchmark: [],
  },
  "Knowledge Area": {
    definition: [
      "This section displays up to the list of repositories based on developer activity. Repositories are ranked by developer engagement, including pull requests (PRs) and code reviews, number of commits.",
      "This analysis can identify knowledge islands, showing where specific developers have concentrated their efforts. This helps manage expertise distribution and promotes better collaboration and knowledge sharing.",
    ],
    benchmark: [],
  },
  "Story Point": {
    definition: [
      "Story points are a unit of measurement used to estimate the amount of effort required to complete a piece of work in agile development",
    ],
    benchmark: [],
  },
  "Task Burndown": {
    definition: [
      "A task burndown is a chart that shows the number of tasks completed and the number of tasks remaining.",
    ],
    benchmark: [],
  },
  "Ticket Without Epics/Stories": {
    definition: [
      "List of tickets that do not have any epics or stories mentioned.",
    ],
    benchmark: [],
  },
  "Dev Attention Time": {
    definition: ["Actual time spent by developers."],
    benchmark: [],
  },
  "Ticket Without Estimation": {
    definition: [
      "Ticket which does not have any scope or target date mentioned.",
    ],
    benchmark: [],
  },
  "Knowledge Sharing Index": {
    definition: [
      "Knowledge sharing happens when engineers are familiar with multiple areas of the code base and share that knowledge with others. There are two main ways to increase your team’s knowledge sharing. Engineers can work on multiple areas of the code base, or engineers can review each other’s code.",
      "Since it’s not always practical for an engineer to work on multiple areas of the code base, reviewing code is a valuable opportunity to engage with different areas of the code base. This allows engineers to know more parts of the code and ensures no one person is a knowledge silo for a particular part of the code.",
      "The Sharing index helps you determine whether you are increasing the proportion of users participating in code reviews and the balance of those reviews, or whether you tend to consolidate the group of people doing code review for your organization.",
      "This metric is helpful in identifying knowledge silos and people who are isolated in their code reviews. Find the outliers and take action to help them get involved.",
    ],
    benchmark: [
      "0.2 to 0.4: Average",
      "Less than 0.2 : Needs improvement .",
      "Greater than 0.4 : Elite team",
    ],
  },
  "Pr Size": {
    definition: [
      "Pull request (PR) size is a metric that measures the number of lines of code added, changed, or removed in a PR. It's calculated relative to the base branch for each PR.",
      "Why is PR size important?",
      "Review time: Smaller PRs are easier to review, and reviewers can find problems more easily. ",
      "Risk of bugs: Smaller PRs reduce the risk of introducing new bugs or production issues. ",
      "Cycle time: Larger PRs can lead to slower cycle times and more rework. ",
      "Best practices for PR size. ",
      "Keep PRs under 200 lines of code.",
      "Focus on one feature or bug fix per PR",
      "Limit the number of files changed",
      "Use descriptive commit messages",
      "Avoid mixing unrelated changes in the same PR",
    ],
    benchmark: [],
  },
  "Defect resolution time": {
    definition: [
      "Defect resolution time is the time it takes to fix a software defect after it's reported. It's a key metric used to measure the efficiency of a development team. ",
      "What does defect resolution time indicate ?",
      "Responsiveness: A short resolution time indicates that defects are addressed quickly.",
      "Efficiency: A short resolution time indicates that defects are resolved effectively.",
      "Customer satisfaction: A short resolution time can lead to higher customer satisfaction.",
      "Delivery time: A short resolution time can lead to faster delivery.",
      "Cost: A short resolution time can lead to lower costs.",
    ],
    benchmark: [],
  },
  "Test Case Efficiency": {
    definition: [
      "It is the ratio of  the number of defects resolved to  the total number of defects submitted",
    ],
    benchmark: [],
  },
  "Delivery Quality": {
    definition: ["How well the software delivery is"],
    benchmark: [],
  },
};
