import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Card, Button, makeStyles } from "@material-ui/core";
import Process from "./process/process";
import Quality from "./quality";
import instance from "../../../../utils/axiosHelper";
import Communication from "./communication";
import RangeDatePicker from "../../../../components/RangeDatePicker";
import { GlobalData } from "../../../../context/globalData";
import HealthStatus from "../../../../components/helthStatus";
import { datePayload } from "../../../../utils/moduleTabApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { config } from "../../../../Config/permission";
import processHealthImg from "../../../../assets/processHealth.png";
import communicationHealthImg from "../../../../assets/communicationHealth.png";
import qualityHealthImg from "../../../../assets/qualityHealth.png";
import executionHealthImg from "../../../../assets/executionHealth.png";
import DxiImg from "../../../../assets/projectDex.webp";
import StatusTable from "./execution/StatusTable";
import InfoIcon from "@material-ui/icons/Info";
import DxiUi from "./dxi/Index";

const useStyles = makeStyles({
  borderBottom: {
    borderBottom: "1px solid gray",
    paddingBottom: 4,
  },
  active: {
    borderBottom: " 5px solid #5EBDCC !important",
    paddingBottom: 0,
  },
});

function Index() {
  const [active, setActive] = useState("Delivery Tracker");
  const classes = useStyles();
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI, deliveryTracker } =
    useContext(GlobalData);
  const headerMenu = [
    // "Delivery Tracker",
    // "Process",
    // "Delivery Quality",
    // "Code Review Collaboration",
  ];
  if (config.project.projectLevelHealthDeliveryTracker) {
    headerMenu.push("Delivery Tracker");
  }
  if (config.project.projectLevelHealthProcess) {
    headerMenu.push("Process");
  }
  if (config.project.projectLevelHealthDeliveryQuality) {
    headerMenu.push("Delivery Quality");
  }
  if (config.project.projectLevelHealthCollaboration) {
    headerMenu.push("Code Review Collaboration");
  }
  if (config.project.projectLevelHealthDxi) {
    headerMenu.push("Developer Experience Index");
  }
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [showTrend, setShowTrend] = useState(false);
  const [executionStatus, setExecutionStatus] = useState();
  const [processStatus, setProcessStatus] = useState();
  const [communicationStatus, setCommunicationStatus] = useState();
  const [dxiStatus, setDxiStatus] = useState();
  const [qualityStatus, setQualityStatus] = useState();
  const [qualityStatusOnFeSide, setQualityStatusOnFeSide] = useState({
    avg: "",
    status: "",
  });

  const fetchData = async (type, url) => {
    try {
      const res = await instance.get(url);
      if (type === "Process") {
        setProcessStatus({
          status: res?.data?.HealthStatus,
          recommendation: res?.data?.additionalText,
          score: res?.data?.HealthScore,
        });
      } else if (type === "Delivery Quality") {
        console.log("res?.data", res?.data);
        setQualityStatus({
          status: res?.data[0]?.healthStatus,
          recommendation: res?.data[0]?.additionalText,
          score: res?.data[0]?.healthScore,
        });
      } else if (type === "Communication") {
        setCommunicationStatus({
          status: res?.data?.HealthStatus,
          recommendation: res?.data,
          score: res?.data?.HealthScore,
        });
      } else if (type === "Developer Experience Index") {
        setDxiStatus({
          status: res?.data?.DXIStatus,
          recommendation: res?.data,
          score: +res?.data?.DXIScore,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getProcessStatus = (type, date = projectRangePickerValue) => {
    const dateUrl = datePayload(date);
    if (type === "Process") {
      const url = `/api/v2/ph/health/project/${projectId}?${dateUrl}`;
      fetchData(type, url);
    } else if (type === "Delivery Quality") {
      const url = `/api/v2/quality/get/resource/healthstatus/${projectId}?${dateUrl}`;
      fetchData(type, url);
    } else if (type === "Communication") {
      const url = `/api/v2/cm/health/project/${projectId}?${dateUrl}`;
      fetchData(type, url);
    } else if (type === "Developer Experience Index") {
      const url = `/api/v2/projects/calculateDxi?${dateUrl}&projectId=${projectId}`;
      fetchData(type, url);
    }
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/quality/get/resources/project/${projectId}?${dateUrl}`;
    instance
      .get(url)
      .then((response) => {
        const sum = response?.data?.TeamResult?.reduce((accumulator, item) => {
          return accumulator + (item?.healthScore * 100 || 0);
        }, 0);
        const avg = sum / (response?.data?.TeamResult?.length || 1).toFixed(); // to prevent division by zero
        // const status = avg > 50 ? "GOOD" : "BAD";
        const status = avg < 30 ? "BAD" : avg < 70 ? "AVERAGE" : "GOOD";
        setQualityStatusOnFeSide({ avg: avg.toFixed(), status: status });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date]);

  const getComponent = useMemo(() => {
    switch (active) {
      case "Process":
        return (
          <Card className="p-3">
            <Process
              getProcessStatus={getProcessStatus}
              setShowTrend={setShowTrend}
              showTrend={showTrend}
              date={date}
              recommendation={processStatus}
            />
          </Card>
        );
      case "Code Review Collaboration":
        return (
          <Card className="p-3">
            <Communication
              getProcessStatus={getProcessStatus}
              showTrend={showTrend}
              setShowTrend={setShowTrend}
              date={date}
              recommendation={communicationStatus}
            />
          </Card>
        );
      case "Delivery Quality":
        return (
          <Card className="p-3">
            <Quality
              getProcessStatus={getProcessStatus}
              date={date}
              setShowTrend={setShowTrend}
              showTrend={showTrend}
              recommendation={qualityStatusOnFeSide}
            />
          </Card>
        );
      case "Delivery Tracker":
        // return <ScenarioPlanner setExecutionStatus={setExecutionStatus} />;
        return <StatusTable />;
      case "Developer Experience Index":
        return (
          <Card className="p-3">
            <DxiUi
              getProcessStatus={getProcessStatus}
              date={date}
              setShowTrend={setShowTrend}
              showTrend={showTrend}
              recommendation={qualityStatusOnFeSide}
            />
          </Card>
        );
      default:
        return "";
    }
  }, [active, date, showTrend]); // Dependencies array

  useEffect(() => {
    getProcessStatus("Process");
    getProcessStatus("Delivery Quality");
    getProcessStatus("Communication");
    getProcessStatus("Developer Experience Index");
  }, []);

  return (
    <>
      <Box
        className="mb-4"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "12px",
        }}
      >
        {headerMenu.map((e) => (
          <Card
            className={`${classes.borderBottom} cursorPointer border ${
              active === e ? classes.active : ""
            }`}
            onClick={() => setActive(e)}
            // style={{ width: "300px" }}
          >
            <Box px={3} textAlign={"center"} py={1}>
              {e === "Code Review Collaboration" && (
                <>
                  <Box mb={2}>
                    <img
                      src={communicationHealthImg}
                      alt=""
                      height={"100px"}
                      className="rounded-circle"
                    />
                  </Box>
                  <HealthStatus
                    status={communicationStatus?.status}
                  ></HealthStatus>
                  <span>
                    (
                    {communicationStatus?.score
                      ? communicationStatus?.score?.toFixed()
                      : 0}
                    %)&nbsp;
                  </span>
                </>
              )}
              {e === "Process" && (
                <>
                  <Box mb={2}>
                    <img src={processHealthImg} alt="" Height={"100px"} />
                  </Box>
                  <HealthStatus status={processStatus?.status}></HealthStatus>
                  <span>
                    (
                    {processStatus?.score ? processStatus?.score?.toFixed() : 0}
                    %)
                  </span>{" "}
                </>
              )}
              {e === "Delivery Quality" && (
                <>
                  <Box mb={2}>
                    <img
                      src={qualityHealthImg}
                      alt=""
                      height={"100px"}
                      className="rounded-circle"
                    />
                  </Box>
                  <HealthStatus
                    status={qualityStatusOnFeSide?.status}
                  ></HealthStatus>
                  {/* <span>({qualityStatus?.score}%)&nbsp;</span>  ====> This is actual code from backend but front end calculate */}
                  <span>
                    (
                    {qualityStatusOnFeSide?.avg
                      ? qualityStatusOnFeSide?.avg
                      : 0}
                    %)&nbsp;
                  </span>
                </>
              )}
              {e === "Delivery Tracker" && (
                <>
                  {/* {executionStatus && ( */}
                  <>
                    <Box mb={2}>
                      <img src={executionHealthImg} alt="" Height={"100px"} />
                    </Box>
                    {config?.name !== "bigbasket" && (
                      <>
                        {config.name === "LiveSpace" ? (
                          ""
                        ) : (
                          <>
                            <HealthStatus
                              status={deliveryTracker?.status}
                            ></HealthStatus>
                            <span>
                              (
                              {deliveryTracker?.score
                                ? deliveryTracker?.score?.toFixed()
                                : 0}
                              %)&nbsp;
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </>
                  {/* )} */}
                </>
              )}
              {e === "Developer Experience Index" && (
                <>
                  <Box mb={2}>
                    <img src={DxiImg} alt="" Height={"100px"} />
                  </Box>
                  <HealthStatus status={dxiStatus?.status}></HealthStatus>
                  <span>
                    (
                    {dxiStatus?.score
                      ? dxiStatus?.score?.toFixed()
                      : 0}
                    %)&nbsp;
                  </span>
                </>
              )}
              <div>
                {e}
                <span
                  onClick={() => {
                    setIsDefinaionPopup(true);
                    setDefinationKPI(e);
                  }}
                >
                  <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                </span>
              </div>
            </Box>
          </Card>
        ))}
      </Box>
      {/* <Card className="p-3"> */}
      {active !== "Delivery Tracker" && (
        <Box mb={3} display={"flex"} gridGap={10}>
          <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          <Button
            onClick={() => setShowTrend(true)}
            variant="contained"
            color="primary"
          >
            {" "}
            Show Trend
          </Button>
        </Box>
      )}
      {getComponent}
    </>
  );
}

export default Index;
