import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Fragment } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import IconButton from "@material-ui/core/IconButton";
import AlertNotification from "./alert/alert";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  span: {
    color: "#1E1E1E",
    " text-align": "left",
    font: "normal normal normal 24px/30px Lexend Deca",
    "letter-spacing": "0px",
    color: "#1E1E1E",
  },
  breadCrumbContainer: {
    display: "flex",
    alignItems: "center",
  },
  active: {
    font: "normal normal 600 24px/30px Lexend Deca",
  },
  useName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #1E1E1E",
    textTransform: "uppercase",
    borderRadius: "50%",
    width: "35px",
    height: "35px",
  },
}));

function BreadCrumb({ backHandler, list = [], active }) {
  const [userName, setUserName] = useState();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")).name;

  useEffect(() => {
    let data = [];
    let name = user?.split(" ");
    name?.forEach((item) => {
      let firstName = item.charAt(0);
      data.push(firstName);
    });
    let shortName = data.join("");
    setUserName(shortName);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.breadCrumbContainer}>
        {backHandler && (
          <div
            onClick={backHandler}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <ArrowBackIcon />
          </div>
        )}
        {list.map((e, index) => (
          <Fragment key={index}>
            <span className={classes.span}>{e}</span>
            <NavigateNextIcon />
          </Fragment>
        ))}
        <span className={`${classes.span} ${classes.active}`}>{active}</span>
      </div>
      <Box display={"flex"} alignItems={"center"} gridGap={"10px"}>
        {/* <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleClick}
          style={{ border: "1px solid black", padding: 5, color: "#5ebdcc" }}
        >
          <NotificationsNoneIcon />
        </IconButton>
        <AlertNotification handleClose={handleClose} anchorEl={anchorEl} /> */}
        <div className={classes.useName}>{userName}</div>
      </Box>
    </div>
  );
}

export default BreadCrumb;
