import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  makeStyles,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HealthStatus from "../../../../../components/helthStatus";
import CommonTable from "../../../../../components/commonTable";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { addDays, format } from "date-fns";
import RangeSelector from "../../../../../components/rangeSelector";
import {
  datePayload,
  getSelectedDate,
} from "../../../../../utils/moduleTabApi";
import SelectField from "../../../../../components/select/selectField";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import instance from "../../../../../utils/axiosHelper";
import ExecutionStatus from "../../health/execution/ececutionStatus";
import { GlobalData } from "../../../../../context/globalData";
// import { GlobalData } from "../../../context/globalData";
import { config } from "../../../../../Config/permission";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { formatTime } from "../../../../../components/jira/tables/jiraTable";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Groups3Icon from "@mui/icons-material/Groups3";
import Person2Icon from "@mui/icons-material/Person2";
import HealthDependCard from "./healthDependCard";

const StatusTable = () => {
  const {
    projectRangePickerValue,
    setProjectRangePickerValue,
    setDeliveryTracker,
  } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [openRange, setOpenRange] = useState(false);
  const [InfoCard, setInfoCard] = useState();
  const [TableData, setTableData] = useState([]);
  const [TableDataFiltered, setTableDataFiltered] = useState([]);
  const [tableFilterCount, setTableFilterCount] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState();
  const { projectId } = useParams();
  const statusMenu = [
    "Life-time Progress",
    "Overall Contributor",
    "Overall FTE",
  ];
  const [issueList, setIssueList] = useState([]);
  const [isIssue, setIsIssue] = useState([]);
  const [issueListInDetail, setIssueListInDetail] = useState({
    data: [],
    level: "",
  });
  const [inWordAndOutWordKeys, setInWordAndOutWordKeys] = useState({
    in: "",
    out: "",
  });

  const keyOfName =
    issueListInDetail?.level === "Issue"
      ? "key"
      : issueListInDetail?.level === "Team"
      ? "team"
      : "assignee";

  useEffect(() => {
    setProjectRangePickerValue(date);
  }, [date]);
  const history = useHistory();
  const getHealthInfo = () => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/overall/${projectId}?&${dateUrl}`)
      .then((response) => {
        setInfoCard(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const getHealhTable = () => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/deliverables/${projectId}?&${dateUrl}`)
      .then((response) => {
        setTableData(response?.data);
        setTableDataFiltered(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    getHealthInfo();
    getHealhTable();
  }, [date]);

  const getComparePop = (values, type) => {
    setShowPopup(true);
    getIssueList(values, type);
    setSelectedIssue();
  };

  const handelClose = () => {
    setShowPopup(false);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: `${config.project.projectLevelHealthDeliverableInProgressTableKey} id`,
        field: "key",
        render: (row) => (
          <a href={row?.link} target="_blank" rel="noreferrer">
            {row?.key}
          </a>
        ),
      },
      {
        title: `${config.project.projectLevelHealthDeliverableInProgressTableKey} Url`,
        field: "key",
        render: (row) => (
          <>
            {row?.status === "Done" ? (
              <Box color={"gray"} sx={{ cursor: "not-allowed" }}>
                {config.project.projectLevelHealthDeliverableInProgressTableKey}{" "}
                Url
              </Box>
            ) : (
              <Box
                color={"#0d6efd"}
                onClick={() =>
                  history.push({
                    pathname: `/project/${projectId}/healthExecution/${row?.key}`,
                    state: row,
                    date: date,
                  })
                }
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {config.project.projectLevelHealthDeliverableInProgressTableKey}{" "}
                Url
                <OpenInNewIcon
                  style={{ color: "#0d6efd", margin: "0px 2px" }}
                  fontSize="small"
                />
              </Box>
            )}
          </>
        ),
      },
      {
        title: `${config.project.projectLevelHealthDeliverableInProgressTableKey} Name`,
        field: "Description",
        render: (row) => (
          <div
            className="d-inline-block text-truncate"
            style={{ maxWidth: "150px" }}
          >
            {row?.Description}
          </div>
        ),
      },
      {
        title: `Overall Scope (${config.type})`,
        field: "scope",
      },
      {
        title: "Dev Attention",
        field: "DevAttention",
        render: (row) => (
          <>{row?.DevAttention[0]?.progressPercentage.toFixed()} %</>
        ),
      },
      {
        title: "Velocity",
        field: "velocity",
        render: (row) => row?.velocity.toFixed(2),
      },
      {
        title: "Team Name",
        field: "TeamName",
        render: (row) => (
          <ul>
            {Object?.keys(row?.TeamName)?.map((e) => (
              <li>{e ? e : ''}</li>
            ))}
          </ul>
        ),
      },
      {
        title: "Contributors",
        field: "uniqueResources",
        render: (row) => (
          <ul>
            {row?.uniqueResources?.map((e) => (
              <li>{e}</li>
            ))}
          </ul>
        ),
      },
      {
        title: "Issue Dependencies",
        field: "",
        render: (row) => (
          <div
            onClick={() => getComparePop(row, "Issue")}
            className="cursorPointer"
          >
            <InsertLinkIcon />
          </div>
        ),
      },
      {
        title: "Team Dependencies",
        field: "",
        render: (row) => (
          <div
            onClick={() => getComparePop(row, "Team")}
            className="cursorPointer"
          >
            <Groups3Icon />
          </div>
        ),
      },
      {
        title: "Resource Dependencies",
        field: "",
        render: (row) => (
          <div
            onClick={() => getComparePop(row, "Resource")}
            className="cursorPointer"
          >
            <Person2Icon />
          </div>
        ),
      },
      {
        title: "Progress (%)",
        field: "progress",
        render: (row) => (
          <>
            {row?.progress.toFixed()}%
            <LinearProgress
              variant="determinate"
              value={row?.progress.toFixed()}
              style={{
                color: "#39f5bd",
                backgroundColor: "lightgray",
                height: "6px",
              }}
            />
          </>
        ),
      },
      {
        title: "FTE (Person-months)",
        field: "fte",
        render: (row) => <>{row?.fte.toFixed(2)}</>,
      },
      {
        title: "Status",
        field: "status",
        render: (row) => (
          <ExecutionStatus
            status={row?.statusColor ? row?.statusColor : row?.status}
          >
            {row?.status}
          </ExecutionStatus>
        ),
      },
    ];

    if (config.project.projectLevelHealthDeliverablesNewTaskAdd) {
      baseColumns.push({
        title: "New Task Added",
        field: "latestTasks",
      });
    }
    if (config.project.projectLevelHealthDeliverablesComponentName) {
      baseColumns.push({
        title: "Component Name",
        field: "Component",
      });
    }
    if (config.project.projectLevelHealthDeliverablesNotes) {
      baseColumns.push({
        title: "Notes",
        field: "Notes",
        render: (row) => (
          <Tooltip title={row?.Notes} placement="top" arrow>
          <div
            className="d-inline-block text-truncate"
            style={{ maxWidth: "150px" }}
          >
            {row?.Notes}
          </div>
            </Tooltip>
        )
      });
    }
    if (config.project.projectLevelHealthDeliverablesCommitWithoutPr) {
      baseColumns.push({
        title: "Commit Without Pr",
        field: "CommitWithoutPr",
        render: (row) => row?.CommitWithoutPr,
      });
    }
    if (config.project.projectLevelHealthDeliverablesLegacyRefactor) {
      baseColumns.push({
        title: "Legacy Refactor",
        field: "LegacyRefactor",
        render: (row) => row?.LegacyRefactor,
      });
    }
    if (config.project.projectLevelHealthDeliverablesPriority) {
      baseColumns.push({
        title: "Priority",
        field: "priority",
        render: (row) => (row?.priority === 0 ? "Not Assigned" : row?.priority),
      });
    }
    if (config.project.projectLevelHealthDeliverablesNewWork) {
      baseColumns.push({
        title: "New Work (%)",
        field: "NewWork",
        render: (row) => <>{row?.NewWork} %</>,
      });
    }
    if (config.project.projectLevelHealthDeliverablesCommitCount) {
      baseColumns.push({
        title: "Commit Count",
        field: "commitCount",
        render: (row) => row?.commitCount,
      });
    }
    if (config.project.projectLevelHealthDeliverablesCodeChurn) {
      baseColumns.push({
        title: "Code Churn (%)",
        field: "codechurn",
        render: (row) => <>{`${row?.codechurn}`} %</>,
      });
    }
    if (config.project.projectLevelHealthDeliverablesPlannedRemainSpentTime) {
      baseColumns.push(
        {
          title: "Planned Time",
          field: "plannedTime",
          render: (row) => {
            return <div>{formatTime(row.plannedTime)}</div>;
          },
        },
        {
          title: "Remaining Time",
          field: "remainingTime",
          render: (row) => {
            return <div>{formatTime(row.remainingTime)}</div>;
          },
        },
        {
          title: "Spent Time",
          field: "timeSpent",
          render: (row) => {
            return <div>{formatTime(row.timeSpent)}</div>;
          },
        }
      );
    }
    if (config.project.projectLevelHealthDeliverablesForcastDate) {
      baseColumns.push({
        title: "Forecast",
        field: "expectedDate",
        render: (row) =>
          row?.expectedDate === null
            ? "Not Set"
            : format(new Date(row?.expectedDate), "dd-MMM-yyyy"),
      });
    }
    if (config.project.projectLevelHealthDeliverablesTargetDate) {
      baseColumns.push({
        title: "Target Date",
        field: "targetDate",
        render: (row) =>
          row?.targetDate === null
            ? "Not Set"
            : format(new Date(row?.targetDate), "dd-MMM-yyyy"),
      });
    }
    return baseColumns;
  }, [config]);

  const TitleNAme = (
    <>
      <span className="fs-5">
        {`Deliverables in-Progress (${TableData?.length || 0}) `}
      </span>
      <span className="text-muted fs-6">
        ( {getSelectedDate(date)} )
        {/* ({format(new Date(getSelectedDate(date).split(" to ")[0]), "MMM yyyy")}{" "}
        to{" "}
        {format(new Date(getSelectedDate(date).split(" to ")[1]), "MMM yyyy")}) */}
      </span>
    </>
  );

  // Filter of Deliverables in-Progress
  const DeliverablesRisk = (filter1, filter2, filter3) => {
    if (filter1 === "All") {
      setTableData(TableDataFiltered);
      return;
    }
    const DeliverablesFilter = TableDataFiltered?.filter((item, index) => {
      return [filter1, filter2, filter3]?.some((filter) =>
        item?.status?.includes(filter)
      );
    });
    setTableData(DeliverablesFilter);
  };

  // Count of Deliverables in-Progress
  useEffect(() => {
    const counts = {
      high: 0,
      medium: 0,
      low: 0,
    };

    TableDataFiltered?.forEach((item) => {
      if (
        ["delayed", "Slipping"].some((filter) => item?.status?.includes(filter))
      ) {
        counts.high += 1;
      } else if (
        ["To do", "On hold", "In Progress"].some((filter) =>
          item?.status?.includes(filter)
        )
      ) {
        counts.medium += 1;
      } else if (
        ["Done", "On Time", "Open"].some((filter) =>
          item?.status?.includes(filter)
        )
      ) {
        counts.low += 1;
      }
    });

    setTableFilterCount(counts);
  }, [TableDataFiltered]);

  useEffect(() => {
    let statusAndPer = {};
    const totalScore =
      ((tableFilterCount.high + tableFilterCount.medium) /
        TableDataFiltered.length) *
      100;

    const totalOfDeliveryTracker = totalScore;

    if (totalOfDeliveryTracker <= 30) {
      statusAndPer.status = "BAD";
      statusAndPer.score = totalScore;
    } else if (totalOfDeliveryTracker >= 30 && totalOfDeliveryTracker <= 70) {
      statusAndPer.status = "AVERAGE";
      statusAndPer.score = totalScore;
    } else if (totalOfDeliveryTracker > 70) {
      statusAndPer.status = "GOOD";
      statusAndPer.score = totalScore;
    }

    setDeliveryTracker(statusAndPer);
  }, [TableDataFiltered, tableFilterCount]);

  const getIssueList = (data, type) => {
    const url = `/api/v2/requirementAnalytic/issuelevelDependancy?epic=${data?.key}`;
    instance
      .get(url)
      .then((response) => {
        setIssueListInDetail({ data: response?.data, level: type });
        const allIssue = response?.data?.map((e) => {
          return e.Key;
        });
        setIssueList(allIssue);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    const filterIssue = issueListInDetail?.data.filter(
      (item) => item?.Key === selectedIssue
    );
    setIsIssue(filterIssue);
  }, [selectedIssue, issueListInDetail]);

  useEffect(() => {
    let inWordKey = [];
    let outWordKey = [];
    isIssue?.forEach((item) =>
      item?.Data?.forEach((innerItem) => {
        if (innerItem?.IssueLinks?.inwardIssue?.key) {
          inWordKey.push(innerItem?.IssueLinks?.inwardIssue?.[keyOfName]);
        } else {
          outWordKey.push(innerItem?.IssueLinks?.outwardIssue?.[keyOfName]);
        }
      })
    );
    setInWordAndOutWordKeys({
      in: inWordKey.join(", "),
      out: outWordKey.join(", "),
    });
  }, [isIssue]);

  return (
    <div>
      <Card className="p-3 mb-3 border">
        <Box mb={1} display={"flex"} justifyContent={"flex-end"}>
          <SelectField
            input={
              <Button
                onClick={() => setOpenRange(true)}
                style={{
                  border: "1px solid #b7b7b7",
                  width: "230px",
                  textTransform: "capitalize",
                  padding: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "14px ",
                  paddingRight: "11px",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  height: "54px",
                  color: "black",
                }}
                endIcon={<DateRangeOutlinedIcon />}
              >
                {getSelectedDate(date)}
              </Button>
            }
            onClick={() => setOpenRange(true)}
          />
          <RangeSelector
            open={openRange}
            setOpen={setOpenRange}
            pickerHandler={(val) => setDate(val)}
            value={date}
            isNextDate={false}
          />
        </Box>
        <Grid container spacing={1}>
          {statusMenu?.map((e) => (
            <Grid item xs={4} key={e}>
              <Card className="border">
                <Box px={3} textAlign={"center"} py={1}>
                  {e === "Life-time Progress" && (
                    <>
                      <Box>
                        <h5>{InfoCard?.LifeTimeProgress?.toFixed()} %</h5>
                        <Box my={1}>
                          <HealthStatus status={"Good"}></HealthStatus>
                        </Box>
                      </Box>
                    </>
                  )}
                  {e === "Overall Contributor" && (
                    <>
                      <Box>
                        <h5>{InfoCard?.OverallContributor}</h5>
                        <div>
                          <PeopleAltIcon
                            style={{ color: "#9ed368", margin: "5px 0px" }}
                          />
                        </div>
                      </Box>
                    </>
                  )}
                  {e === "Overall FTE" && (
                    <>
                      <Box>
                        <h5>
                          {InfoCard?.OverallFte.toFixed()} (Person-months)
                        </h5>
                        <div>
                          <PersonIcon
                            style={{ color: "#9ed368", margin: "5px 0px" }}
                          />
                        </div>
                      </Box>
                    </>
                  )}
                  {e}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Card className="border position-relative">
        <Box className="position-absolute riskButtons">
          <button onClick={() => DeliverablesRisk("All")}>See All</button>
          <button onClick={() => DeliverablesRisk("delayed", "Slipping")}>
            <FiberManualRecordIcon sx={{ color: "red" }} /> High Risk (
            {tableFilterCount?.high})
          </button>
          <button
            onClick={() => DeliverablesRisk("To do", "On hold", "In Progress")}
          >
            <FiberManualRecordIcon sx={{ color: "yellow" }} /> Medium Risk (
            {tableFilterCount?.medium})
          </button>
          {/* <button
          onClick={() => DeliverablesRisk("Done", "On Time", "Open")}
          >
            <FiberManualRecordIcon sx={{ color: "green" }} /> Low Risk (
            {tableFilterCount?.low})
          </button> */}
        </Box>
        <CommonTable
          columns={columns}
          data={TableData}
          options={{
            sorting: true,
            search: true,
            scrollX: true,
          }}
          title={TitleNAme}
        />
      </Card>
      {/* Depend popup */}
      <HealthDependCard
        open={showPopup}
        close={handelClose}
        issueList={issueList}
        setSelectedIssue={setSelectedIssue}
        selectedIssue={selectedIssue}
        issue={isIssue}
        inWordAndOutWordKeys={inWordAndOutWordKeys}
        issueListInDetail={issueListInDetail}
        keyOfName={keyOfName}
      />
    </div>
  );
};

export default StatusTable;
