const e = process.env;

const config = {
  // common
  type: e.REACT_APP_TYPE || "Story Point",
  name: e.REACT_APP_NAME || "Demo",
  utilizationType: e.REACT_APP_UTILIZATION,
  PerformanceType: e.REACT_APP_PERFORMANCE,

  // PROJECT LEVEL =======================================>
  project: {
    isProjectOnSideBar: toBoolean(e.REACT_APP_IS_PROJECT),
    projectLevelHeath: toBoolean(e.REACT_APP_PROJECT_HEATH),
    projectLevelMetricWorkBreakDown: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_WORK_BREAKDOWN
    ),
    projectLevelCommunicationComplex: toBoolean(
      e.REACT_APP_PROJECT_COMUNICATON_COMPLEXCITY
    ),
    projectLevelDoraMetrics: toBoolean(e.REACT_APP_PROJECT_DORA_METRICS),
    projectLevelEpicDistribution: toBoolean(
      e.REACT_APP_PROJECT_EPIC_DISTRIUTION
    ),
    projectLevelAllProjectLifeTimeProgress: toBoolean(
      e.REACT_APP_ALL_PROJECTS_LIFE_TIME_PROGRESS
    ),
    projectLevelAllProjectOverallRisk: toBoolean(
      e.REACT_APP_ALL_PROJECTS_OVERALL_RISK
    ),
    projectLevelBenchmark: toBoolean(e.REACT_APP_PROJECT_BENCHMARK),
    projectLevelRequirementsAnalysis: toBoolean(
      e.REACT_APP_PROJECT_REQUIREMENT_ANALYSIS
    ),
    projectLevelInvestmentDistribution: toBoolean(
      e.REACT_APP_ALL_PROJECTS_INVESTMENT_DISTRIBUTION
    ),
    projectLevelEffortAllocation: toBoolean(
      e.REACT_APP_PROJECT_EFFORT_ALLOCATION
    ),
    projectLevelMetricCycleTimePr: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_CYCLE_TIME_PR
    ),
    projectLevelMetricCycleTimeBreakDown: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_CYCLE_TIME_BREAKDOWN
    ),
    projectLevelMetricWorkCompleted: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_WORK_COMPLETED
    ),
    projectLevelMetricCommitWithoutTicketRef: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_COMMIT_WITHOUT_TICKET_REF
    ),
    projectLevelMetricUnReviewPr: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_UNREVIEW_PR
    ),
    projectLevelMetricCommitWithPr: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_COMMIT_WITH_PR
    ),
    projectLevelMetricPrReviewTime: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_PR_REVIEW_TIME
    ),
    projectLevelMetricFirstTimePassIssue: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_FIRST_TIME_PASS_ISSUE
    ),
    projectLevelMetricWorkInProgress: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_WORK_IN_PROGRESS
    ),
    projectLevelMetricAvgFlowEfficiency: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_AVG_FLOW_EFFICINCY
    ),
    projectLevelMetricSpeedingTransitionRate: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_SPEEDING_TRANSITION_RATE
    ),
    projectLevelHealthDeliveryTracker: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELIVERY_TRACKER
    ),
    projectLevelHealthProcess: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_PROCESS
    ),
    projectLevelHealthDeliveryQuality: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELIVERY_QUALITY
    ),
    projectLevelHealthCollaboration: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_COLLABORATION
    ),
    projectLevelMetricPlanningAccuracy: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_PLANNING_ACCURACY
    ),
    projectLevelHealthDeliverablesNewTaskAdd: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_NEW_TASK_ADD
    ),
    projectLevelHealthDeliverablesComponentName: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_COMPONENT_NAME
    ),
    projectLevelHealthDeliverablesCommitWithoutPr: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_COMMIT_WITHOUT_PR
    ),
    projectLevelHealthDeliverablesLegacyRefactor: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_LEGACY_REFACTOR
    ),
    projectLevelHealthDeliverablesPriority: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_PRIORITY
    ),
    projectLevelHealthDeliverablesCodeChurn: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_CODE_CHURN
    ),
    projectLevelHealthDeliverablesNewWork: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_NEW_WORK
    ),
    projectLevelHealthDeliverablesCommitCount: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_COMMIT_COUNT
    ),
    projectLevelHealthDeliverablesForcastDate: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_FORCAST_DATE
    ),
    projectLevelHealthDeliverablesTargetDate: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_TARGET_DATE
    ),
    projectLevelHealthDeliverablesPlannedRemainSpentTime: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_PLANN_REMAIN_SPENT_TIME
    ),
    projectLevelCustomDate: e.REACT_APP_ALL_PROJECTS_CUSTOM_DATE_RANGE,
    projectLevelMetricsCommitWithoutPr: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_COMMIT_WITHOUT_PR
    ),
    projectLevelMetricsStoryBurnDown: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_STORY_BURNDOWN
    ),
    projectLevelMetricsStoryTicketWithoutEpicAndStory: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TICKET_WITHOUT_EPIC_AND_STORY
    ),
    projectLevelMetricsDevAttentionTime: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_DEV_ATTENTION_TIME
    ),
    projectLevelMetricsTicketWithoutEstimation: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TICKET_WITHOUT_ESTIMATION
    ),
    projectHeaderCodeHealth: toBoolean(e.REACT_APP_ALL_PROJECT_CODE_HEALTH),
    projectLevelHealthDeliverableInProgressTableKey:
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELIVERRABLE_IN_PROGRESS_COLUMN_KEY,
    projectLevelMetricNumberOfEpicAndStoryAndTotalStoryPoint: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_NUMBER_OF_EPIC_AND_STORY_AND_TOTAL_STORY_POINT
    ),
    AllLevelSummary: toBoolean(e.REACT_APP_SUMMARY),
    projectLevelHealthDeliverablesNotes: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELEVRIABLE_TABLE_NOTES
    ),
    projectLevelMetricKsi: toBoolean(e.REACT_APP_PROJECT_LEVEL_METRICS_KSI),
    projectLevelMetricHyginScore: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_HYGIN_SCORE
    ),
    projectLevelTabPRD: toBoolean(e.REACT_APP_PROJECT_REQUIREMENT_DOCUMENT),
    projectLevelPrSize: toBoolean(e.REACT_APP_PROJECT_LEVEL_METRICS_PR_SIZE),
    projectLevelPrSizeDefectResoultionTime: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_DEFECT_RESOLUTION_TIME
    ),
    projectLevelTestCaseEfficiency: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TEST_CASE_EFFICIENCY
    ),
    projectLevelHealthDeliveryQualityAvgPrSize: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DELIVERY_QUALITY_PR_SIZE
    ),
    projectLevelBugIntroduceAndFixed: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_BUG_INTRODUCE_AND_FIXED
    ),
    projectLevelMetricWorkAllocation: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_WORK_ALLOCATON
    ),
    projectLevelMetricProdBugIntroAndFeature: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_PRODBUG_INTRO_AND_FEATURE
    ),
    projectLevelMetricTotalBugIntroduce: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TOTAL_BUG_INTRODUCE
    ),
    projectLevelMetricTotalProdBugFixed: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TOTAL_PROD_BUG_FIXED
    ),
    projectLevelMetricTotalNumberFeature: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TOTAL_NUMBER_FEATURE
    ),
    projectLevelMetricProdBugIntroCheckIn: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_PROD_BUG_INTRO_CHECKIN
    ),
    projectLevelMetricTotalCheckIn: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRICS_TOTAL_CHECKIN
    ),
    projectLevelMetricDeliveryQuality: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_DELEVARY_QUALITY_DORA_METICS
    ),
    projectLevelMetricDefectInBacklog: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRIC_DEFECT_IN_BACKLOG
    ),
    projectLevelMetricDefectInProgress: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRIC_DEFECT_IN_PROGRESS
    ),
    projectLevelMetricTicketWithoutCommitRef: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_METRIC_TICKET_WITHOUT_COMMIT_REF
    ),
    projectLevelHealthDxi: toBoolean(
      e.REACT_APP_PROJECT_LEVEL_HEALTH_DEX
    ),
  },

  // TEAM LEVEL =============================================>
  team: {
    projectLevelTeamProductivity: toBoolean(
      e.REACT_APP_PROJECT_TEAM_PRODUCTIVITY
    ),
    projectLevelTeamQualityHealth: toBoolean(
      e.REACT_APP_PROJECT_TEAM_QUALITY_HEALTH
    ),
    projectLevelTeamProcessHealth: toBoolean(
      e.REACT_APP_PROJECT_TEAM_PROCESS_HEALTH
    ),
    projectLevelTeamCollaboration: toBoolean(
      e.REACT_APP_PROJECT_TEAM_COLLABORATION
    ),
    projectLevelTeamEffortAllocation: toBoolean(
      e.REACT_APP_PROJECT_TEAM_EFFORT_ALLOCATION
    ),
    projectLevelTeamInvestMentProfile: toBoolean(
      e.REACT_APP_PROJECT_TEAM_INVESTMENT_PROFILE
    ),
    projectLevelAllTeamNumberOfMember: toBoolean(
      e.REACT_APP_PROJECT_ALL_TEAM_NUMBER_OF_MEMBER
    ),
    projectLevelAllTeamUtilizationVsPerformance: toBoolean(
      e.REACT_APP_PROJECT_ALL_TEAM_UTILIZATION_PERFORMANCE
    ),
    projectLevelAllTeamWorkInProgress: toBoolean(
      e.REACT_APP_PROJECT_ALL_TEAM_WORK_IN_PROGRESS
    ),
    projectLevelAllTeamAvgFlowEfficiency: toBoolean(
      e.REACT_APP_PROJECT_ALL_TEAM_AVG_FLOW_EFFICINCY
    ),
    projectLevelTeamNumberOfTeamMember: toBoolean(
      e.REACT_APP_PROJECT_TEAM_NUMBER_OF_TEAM_MEMBER
    ),
    projectLevelTeamTaskCompleted: toBoolean(
      e.REACT_APP_PROJECT_TEAM_TASK_COMPLETED
    ),
    projectLevelTeamUnReviewPr: toBoolean(e.REACT_APP_PROJECT_TEAM_UNREVIEW_PR),
    projectLevelTeamCommitWithPr: toBoolean(
      e.REACT_APP_PROJECT_TEAM_COMMIT_WITH_PR
    ),
    projectLevelTeamPrReviewTime: toBoolean(
      e.REACT_APP_PROJECT_TEAM_PR_REVIEW_TIME
    ),
    projectLevelTeamWorkInProgress: toBoolean(
      e.REACT_APP_PROJECT_TEAM_WORK_IN_PROGRESS
    ),
    projectLevelTeamAvgFlowEfficiency: toBoolean(
      e.REACT_APP_PROJECT_TEAM_AVG_FLOW_EFFICENCY
    ),
    projectLevelTeamCommitWithoutTicketRef: toBoolean(
      e.REACT_APP_PROJECT_TEAM_COMMIT_WITHOUT_TICKET_REF
    ),
    projectLevelTeamSpeedingTransitionRate: toBoolean(
      e.REACT_APP_PROJECT_TEAM_SPEEDING_TRANSITION_RATE
    ),
    projectLevelTeamUtilizationVsPerformance: toBoolean(
      e.REACT_APP_PROJECT_TEAM_UTILIZATION_VS_PERFORMANCE
    ),
    projectLevelTeamWorkAllocation: toBoolean(
      e.REACT_APP_PROJECT_TEAM_TEAM_WORK_ALLOCATION
    ),
    projectLevelTeamWorkBreakDown: toBoolean(
      e.REACT_APP_PROJECT_TEAM_WORKBREAK
    ),
    projectLevelTeamCycleTimePr: toBoolean(
      e.REACT_APP_PROJECT_TEAM_CYCLE_TIME_PR
    ),
    projectLevelTeamUtilizationPlannedTime: toBoolean(
      e.REACT_APP_PROJECT_TEAM_UTILIZATION_PLANED_TIME
    ),
    projectLevelTeamMetricsCommitWithoutPr: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_COMMIT_WITHOUT_PR
    ),
    projectLevelTeamMetricsTicketWithoutEpicAndStory: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TICKET_WITHOUT_EPIC_AND_STORY
    ),
    projectLevelTeamMetricsDevAttentionTime: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_DEV_ATTENTION_TIME
    ),
    projectLevelTeamMetricsTicketWithoutEstimation: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TICKET_WITHOUT_ESTIMATION
    ),
    projectLevelTeamMetricKsi: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_KSI
    ),
    projectLevelTeamMetricHyginScore: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_HYGIN_SCORE
    ),
    projectLevelTeamMetricPrSize: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_PR_SIZE
    ),
    projectLevelTeamMetricDefectResoultionTime: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_DEFECT_RESOLUTION_TIME
    ),
    projectLevelTeamMetricTestCaseEfficiency: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TEST_CASE_EFFICIENCY
    ),
    projectLevelTeamMetricBugIntroduceAndDone: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_BUG_INTRODUCE_DONE
    ),
    projectLevelTeamMetricBugIntroAndFeature: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_PROD_BUG_INTRO_FEATURE
    ),
    projectLevelTeamMetricTotalBugIntroduce: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TOTAL_BUG_INTRODUCE
    ),
    projectLevelTeamMetricTotalProdBugFixed: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TOTAL_PROD_BUG_FIXED
    ),
    projectLevelTeamMetricTotalNumberFeature: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TOTAL_NUMBER_FEATURE
    ),
    projectLevelTeamMetricProdBugIntroCheckIn: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_PROD_BUG_INTRO_CHECKIN
    ),
    projectLevelTeamMetricTotalCheckIn: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TOTAL_CHECKIN
    ),
    projectLevelTeamMetricQualityHealthDoraMetric: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_QUALITY_HELTH_DORA_METRIC
    ),
    projectLevelTeamMetricDefectInBacklog: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_DEFECT_IN_BACKLOG
    ),
    projectLevelTeamMetricDefectInProgress: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_DEFECT_IN_PROGRESS
    ),
    projectLevelTeamMetricTicketWithoutCommitRef: toBoolean(
      e.REACT_APP_PROJECT_TEAM_LEVEL_METRICS_TICKET_WITHOUT_COMMIT_REF
    ),
    projectLevelTeamDxi: toBoolean(
      e.REACT_APP_PROJECT_TEAM_DXI
    ),
  },

  // CONTRIBUTORS LEVEL =============================================>
  contributor: {
    projectLevelTeamContributorHealth: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_HEALTH
    ),
    projectLevelTeamContributorQualityHealth: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_QUALITY_HEALTH
    ),
    projectLevelTeamContributorProcessHealth: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_PROCESS_HEALTH
    ),
    projectLevelTeamContributorUnReviewPr: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_UNREVIEW_PR
    ),
    projectLevelTeamContributorCommitWithPullRequest: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_COMMIT_WITH_PULL_REQUEST
    ),
    projectLevelTeamContributorAvgFlowEfficiency: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_AVG_FLOW_EFICIENCY
    ),
    projectLevelTeamContributorCommitWithoutTicketRef: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_COMMIT_WITHOUT_TICKET_REF
    ),
    projectLevelTeamContributorReviewAndSubmittedPr: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_REVIEW_PR_AND_SUBMITED_PR
    ),
    projectLevelTeamContributorAssignedStoryPoint: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_ASSIGNE_STORY_POINT
    ),
    projectLevelTeamContributorAvgNumberOfStoryPoint: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_AVG_NUMBER_OF_STORY_POINT
    ),
    projectLevelTeamContributorTotalTimeOnInterruption: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_TOTAL_TIME_INTERRUPTION
    ),
    projectLevelTeamContributorTotalTimeOnFragment: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_TOTAL_TIME_FRAGMENT
    ),
    projectLevelTeamContributorTotalTimeOnMeeting: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_TOTAL_TIME_MEETING
    ),
    projectLevelTeamContributorTotalTimeOnDeepWork: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_TOTAL_TIME_DEEP_WORK
    ),
    projectLevelTeamContributorSpeedingTransitionRate: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_SPEEDING_TRANSITION_RATE
    ),
    projectLevelTeamContributorWorkBreakDown: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_WORK_BREAK_DOWN
    ),
    projectLevelTeamContributorEffortAllocation: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_EFFORT_ALLOCATION
    ),
    projectLevelTeamContributorInvestmentProfile: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_INVEATMENT_PROFILE
    ),
    projectLevelTeamContributorMetricsCommitWithoutPr: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_COMMIT_WITHOUT_PR
    ),
    projectLevelTeamContributorMetricsDeveloperCoaching: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_DEVELOPER_COACHING
    ),
    projectLevelTeamContributorMetricsTicketWithoutEpicAndStory: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TICKET_WITHOUT_EPIC_AND_STORY
    ),
    projectLevelTeamContributorMetricsDevAttentionTime: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_DEV_ATTENTION_TIME
    ),
    projectLevelTeamContributorMetricsTicketWithoutEstimation: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TICKET_WITHOUT_ESTIMATION
    ),
    projectLevelTeamContributorMetricsHyginScore: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_HYGIN_SCORE
    ),
    projectLevelTeamContributorMetricsPrSize: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_PR_SIZE
    ),
    projectLevelTeamContributorMetricsDefectResoultionTime: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_DEFECT_RESOLUTION_TIME
    ),
    projectLevelTeamContributorMetricsTestCaseEfficiency: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TEST_CASE_EFFICIENCY
    ),
    projectLevelTeamContributorMetricsBugIntroAndDone: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_BUG_INTRO_DONE
    ),
    projectLevelTeamContributorMetricsProdBugIntroAndFeature: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_PROD_BUG_INTRO_AND_FEATURE
    ),
    projectLevelTeamContributorMetricsTotalBugIntroduce: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TOTAL_BUG_INTRODUCE
    ),
    projectLevelTeamContributorMetricsTotalProdBugFixed: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TOTAL_PROD_BUG_FIXED
    ),
    projectLevelTeamContributorMetricsTotalNumberFeature: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TOTAL_NUMBER_FEATURE
    ),
    projectLevelTeamContributorMetricsProdBugIntroCheckIn: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_PROD_BUG_INTRO_CHECKIN
    ),
    projectLevelTeamContributorMetricsTotalCheckIn: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TOTAL_CHECKIN
    ),
    projectLevelTeamContributorDefectInBacklog: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_DEFECT_IN_BACKLOG
    ),
    projectLevelTeamContributorDefectInProgress: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_DEFECT_IN_PROGRESS
    ),
    projectLevelTeamContributorTicketWithoutRef: toBoolean(
      e.REACT_APP_PROJECT_CONTRIBUTOR_LEVEL_METRICS_TICKET_WITHOUT_COMMIT_REF
    ),
  },

  // SPRINT LEVEL =========================================>
  sprint: {
    sprintLevelIsSprint: toBoolean(e.REACT_APP_IS_SPRINT),
    sprintLevelIsSprintHeaderEpicDistribution: toBoolean(
      e.REACT_APP_IS_SPRINT_HEADER_EPIC_DISTRIBUTION
    ),
    sprintLevelIsWorkCommitIssueLevelStroyPoint:
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_STORY_POINT,
    sprintLevelIsWorkCommitIssueLevelDev:
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_DEV,
    sprintLevelIsWorkCommitIssueLevelQa:
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_QA,
    sprintLevelIsWorkCommitIssueLevelEmptyDevAndQa: toBoolean(
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_EMPTY_DEV_QA
    ),
    sprintLevelWorkCommitIssueLevelStoryColumn: toBoolean(
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_STORY_POINT_COLUMN
    ),
    sprintLevelWorkCommitIssueLevelRemainAndAllowTime: toBoolean(
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_STORY_REMAIN_AND_ALLOCATED_TIME
    ),
    sprintLevelWorkCommitIssueLevelTimeSpent: toBoolean(
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_TIME_SPENT
    ),
    sprintLevelDefectLeadTimeFilter: toBoolean(
      e.REACT_APP_SPRINT_DEFECT_LEAD_TIME_WORKLOG_AND_CHANGELOG_FILTER
    ),
    sprintLevelIsSprintDevInsidesUilizationTrendLastSprint: toBoolean(
      e.REACT_APP_IS_SPRINT_DEVLOPER_INSITE_UTILIZATION_TREND_FOR_LAST_SPRINT
    ),
    sprintLevelIsSprintDevInsidesUilizationPercentLastSprint: toBoolean(
      e.REACT_APP_IS_SPRINT_DEVLOPER_INSITE_UTILIZATION_PERCENT_FOR_LAST_SPRINT
    ),
    sprintLevelIsSprintSprintOverviewUilizationLastSprint: toBoolean(
      e.REACT_APP_IS_SPRINT_SPRINT_OVERVIEW_ISSUE_LEVEL_ULIZATIONTREND
    ),
    sprintLevelIsSprintDeveloperInsidesPlanAndTimeSpent: toBoolean(
      e.REACT_APP_IS_SPRINT_DEVELOPER_INSITE_PLANTIME_AND_TIME_SPEND
    ),
    sprintLevelIsSprintSprintOverviewIssueLevel: toBoolean(
      e.REACT_APP_IS_SPRINT_SPRINT_OVERVIEW_ISSUE_LEVEL_TABLE
    ),
    sprintLevelIsSprintSprintOverviewMetricCommitWithoutPr: toBoolean(
      e.REACT_APP_IS_SPRINT_SPRINT_OVERVIEW_METRIC_COMMIT_WITHOUT_PR
    ),
    sprintLevelIsSprintSprintOverviewCommitWithoutTicketRef: toBoolean(
      e.REACT_APP_IS_SPRINT_SPRINT_OVERVIEW_METRIC_COMMIT_WITHOUT_TICKET_REF
    ),
    sprintLevelIsSprintSprintOverviewNumberOfCommit: toBoolean(
      e.REACT_APP_IS_SPRINT_SPRINT_OVERVIEW_METRIC_NUMBER_OF_COMMIT
    ),
    sprintLevelIsSprintDevInsideUtilizationStatus: toBoolean(
      e.REACT_APP_IS_SPRINT_DEVELOPER_INSITE_UTILIZATION_STATUS
    ),
    sprintLevelIsSprintWorkCommitIssueLevelOther: toBoolean(
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_OTHER
    ),
    sprintLevelIsSprintWorkCommitIssueLevelReporter: toBoolean(
      e.REACT_APP_SPRINT_WORK_COMMITMENT_ISSUE_LEVEL_REPOTERS
    ),
  },

  // ALERT LEVEL
  alertFrame: {
    isAlertFrameWork: toBoolean(e.REACT_APP_IS_ALERT),
  },

  // SETTING LEVEL
  setting: {},

  // RESOURCE ALLOCATION LEVEL
  resourceAllocation: {
    isResourceAllocation: toBoolean(e.REACT_APP_IS_RESOURECE_ALLOCATION),
  },

  //...... add more config here if required
};

export { config };

function toBoolean(string) {
  return string === "false" ? false : true;
}
