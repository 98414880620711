import React, { useContext, useEffect, useState } from "react";
import instance from "../../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonTable from "../../../../../components/commonTable";
import RiskWrapper from "../../../../../components/overview/riskWrapper";
import { Alert, Button, Dialog, Tooltip, backdropClasses } from "@mui/material";
import { datePayload } from "../../../../../utils/moduleTabApi";
import { Box, makeStyles } from "@material-ui/core";
import TrendWithCategoryAxis from "../../../../../components/trendGraph/trendWithCategory";
import { format, parseISO } from "date-fns";
import CommonPopup from "../../../../../components/commonPopup";
import "../../health/Styles.css";
import InfoIcon from "@material-ui/icons/Info";
import HealthColorWrapper from "../../../../../components/overview/healthColorWrapper";
import { GlobalData } from "../../../../../context/globalData";
import { definations } from "../../../../../utils/defination";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

function Process({
  date,
  showTrend,
  setShowTrend,
  getProcessStatus,
  recommendation,
}) {
  const [processData, setProcessData] = useState([]);
  const [trenData, setTrendData] = useState([]);
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);
  const [showInfrenc, setShowInfrenc] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [aIGenaratedInference, setAIGenaratedInference] = useState([]);

  const transformText = (text) => {
    return JSON.stringify(text || "")
      .replace(/[{}[\]]/g, "<br />")
      .replace(/,/g, " ");
  };

  const InferenceText = transformText(aIGenaratedInference[0]?.Inference);
  const SuggestionsText = transformText(aIGenaratedInference[0]?.Suggestions);
  const ActionsText = transformText(aIGenaratedInference[0]?.Actions);

  console.log(aIGenaratedInference, "===", InferenceText, SuggestionsText);

  const [columns] = useState([
    { title: "Name", field: "Name" },
    {
      title: "Health Score (%)",
      field: "HealthScore",
      render: (row) => row?.HealthScore?.toFixed(),
    },
    {
      title: "Health Status",
      field: "HealthStatus",
      render: (row) => <HealthColorWrapper status={row.HealthStatus} />,
    },
    {
      title: (
        <>
          Avg Pull Request Idle Time
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Avg Pull Request Idle Time");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "avgPrAge",
      render: (row) => (
        <Tooltip
          title={definations?.["Avg Pull Request Idle Time"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >{`${Math.ceil(row.avgPrAge)} ${
          row.avgPrAge < 2 ? "day" : "days"
        }`}</Tooltip>
      ),
    },
    {
      title: (
        <>
          Completion Rate (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Completion Rate (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "CompletionRate",
      render: (row) => (
        <Tooltip
          title={definations?.["Completion Rate (%)"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {(row.CompletionRate * 100)?.toFixed()}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Commit Without Ticket Reference (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Commit Without Ticket Reference (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentCommitWithoutTicketRef",
      render: (row) => (
        <Tooltip
          title={definations?.[
            "Commit Without Ticket Reference (%)"
          ]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {(row.PercentCommitWithoutTicketRef * 100)?.toFixed()}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Commit With Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Commit With Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentCommitWithoutPr",
      render: (row) => (
        <Tooltip
          title={definations?.["Commit With Pull Request (%)"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {(row.PercentCommitWithoutPr * 100)?.toFixed()}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Merged Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentMergedPr",
      render: (row) => (
        <Tooltip
          title={definations?.["Merged Pull Request (%)"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {(row.PercentMergedPr * 100)?.toFixed()}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Merged Reviewed Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged Reviewed Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "PercentMergedReviewdPr",
      render: (row) => (
        <Tooltip
          title={definations?.[
            "Merged Reviewed Pull Request (%)"
          ]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {(row.PercentMergedReviewdPr * 100)?.toFixed()}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Speedy Transitions
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Speedy Transition");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "totalSpeedyTransitions",
      render: (row) => (
        <Tooltip
          title={definations?.["Speedy Transitions"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {row?.totalSpeedyTransitions}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Hygine Score
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Hygine Score");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "HygineScore",
      render: (row) => (
        <Tooltip
          title={definations?.["HygineScore"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {row?.HygineScore}
        </Tooltip>
      ),
    },
    // ==========================================================================

    // { title: "Commits", field: "Commits" },
    // {
    //   title: "Commit Without Ticket Reference (%)",
    //   field: "CommitWithoutTicketRef",
    // },
    // { title: "Merged Pull Request", field: "MergedPr" },
    // { title: "Merged Reviewed Pull Request ", field: "MergedReviewdPr" },
    // { title: "Pull Request Count (%)", field: "PrCount" },
    // { title: "Commit Without Pull Request", field: "CommitWithoutPr" },
    // {
    //   title: "Task Assigned",
    //   field: "TaskAsigned",
    //   render: (row) => row.TaskAsigned.toFixed(),
    // },
    // { title: "Scope Change (%)", field: "ScopeChange" },
    // {
    //   title: "Task Completed",
    //   field: "TaskCompleted",
    //   render: (row) => row.TaskCompleted.toFixed(),
    // },
  ]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/processhealth/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data;
        getProcessStatus("Process", date);
        setProcessData(data);
      })
      .catch((err) => {
        console.error(err);
      });

    instance
      .get(`/api/v2/ph/trend/process/project/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.Date);
          return {
            ...e,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  const [visibleColumns, setVisibleColumns] = useState(columns.slice(0, 5));
  const [showViewMore, setShowViewMore] = useState(true);

  const handleViewMore = () => {
    const currentLength = visibleColumns.length;
    const nextVisibleColumns = columns.slice(currentLength, currentLength + 5);
    setVisibleColumns([...visibleColumns, ...nextVisibleColumns]);

    if (currentLength + 5 >= columns.length) {
      setShowViewMore(false);
    }
  };

  useEffect(() => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/processhealth/project/inference/${projectId}?${dateUrl}`)
      .then((res) => {
        const dummyData = [
          {
            Inference: {
              "Singapore Team": {
                healthStatus: "AVERAGE",

                ChurnState: "BAD",

                PrSizeState: "BAD",

                LegacyState: "BAD",

                FirstTimeState: "GOOD",

                ReturnRateState: "GOOD",

                MergedPRState: "BAD",

                MergedReviewedState: "GOOD",
              },

              "Data Engineering": {
                healthStatus: "AVERAGE",

                ChurnState: "BAD",

                PrSizeState: "BAD",

                LegacyState: "BAD",

                FirstTimeState: "GOOD",

                ReturnRateState: "BAD",

                MergedPRState: "GOOD",

                MergedReviewedState: "GOOD",
              },

              "Mobile Team": {
                healthStatus: "AVERAGE",

                ChurnState: "BAD",

                PrSizeState: "BAD",

                LegacyState: "BAD",

                FirstTimeState: "BAD",

                ReturnRateState: "GOOD",

                MergedPRState: "GOOD",

                MergedReviewedState: "GOOD",
              },

              "India Team": {
                healthStatus: "AVERAGE",

                ChurnState: "BAD",

                PrSizeState: "BAD",

                LegacyState: "BAD",

                FirstTimeState: "BAD",

                ReturnRateState: "GOOD",

                MergedPRState: "BAD",

                MergedReviewedState: "BAD",
              },

              "QA Team": {
                healthStatus: "AVERAGE",

                ChurnState: "BAD",

                PrSizeState: "BAD",

                LegacyState: "BAD",

                FirstTimeState: "BAD",

                ReturnRateState: "GOOD",

                MergedPRState: "GOOD",

                MergedReviewedState: "GOOD",
              },
            },

            Suggestions: {
              "High Code Churn":
                "Implement structured refactoring and pre-commit reviews.",

              "High failure rate":
                "Enforce test-driven development (TDD) and CI/CD improvements.",

              "Low PR merge rate":
                "Introduce mentorship and structured review guidelines.",

              "Low deployment frequency":
                "Provide focused coaching and coding bootcamps.",

              "High rollback time":
                "Conduct post-mortem reviews and improve automated rollback strategies.",
            },

            Actions: {
              "High Code Churn":
                "Increase code quality and stability through structured refactoring, linting and code reviews.",

              "High failure rate":
                "Incorporate thorough testing and continuous integration to identify and fix issues earlier in the development cycle.",

              "Low PR merge rate":
                "Implement a mentorship program and establish clear PR review guidelines to increase the merge rate.",

              "Low deployment frequency":
                "Monitor and track progress through bootcamps and focused coaching.",

              "High rollback time":
                "Minimize rollback time through automated strategies and robust deployment systems.",
            },
          },
        ];
        setAIGenaratedInference(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date, projectId]);

  return (
    <div>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trenData} />
        </Box>
      </CommonPopup>
      {/* <div className="infrence-suggestion-btn">
        <Button variant="contained" color="warning" startIcon={<WarningIcon />} onClick={() => setShowInfrenc(true)} disabled={showInfrenc} >
          Inference
        </Button>
        <Button variant="contained" color="info" startIcon={<ErrorIcon />} sx={{ margin: '0px 10px' }} onClick={() => setShowSuggestion(true)} disabled={showSuggestion} >
          Suggestion
        </Button>
      </div> */}
      <div className="infrence-suggestion-btn">
        <Button
          variant="contained"
          color="warning"
          startIcon={<WarningIcon />}
          onClick={() => setShowInfrenc(true)}
          disabled={showInfrenc}
        >
          Inference
        </Button>
        {/* {recommendation.status !== "GOOD" && ( */}
          <Button
            variant="contained"
            color="info"
            startIcon={<ErrorIcon />}
            sx={{ margin: "0px 10px" }}
            onClick={() => setShowSuggestion(true)}
            disabled={showSuggestion}
          >
            Suggestion
          </Button>
        {/* )} */}
      </div>
      <>
        {showInfrenc && (
          <Alert
            sx={{ mb: 3 }}
            severity="warning"
            onClose={() => setShowInfrenc(false)}
          >
            <b>Inference : </b>
            <div dangerouslySetInnerHTML={{ __html: InferenceText }}></div>
          </Alert>
        )}
        {showSuggestion && (
          <Alert
            sx={{ mb: 3 }}
            severity="info"
            onClose={() => setShowSuggestion(false)}
          >
            <b>Suggestion : </b>
            <div
              dangerouslySetInnerHTML={{ __html: SuggestionsText }}
              className="mb-3"
            ></div>
            <b>Actions : </b>
            <div dangerouslySetInnerHTML={{ __html: ActionsText }}></div>
          </Alert>
        )}
      </>
      <div className="healthTable">
        <CommonTable columns={visibleColumns} data={processData} />
      </div>
      {showViewMore && (
        <div
          style={{
            color: "rgb(65, 132, 142)",
            textAlign: "end",
            cursor: "pointer",
          }}
          onClick={handleViewMore}
          className="pt-2 mb-0"
        >
          ...View More Column
        </div>
      )}
    </div>
  );
}

export default Process;
