import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const MultiLineChart = ({ chartData, height = "500px", status }) => {
  useEffect(() => {
    if (!chartData || !am4core || !am4charts) {
      console.log("Returning early");
      return;
    }
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    chart.data = chartData;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // // Add custom Y-axis labels
    // let lowLabel = valueAxis.axisRanges.create();
    // lowLabel.value = 10;  // Adjust the value as per your low condition
    // lowLabel.label.text = `Low {value}`;
    // lowLabel.label.fill = am4core.color("#00ff00");
    // // lowLabel.label.adapter.add("dx", (dx, target) => {
    // //     return -30; // Adjust the left spacing as needed (negative value moves left)
    // // });

    // let avgLabel = valueAxis.axisRanges.create();
    // avgLabel.value = 50;  // Adjust the value as per your average condition
    // avgLabel.label.text = `Avg {value}`;

    // let highLabel = valueAxis.axisRanges.create();
    // highLabel.value = 100;  // Adjust the value as per your high condition
    // highLabel.label.text = `High {value}`;

    status.forEach((e) => {
      let label = valueAxis.axisRanges.create();
      label.value = e.value; // Adjust the value as per your low condition
      label.label.html = `<div style="background:#afafaf6e;" title='${
        e.label
      } {value}'> ${e.label} ${e.arrow === "Up" ? "⬆" : "⬇"} {value}</div>`;
      label.label.fill = am4core.color(e.color);
    });

    // const arrowAnnotations = [
    //     { start: 1, end: 50, text: '---------------------------->', color: am4core.color('#00ff00') },
    //     { start: 50, end: 100, text: '---------------------------', color: am4core.color('#ff0000') },
    //     { start: 100, end: 120, text: '---------------------------', color: am4core.color('orange') },
    // ];

    // arrowAnnotations.forEach((annotation) => {
    //     const range = valueAxis.axisRanges.create();
    //     range.value = annotation.start;
    //     range.endValue = annotation.end;

    //     // Create arrow label
    //     const label = valueAxis.renderer.labels.template.clone();
    //     label.text = `{bold ${annotation.text}}`;
    //     label.text = annotation.text;
    //     label.fill = annotation.color;
    //     label.fontSize = 12;
    //     label.rotation = 270;
    //     label.horizontalCenter = 'middle';
    //     label.verticalCenter = 'center';
    //     label.dx = -10; // Adjust the distance from the axis
    //     label.dy = 0;

    //     range.label = label;
    // });

    chartData.forEach((series) => {
      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "value";
      lineSeries.dataFields.dateX = "time";
      lineSeries.name = series.displayName;
      lineSeries.data = series.data;
      lineSeries.tooltipText = `[bold]{name}[/]\nDate: {dateX}\nValue: {valueY}`;
      lineSeries.strokeWidth = 2.5;
      lineSeries.tensionX = 0.8;
      lineSeries.tensionY = 0.8;

      let bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = 4;
      bullet.tooltipText = `{name} - Date: {dateX}\nValue: {valueY}`;
    });

    chart.legend = new am4charts.Legend();
    chart.responsive.enabled = true;
    chart.legend.maxHeight = 100; // Set the maximum height for the legend
    chart.legend.scrollable = true; // Enable scrollable legend

    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return <div id="chartdiv" style={{ width: "100%", height: height }} />;
};

export default MultiLineChart;
