import React, { useContext, useEffect, useState } from "react";
import instance from "../../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonTable from "../../../../../components/commonTable";
import RiskWrapper from "../../../../../components/overview/riskWrapper";
import { Alert, Box, Button, Tooltip } from "@mui/material";
import {
  addLeadingZeroToDateRange,
  datePayload,
} from "../../../../../utils/moduleTabApi";
import CommonPopup from "../../../../../components/commonPopup";
import TrendWithCategoryAxis from "../../../../../components/trendGraph/trendWithCategory";
import { format, parseISO } from "date-fns";
import { ceil } from "../../../../../utils/changeDecimal";
import "../../health/Styles.css";
import HealthColorWrapper from "../../../../../components/overview/healthColorWrapper";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../../../context/globalData";
import { definations } from "../../../../../utils/defination";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

function DxiUi({
  date,
  showTrend,
  setShowTrend,
  getProcessStatus,
  recommendation,
}) {
  const [DixTable, setDixTable] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [showInfrenc, setShowInfrenc] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [loaderInfSuggest, setLoaderInfSuggest] = useState(false);
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);
  const [aIGenaratedInference, setAiGenaratedInference] = useState([]);

  const [columns] = useState([
    { title: "Name", field: "name" },
    { title: "Developer Experience Index", field: "DXI" },
    {
      title: "Status",
      field: "DXIStatus",
      render: (res) => <HealthColorWrapper status={res?.DXIStatus} />,
    },
    {
      title: "Defect Resolution Time (Hrs)",
      field: "defectResolutionTime",
      render: (res) => (
        <>
          {res.defectResolutionTime
            ? res.defectResolutionTime?.toFixed()
            : " 0"}
        </>
      ),
    },
    { title: "Time Spent On Bug Fixing (Hrs)", field: "timeSpentOnBugFixing" },
    {
      title: "Mean Time To Restore Failed Build (Hrs)",
      field: "meanTimeToRestoreFailedBuild",
    },
    {
      title: "Pr Merge Frequency (Count)",
      field: "prMergeFrequency",
      render: (res) => (
        <>{res.prMergeFrequency ? res.prMergeFrequency?.toFixed() : " 0"}</>
      ),
    },
    {
      title: "Pickup Time (Hrs)",
      field: "pickupTime",
      render: (res) => <>{res.pickupTime ? res.pickupTime?.toFixed() : " 0"}</>,
    },
    {
      title: "Review Time (Hrs)",
      field: "reviewTime",
      render: (res) => <>{res.reviewTime ? res.reviewTime?.toFixed() : " 0"}</>,
    },
    { title: "Deploy Time (Hrs)", field: "deployTime" },
    { title: "Utilization Factor (%)", field: "utilizationFactor" },
  ]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    getProcessStatus("Developer Experience Index", date);
    instance
      .get(
        `/api/v2/projects/calculateDxiIssuelevel?${zeroDate}&projectId=${projectId}`
      )
      .then((res) => {
        const data = res?.data?.filter((e) => e.name !== null);
        setDixTable(data);
      })
      .catch((err) => {
        console.error(err);
      });

    instance
      .get(
        `/api/v2/projects/calculateDxiTrend?${zeroDate}&projectId=${projectId}`
      )
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.Date);
          return {
            ...e,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    const zeroDate = addLeadingZeroToDateRange(dateUrl);
    setLoaderInfSuggest(true);
    instance
      .get(
        `/api/v2/projects/calculateDxiInference?${zeroDate}&projectId=${projectId}`
      )
      .then((res) => {
        setAiGenaratedInference(res?.data);
        setLoaderInfSuggest(false);
      })
      .catch((err) => {
        console.error(err);
        setLoaderInfSuggest(true);
      });
  }, [date]);

  const transformText = (text) => {
    return JSON.stringify(text || "")
      .replace(/[{}[\]]/g, "<br />")
      .replace(/,/g, " ");
  };

  const InferenceText = transformText(aIGenaratedInference[0]?.Inference);
  const SuggestionsText = transformText(
    aIGenaratedInference[0]?.Suggestions || aIGenaratedInference[1]?.Suggestions
  );
  const ActionsText = transformText(
    aIGenaratedInference[0]?.Actions || aIGenaratedInference[2]?.Actions
  );

  const [visibleColumns, setVisibleColumns] = useState(columns.slice(0, 5));
  const [showViewMore, setShowViewMore] = useState(true);

  const handleViewMore = () => {
    const currentLength = visibleColumns.length;
    const nextVisibleColumns = columns.slice(currentLength, currentLength + 5);
    setVisibleColumns([...visibleColumns, ...nextVisibleColumns]);

    if (currentLength + 5 >= columns.length) {
      setShowViewMore(false);
    }
  };

  return (
    <div>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trendData} />
        </Box>
      </CommonPopup>
      <div className="infrence-suggestion-btn">
        <Button
          variant="contained"
          color="warning"
          startIcon={<WarningIcon />}
          onClick={() => setShowInfrenc(true)}
          disabled={showInfrenc || loaderInfSuggest}
        >
          Inference
        </Button>
        <Button
          variant="contained"
          color="info"
          startIcon={<ErrorIcon />}
          sx={{ margin: "0px 10px" }}
          onClick={() => setShowSuggestion(true)}
          disabled={showSuggestion || loaderInfSuggest}
        >
          Suggestion
        </Button>
      </div>
      {recommendation?.status === "GOOD" ? null : (
        <>
          {showInfrenc && (
            <Alert
              sx={{ mb: 3 }}
              severity="warning"
              onClose={() => setShowInfrenc(false)}
            >
              <b>Inference : </b>
              <div dangerouslySetInnerHTML={{ __html: InferenceText }}></div>
            </Alert>
          )}
          {showSuggestion && (
            <Alert
              sx={{ mb: 3 }}
              severity="info"
              onClose={() => setShowSuggestion(false)}
            >
              <b>Suggestion : </b>
              <div
                dangerouslySetInnerHTML={{ __html: SuggestionsText }}
                className="mb-3"
              ></div>
              <b>Actions : </b>
              <div dangerouslySetInnerHTML={{ __html: ActionsText }}></div>
            </Alert>
          )}
        </>
      )}
      <div className="healthTable">
        <CommonTable columns={visibleColumns} data={DixTable} />
      </div>
      {showViewMore && (
        <div
          style={{
            color: "rgb(65, 132, 142)",
            textAlign: "end",
            cursor: "pointer",
          }}
          onClick={handleViewMore}
          className="pt-2"
        >
          ...View More Column
        </div>
      )}
    </div>
  );
}

export default DxiUi;
