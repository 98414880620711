import React, { useContext, useMemo, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import WIPimg from "../../../assets/WIP.jpeg";
import flowEfficiencyImg from "../../../assets/FlowEfficiency.jpeg";
import wipImg from "../../../assets/WIP.jpeg";
import UtilizationvsPerformance from "../../../assets/UtilizationvsPerformance.jpeg";
import msg from "../../../assets/msg.png";
import check from "../../../assets/check.png";
import pr from "../../../assets/pr.png";
import commit from "../../../assets/commit.png";
import GraphCard from "./graphCard";
import { useEffect } from "react";
import CycleTimeBreakDown from "./graphs/cycleTimeBreakDown";
import WorkAlocationChart from "./graphs/workAlocationChart";
import axios from "../../../utils/axiosHelper";
import PlanningAccuracy from "./graphs/PlanningAccuracy";
import { style } from "./style";
import IssueLevel from "./IssueLevel";
import { addSpace } from "../../../utils/spaceSeprator";
import {
  PlanningAccuracyStatusList,
  peReivewSubStatus,
  prReivewStatusList,
} from "../../../Config/statusData";
import Trend from "./trend";
import { Box, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../context/globalData";
import {
  addLeadingZeroToDateRange,
  datePayload,
  getSelectedDate,
} from "../../../utils/moduleTabApi";
import { getMetricRange } from "../../../utils/getMetricRange";
import { config } from "../../../Config/permission";
import IssueLevelTable from "./IssueLevel/IssueLevelTable";
import IssueLevelCycle from "./IssueLevel/IssueLevelCycle";
import HealthColorWrapper from "../../../components/overview/healthColorWrapper";
import WIPTrend from "./trend/WIPTrend";
import AllTeamTrendBar from "./trend/AllTeamTrend";
import EpicDropDown from "../../../components/epicDropDown";
import SelectField from "../../../components/select/selectField";
import RangeSelector from "../../../components/rangeSelector";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import IssueLevelSpeeding from "./IssueLevel/IssueLevelSpeeding";
import YearlyLineChart from "../../../components/utilizationVsPerformance/lineChart/yearlyLineChart";
import CancelIcon from "@mui/icons-material/Cancel";
import { format } from "date-fns";
import RelevanceLabSprintTrend from "./trend/RelevenceTrend";
import jiraIcon from "../../../assets/jiraIcon.jpeg";
import { Drawer, Tooltip } from "@mui/material";
import MultipleTooltipIndex from "./trend/TooltipTrend/MultipleTooltipIndex";

const useStyles = style;

function ProjectDashboard({ projectName, projectId }) {
  const { setIsDefinaionPopup, setDefinationKPI, projectRangePickerValue } =
    useContext(GlobalData);

  const [barData, setBarData] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [cycleDataPullRequest, setCycleDataPullRequest] = useState([]);
  const [totalCycleData, setTotalCycleData] = useState();
  const [workAllocationData, setWorkAllocationDat] = useState([]);
  const [planningAccuracyData, setPlanningAccuracyData] = useState({});
  const classes = useStyles();
  const [issueType, setIssueType] = useState();
  const [statusList, setStatusList] = useState([]);
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [workCompleted, setWorkCompleted] = useState();
  const [teamAvgAging, setTeamAvgAging] = useState();
  const [teamFlowEfficiency, setFlowTeamEfficiency] = useState();
  const [iteration, setIteration] = useState(30);
  const [commitwithoutData, setCommitWithoutData] = useState();
  const [date, setDate] = useState(projectRangePickerValue);
  const [openRange, setOpenRange] = useState(false);
  const [openUtilizePerform, setOpenUtilizePerform] = useState(false);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [capacityAccuracy, setCapacityAccuracy] = useState();
  const [cradResponsePersent, setCradResponsePersent] = useState();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [SpeedingTransitionsRateMetrics, setSpeedingTransitionsRateMetrics] =
    useState({});
  const [
    resourceAllocationStatusListData,
    setResourceAllocationStatusListData,
  ] = useState([]);
  const [firstTimePassPercentageForRang, setFirstTimePassPercentageForRang] =
    useState();
  const [risk, setRisk] = useState({});
  const [range, setRange] = useState([]);
  const [developerAttentionTimeValues, setDeveloperAttentionTimeValues] =
    useState();
  const [ticketWithoutEstimation, setTicketWithoutEstimation] = useState();
  const [numberOfEpics, setNumberOfEpics] = useState({});
  const [numberOfStoryPointsCompleted, setNumberOfStoryPointsCompleted] =
    useState({});
  const [totalStoryPoint, setTotalStoryPoint] = useState({});
  const [prMergeCount, setPrMergeCount] = useState({
    pr: "",
    commit: "",
    prDepth: "",
    PrReviewTime: "",
    firstTimePass: "",
  });
  const [stroyBurnDown, setStroyBurnDown] = useState([]);
  const [TicketWithoutEpicAndStory, setTicketWithoutEpicAndStory] = useState(
    {}
  );
  const [isSummary, setIsSummary] = useState(false);
  const [isKsiIndex, setIsKsiIndex] = useState();
  const [hyginScore, setHyginScore] = useState();
  const [prSize, setPrSize] = useState();
  const [defectResolutionTime, setdefectResolutionTime] = useState();
  const [testEfficiency, setTestEfficiency] = useState();
  const [bugIntroAndDone, setBugIntroAndDone] = useState();
  const [prodBugIntroAndFeature, setProdBugIntroAndFeature] = useState();
  const [noOfBugIntroduce, setNoOfBugIntroduce] = useState();
  const [noOfProdBugFixed, setNoOfProdBugFixed] = useState();
  const [totalNumberOfFeature, setTotalNumberOfFeature] = useState();
  const [prodBugVsCheckIn, setProdBugVsCheckIn] = useState();
  const [totalNumberOfBugCheckIn, setTotalNumberOfBugCheckIn] = useState();
  const [DefectInBacklog, setDefectInBacklog] = useState();
  const [defectInProgress, setDefectInProgress] = useState();
  const [ticketWithoutCommitReference, setTicketWithoutCommitReference] =
    useState();

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url?.trim(), selectedEpic: list });
  };

  // get work break down list
  const excudePermission = [
    "Work Breakdown",
    "Commit Without Ticket Reference",
    "Commit with pull request",
    // "WIP Average Age",
    "Pr Review Time",
    "Average Flow Efficiency",
    "Cycle Time (Pull Request)",
    "Speeding Transitions Rate",
    "Commit without pull request",
    "Dev Attention Time",
    "Ticket Without Estimation",
    "Ticket Without Epics/Stories",
    "Number of Epics",
    "Number of Story Completed",
    "Total Story Point",
    "Hygin Score",
    "Defect resolution time",
    "Bug Introduced / Bug Fixed",
    "Production Bugs Introduce / Features",
    "No of Prod Bugs introduced in a week",
    "No of Prod Bugs Fixed",
    "Total Number of Features",
    "Production Bugs Introduce / Check in",
    "Total Number of Checkins in a week",
    "Defect In Backlog",
    "Defect In Progress",
    "Ticket Without Commit Reference",
  ];
  const getWorkBreakDown = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/workBreakdown/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/workBreakdown/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        const data = Object.keys(res.data.graph).map((e) => {
          return {
            title: e === "NewWork" ? "Feature and Enhancement" : addSpace(e),
            value: res.data.graph[e],
          };
        });
        setBarData({ CodeChanges: res.data.CodeChanges, data: data });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  //  statusNames
  useEffect(() => {
    const url = `/api/v2/statusNames/${projectId}`;
    axios
      .get(url)
      .then((response) => {
        setResourceAllocationStatusListData(
          response?.data?.StatusNames[0]?.Names
        );
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId]);

  const getCycletimeBreakdown = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/cycletimeBreakdown/${projectId}?${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then(
        (res) => {
          // if (res.data) {
          const response = res?.data?.avgDurations;
          const total = Object.values(response).reduce(
            (acc, value) => acc + value,
            0
          );
          setTotalCycleData({
            total: total.toFixed(),
            percentValue: res?.data?.percentUnderAvgDuration.toFixed(),
          });
          // Calculate percentages and format the data
          const formattedData = Object.keys(response).map((key) => ({
            title: key,
            value: response[key].toFixed(), // Format value to two decimal places
            percentage: ((response[key] / total) * 100).toFixed(), // Calculate and format percentage
          }));
          setCycleData(formattedData);
        }
        // }
      )
      .catch((err) => {});
  };
  const getCycleTimePullRequest = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/teams/cycleTimePR/${projectId}?${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then(
        (res) => {
          // if (res.data) {
          // const
          const response = res?.data;
          // const total = Object.values(response).reduce(
          //   (acc, value) => acc + value,
          //   0
          // );
          // setTotalCycleData({
          //   total: total.toFixed(),
          //   percentValue: res?.data?.percentUnderAvgDuration.toFixed(),
          // });
          // Calculate percentages and format the data
          const formattedData = Object.keys(response)
            .map((key) => ({
              title: key,
              value: response[key], // Format value to two decimal places
              // percentage: ((response[key] / total) * 100).toFixed(), // Calculate and format percentage
            }))
            .filter(
              (e) =>
                e?.title !== "Pickup" &&
                e?.title !== "Coding" &&
                e?.title !== "Review" &&
                e?.title !== "Deploy" &&
                e?.title !== "Idle"
            );
          setCycleDataPullRequest(formattedData);
        }
        // }
      )
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getWorkAllocation = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/resourceAllocation/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/resourceAllocation/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return { title: e, value: res.data[e].toFixed() };
            // return { title: e, value: res.data[e] };
          });
          setWorkAllocationDat(data);
          setAssigneeList(data.map((e) => e.title).filter((e) => e !== "null"));
        }
      })
      .catch((err) => {});
  };
  const getCommitWithout = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/commitwithout/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/commitwithout/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        setCommitWithoutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlanningAccuracy = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/planningAccuracy/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/planningAccuracy/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          setWorkCompleted(res.data?.resData?.Done);
          setCapacityAccuracy(res.data?.result?.Done);
          const data = Object.keys(res.data?.result)
            .map((e) => {
              return { title: e, value: res?.data?.result[e] };
            })
            .filter((item) => item?.title !== "Planned");
          setPlanningAccuracyData({
            graph: data,
            Planned: res?.data?.result?.Planned,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPrMergeData = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/prMerged/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/prMerged/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res?.data) {
          let data = (res?.data?.FirstTime / res?.data?.CompletedIssue) * 100;
          let firstTimePassPercentage = data?.toFixed();
          // let firstTimePassPercentage = data?.toFixed(2);
          setFirstTimePassPercentageForRang(firstTimePassPercentage);
          setCradResponsePersent(res?.data);
          setPrMergeCount({
            pr: `${res?.data?.PRWithoutReview}/${res?.data?.commitPr} (${+res
              ?.data?.PRPercent} %)`,
            commit: `${res?.data?.CommitWithPR}/${res?.data?.CommitCount} (${res?.data?.CommitWithPRper} %)`,
            prDepth: `${res?.data?.commitPr} /${res?.data?.SumComment}  `,
            PrReviewTime: res?.data?.PrReviewTime,
            firstTimePass: `${res?.data?.FirstTime}/${res?.data?.CompletedIssue} (${firstTimePassPercentage} %)`,
            commitWithoutPrMetric: `${res?.data?.CommitWithoutPR}/${res?.data?.CommitCount} (${res?.data?.CommitWithoutPRper} %)`,
          });
          setRisk({
            pr: res?.data?.PRPercent,
            commit: res?.data?.CommitWithPRper,
            commitWithoutPrMetricsValues: res?.data?.CommitWithoutPRper,
            firstTimePass: firstTimePassPercentage,
            RiskPRCommentDepthRisk: res?.data?.RiskPRCommentDepth,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewMoreHandler = (name, list, subStatus = []) => {
    setIssueType(name);
    setStatusList(list);
    setSelectedSubStatus(subStatus);
  };
  useEffect(() => {
    if (projectId) {
      getWorkAllocation();
      getPlanningAccuracy();
      getCycletimeBreakdown();
      getCommitWithout();
      getPrMergeData();
      getWorkBreakDown();
    }
  }, [projectId, epicData.url, date, iteration]);

  useEffect(() => {
    getCycleTimePullRequest();
  }, [projectId, epicData, date]);

  // get ranges
  useEffect(() => {
    axios
      .get(`/api/risks?ProjectId=${projectId}`)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  // Avg Aging api
  const avgAging = () => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/avgAging/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/avgAging/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        setTeamAvgAging(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  // flow Efficiency api
  const flowEfficiency = () => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/flowEfficiency/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/flowEfficiency/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        setFlowTeamEfficiency(res?.data?.Efficiency);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    avgAging();
    flowEfficiency();
  }, [date, epicData]);

  // Speeding Transitions Rate Metrics
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/speedyTransition?projectId=${projectId}&${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setSpeedingTransitionsRateMetrics({
          percent: response?.data?.percent?.toFixed(),
          values: `${response?.data?.totalShortChangeLogs}/${
            response?.data?.totalChangeLogs
          } (${response?.data?.percent?.toFixed()} %)`,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [date, projectId, epicData]);

  // get Ticket without epic and story
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/ticketWithoutEpic?projectId=${projectId}&${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTicketWithoutEpicAndStory(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [date, projectId, epicData]);

  // Story Burndown
  useEffect(() => {
    const dateUrl = datePayload(date);
    const url = `/api/v2/sprints/spMetrics/${projectId}?${dateUrl}`;
    axios
      .get(url)
      .then((response) => {
        setStroyBurnDown(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date]);

  // Dev Attention Time
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/devattentionDetails/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setDeveloperAttentionTimeValues(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Ticket without Estimations
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/ticketWithoutEstimationDetails?${dateUrl}&projectId=${projectId}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTicketWithoutEstimation(response?.data?.count);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Number of Epics
  useEffect(() => {
    const dateUrl = datePayload(date);
    const url = `/api/v2/projects/numberOfEpics/?projectId=${projectId}&${dateUrl}`;
    axios
      .get(url)
      .then((response) => {
        setNumberOfEpics(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date]);

  // Number of Story Completed / Number of Story
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/numberOfStories/?projectId=${projectId}&${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setNumberOfStoryPointsCompleted(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Total Story Point
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/numberOfStoryPoints/?projectId=${projectId}&${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTotalStoryPoint(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Knowledge Sharing index
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/ksiDetails/?projectId=${projectId}&${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setIsKsiIndex(response?.data?.KSIvalue);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Hygin score
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/hygineScore/details/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setHyginScore(response?.data?.hygincScore);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Pr Size
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/prSize?${dateUrl}&projectId=${projectId}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setPrSize(response?.data?.prSizeCount);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // defect resolution time
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/defectResolutionTime?${dateUrl}&projectId=${projectId}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setdefectResolutionTime(response?.data[0]?.DefectResolutionTime);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Test Case Efficiency
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/projects/testEfficiency?${dateUrl}&projectId=${projectId}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTestEfficiency(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Bug Introduced / Bug Fixed
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/productionBugIntroduce/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setBugIntroAndDone(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Production Bugs Introduce / Features
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/bugFeatureDefect/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setProdBugIntroAndFeature(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // No of Prod Bugs introduced in a week
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/bugIntroduced/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setNoOfBugIntroduce(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // No of Prod Bugs Fixed
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/bugFixed/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setNoOfProdBugFixed(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Total Number of Features
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/totalTicketCheckedin/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTotalNumberOfFeature(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Production Bugs Introduce / Check in Ratio
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/productionBugCheckin/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setProdBugVsCheckIn(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Total Number of Checkins in a week
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/numberOfCheckin/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTotalNumberOfBugCheckIn(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Defect In Backlog
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/bugInBacklog/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setDefectInBacklog(response?.data?.totalBugsInBacklog);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Defect In Progress
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/defectInProgress/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setDefectInProgress(response?.data?.totalBugsInProgress);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  // Defect In Progress
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/bugsWithoutTicket/${projectId}?${dateUrl}`;
    if (epicData) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((response) => {
        setTicketWithoutCommitReference(response?.data?.totalBugsInBacklog);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId, date, epicData]);

  const TicketWithoutCommitReferenceRisk = useMemo(
    () =>
      getMetricRange(
        range,
        ticketWithoutCommitReference,
        "Ticket Without Commit Reference"
      ),
    [ticketWithoutCommitReference, range]
  );
  const speedyRateRisk = useMemo(
    () =>
      getMetricRange(
        range,
        SpeedingTransitionsRateMetrics?.percent,
        "Speedy Transition Rate"
      ),
    [SpeedingTransitionsRateMetrics?.percent, range]
  );

  const ticketWithoutEpic = useMemo(
    () =>
      getMetricRange(
        range,
        TicketWithoutEpicAndStory?.count,
        "Ticket Without Epic"
      ),
    [TicketWithoutEpicAndStory?.count, range]
  );
  const ticketWithoutEstimate = useMemo(
    () =>
      getMetricRange(
        range,
        ticketWithoutEstimation,
        "Ticket Without Estimation"
      ),
    [ticketWithoutEstimation, range]
  );
  const hygineScore = useMemo(
    () => getMetricRange(range, hyginScore, "Hygine Score"),
    [hyginScore, range]
  );

  const unreviewedPR = useMemo(
    () => getMetricRange(range, risk?.pr, "Unreviwed PR"),
    [risk?.pr, range]
  );
  const commitWithPR = useMemo(
    () => getMetricRange(range, risk?.commit, "Commit With PR"),
    [risk?.commit, range]
  );
  const CommitWithoutPR = useMemo(
    () =>
      getMetricRange(
        range,
        risk?.commitWithoutPrMetricsValues,
        "Commit Without PR"
      ),
    [risk?.commitWithoutPrMetricsValues, range]
  );
  const PRReviewTimeRange = useMemo(
    () =>
      getMetricRange(
        range,
        prMergeCount?.PrReviewTime,
        "PR Review Time (Days)"
      ),
    [prMergeCount?.PrReviewTime, range]
  );

  const FirstTimePassIssue = useMemo(
    () =>
      getMetricRange(
        range,
        firstTimePassPercentageForRang,
        "First Time Pass Issue"
      ),
    [firstTimePassPercentageForRang, range]
  );

  const WorkInProgressAging = useMemo(
    () =>
      getMetricRange(range, teamAvgAging?.Time, "Work In Progess Aging (Days)"),
    [teamAvgAging?.Time, range]
  );

  const AverageFlowEfficiencyRisk = useMemo(
    () => getMetricRange(range, teamFlowEfficiency, "Average Flow Efficiency"),
    [teamFlowEfficiency, range]
  );

  const commitWithoutTicketRef = useMemo(() => {
    if (!commitwithoutData?.CommitWithoutTicketReference) return;
    const val =
      commitwithoutData?.CommitWithoutTicketReference?.match(/\d+\.\d+/);
    const number = val?.length > 0 ? +val[0] : 0;
    return getMetricRange(
      range,
      number?.toFixed(2),
      "Commit Without Ticket Ref"
    );
  }, [commitwithoutData?.CommitWithoutTicketReference, range]);

  const handleAutocompleteChange = (event, value) => {
    setSelectedMetrics(value);
  };

  const handleClose = () => {
    setOpenUtilizePerform(false);
  };

  return (
    <>
      <Box display={"flex"}>
        <EpicDropDown
          epicDropDownHandler={epicDropDownHandler}
          projectId={projectId}
        />
        <>
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div style={{ marginTop: -8 }}>
            <SelectField
              input={
                <Button
                  onClick={() => setOpenRange(true)}
                  style={{
                    border: "1px solid #b7b7b7",
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    height: "54px",
                  }}
                  endIcon={<DateRangeOutlinedIcon />}
                >
                  {getSelectedDate(date)}
                </Button>
              }
              onClick={() => setOpenRange(true)}
            />
            <RangeSelector
              open={openRange}
              setOpen={setOpenRange}
              pickerHandler={(val) => setDate(val)}
              value={date}
              isNextDate={false}
            />
          </div>
          {config.project.AllLevelSummary && (
            <div onClick={() => setIsSummary(!isSummary)}>
              <div className="border px-3 pt-2 pb-3 rounded cursorPointer mx-3">
                Summary
              </div>
            </div>
          )}
        </>
      </Box>
      {/* <div className="col-3 customCard shadow">
        <SmallCardGraph />
      </div> */}
      {/* =============================================> All Project Graphs <=================================================== */}
      <Grid container spacing={2} className={classes.graphCard}>
        {config.project.projectLevelMetricWorkBreakDown && (
          <Grid item xs={6}>
            <GraphCard
              title={"Work Breakdown"}
              subTitle={
                <div className={classes.subTitle}>
                  <span className={classes.subTitleAmt}>
                    {barData?.CodeChanges}
                  </span>
                  <span className={classes.subTitleText}>Code changes</span>
                  <div
                    onClick={() =>
                      viewMoreHandler(
                        "Work Breakdown"
                        // PlanningAccuracyStatusList
                      )
                    }
                    className={classes.viewMore}
                  >
                    View More
                  </div>
                </div>
              }
            >
              {/* <BarChart data={barData.data} /> */}
              <WorkAlocationChart data={barData.data} />
            </GraphCard>
          </Grid>
        )}

        {config.project.projectLevelMetricCycleTimeBreakDown && (
          <Grid item xs={6}>
            <GraphCard
              title={"Cycle Time Breakdown"}
              subTitle={
                <div className={classes.subTitle}>
                  <div
                    onClick={() =>
                      viewMoreHandler(
                        "Cycle Time Breakdown",
                        resourceAllocationStatusListData
                      )
                    }
                    className={classes.viewMore}
                  >
                    View More
                  </div>
                </div>
              }
              cardItem={totalCycleData}
            >
              <CycleTimeBreakDown data={cycleData} />
            </GraphCard>
          </Grid>
        )}
        {config.project.projectLevelMetricCycleTimePr && (
          <Grid item xs={6}>
            <GraphCard
              title={"Cycle Time (Pull Request)"}
              subTitle={
                <div className={classes.subTitle}>
                  <div
                    onClick={() =>
                      viewMoreHandler(
                        "Cycle Time (Pull Request)",
                        resourceAllocationStatusListData
                      )
                    }
                    className={classes.viewMore}
                  >
                    View More
                  </div>
                </div>
              }
            >
              <div style={{ paddingTop: "60px" }}>
                <CycleTimeBreakDown
                  data={cycleDataPullRequest}
                  title={"Cycle Time (Pull Request)"}
                />
              </div>
            </GraphCard>
          </Grid>
        )}
        {config.project.projectLevelMetricPlanningAccuracy && (
          <Grid item xs={6}>
            <GraphCard
              title={"Planning Accuracy"}
              subTitle={
                <div className={classes.subTitle}>
                  <span className={classes.subTitleAmt}>
                    {planningAccuracyData?.Planned}
                  </span>
                  <span className={classes.subTitleText}>Planned</span>
                  <div
                    onClick={() =>
                      viewMoreHandler(
                        "Planning Accuracy",
                        PlanningAccuracyStatusList
                      )
                    }
                    className={classes.viewMore}
                  >
                    View More
                  </div>
                </div>
              }
            >
              {/* <PlanningAccuracy data={planningAccuracyData} /> */}
              <WorkAlocationChart
                data={planningAccuracyData?.graph}
                name="panningAcc"
              />
            </GraphCard>
          </Grid>
        )}
        {config.project.projectLevelMetricWorkAllocation && (
          <Grid item xs={6}>
            <GraphCard
              title={"Work Allocation"}
              subTitle={
                <div
                  onClick={() =>
                    viewMoreHandler(
                      "Work Allocation",
                      resourceAllocationStatusListData
                    )
                  }
                  className={classes.viewMore}
                >
                  View More
                </div>
              }
            >
              <WorkAlocationChart
                data={workAllocationData}
                name="ResourceAllocation"
              />
            </GraphCard>
          </Grid>
        )}
      </Grid>
      {/* =============================================> Allsudo sync && sudo sysctl -w vm.drop_caches=3
 Project Metrics <=================================================== */}
      <Grid container spacing={2} className="my-4">
        {config.project.projectLevelMetricWorkCompleted && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#e8f6eb" }}
                  >
                    <img src={check} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>{workCompleted}</h3>
                    <h6>Work Complete</h6>
                    <h6>({config.type} )</h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricCommitWithoutTicketRef && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={msg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {commitwithoutData?.CommitWithoutTicketReference}
                    </h3>
                    <h6>
                      Commit Without Ticket Reference
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Commit Without Ticket Reference");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Commit Without Ticket Reference"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {commitWithoutTicketRef?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target:{" "}
                            {commitWithoutTicketRef?.From?.$numberDecimal}% to{" "}
                            {commitWithoutTicketRef?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={commitWithoutTicketRef?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={commitWithoutTicketRef?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricUnReviewPr && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={pr} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>{prMergeCount?.pr}</h3>
                    <h6>
                      Unreviewed PR
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Unreviewed PRs");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Unreviewed PR",
                            prReivewStatusList,
                            peReivewSubStatus
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {unreviewedPR?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {unreviewedPR?.From?.$numberDecimal}% to{" "}
                            {unreviewedPR?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={unreviewedPR?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={unreviewedPR?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricCommitWithPr && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>{prMergeCount?.commit}</h3>
                    <h6>
                      Commit With Pull Request
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Commit without pull request");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Commit with pull request"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {commitWithPR?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {commitWithPR?.From?.$numberDecimal}% to{" "}
                            {commitWithPR?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={commitWithPR?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricsCommitWithoutPr && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prMergeCount?.commitWithoutPrMetric}
                    </h3>
                    <h6>
                      Commit Without Pull Request
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Commit without pull request");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Commit without pull request"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {CommitWithoutPR?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {CommitWithoutPR?.From?.$numberDecimal}% to{" "}
                            {CommitWithoutPR?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={CommitWithoutPR?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricPrReviewTime && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prMergeCount?.PrReviewTime} (Days)
                    </h3>
                    <h6>
                      Pr Review Time
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Pr review time");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Pr Review Time"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {PRReviewTimeRange?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target:{PRReviewTimeRange?.From?.$numberDecimal}
                            (Days) to {PRReviewTimeRange?.To?.$numberDecimal}
                            (Days)
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={PRReviewTimeRange?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={PRReviewTimeRange?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricFirstTimePassIssue && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prMergeCount?.firstTimePass}
                    </h3>
                    <h6>
                      First Time Pass Issue
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("First Time pass issue");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "First Time pass issue",
                            resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {FirstTimePassIssue?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target: {FirstTimePassIssue?.From?.$numberDecimal}%
                            to {FirstTimePassIssue?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={FirstTimePassIssue?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricWorkInProgress && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={WIPimg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <>
                      <div
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        <div>
                          Work In Progress - {teamAvgAging?.StoryPoints}
                          <span
                            onClick={() => {
                              setIsDefinaionPopup(true);
                              setDefinationKPI("Work In Progress");
                            }}
                          >
                            <InfoIcon
                              style={{ color: "gray", marginLeft: "8px" }}
                            />
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                        }}
                        className="mb-1"
                      >
                        <div> Avg Age - {teamAvgAging?.Time}</div>
                      </div>
                    </>
                    <h6>
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "WIP Average Age"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {WorkInProgressAging?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target: {WorkInProgressAging?.From?.$numberDecimal}%
                            to {WorkInProgressAging?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={WorkInProgressAging?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricAvgFlowEfficiency && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={flowEfficiencyImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>{teamFlowEfficiency} %</h3>
                    <h6>
                      Average Flow Efficiency
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Average Flow Efficiency");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Average Flow Efficiency"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {AverageFlowEfficiencyRisk?.RiskCategory}{" "}
                            Risk
                          </h6>
                          <h6>
                            Target:{" "}
                            {AverageFlowEfficiencyRisk?.From?.$numberDecimal}%
                            to {AverageFlowEfficiencyRisk?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={AverageFlowEfficiencyRisk?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricSpeedingTransitionRate && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {SpeedingTransitionsRateMetrics.values}
                    </h3>
                    <h6>
                      Speeding Transitions Rate
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Speedy Transition");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Speeding Transitions Rate"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {speedyRateRisk?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {speedyRateRisk?.From?.$numberDecimal}% to{" "}
                            {speedyRateRisk?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Ensuring every ticket status are updated properly.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={speedyRateRisk?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricsStoryTicketWithoutEpicAndStory && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    {/* <img src={wipImg} alt="basket" /> */}
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {TicketWithoutEpicAndStory?.count} /{" "}
                      {TicketWithoutEpicAndStory?.totalIssues}
                    </h3>
                    <h6>
                      Ticket Without Epics/Stories
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Ticket Without Epics/Stories");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Ticket Without Epics/Stories"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {ticketWithoutEpic?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target: {ticketWithoutEpic?.From?.$numberDecimal}%
                            to {ticketWithoutEpic?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you connecting every ticket to
                              either stories or epics.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={ticketWithoutEpic?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {/* <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative py-2`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={UtilizationvsPerformance} alt="basket" />
                </div>
                <div className={classes.textContianer}> 
                  <h6>
                    Yearly Utilistaion / Performance
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Yearly Utilistaion / Performance");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() => setOpenUtilizePerform(true)}
                    >
                      View More
                    </span>
                  </h6>
                </div>
              </div>
            </CardContent>
            <Modal
              open={openUtilizePerform}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.bgPaper}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  my={2}
                  className="cursorPointer"
                >
                  <CancelIcon onClick={handleClose} />
                </Box>
                <YearlyLineChart />
              </Box>
            </Modal>
          </Card>
        </Grid> */}
        {/* <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={jiraIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "21px" }}>
                    {projectLevelMetrics[0]?.NewDefects}/
                    {projectLevelMetrics[0]?.SpCompleted}
                    <Box style={{ fontSize: "14px" }}>
                      {" "}
                      (No of Defect vs No of Story Completed)
                    </Box>
                  </h3>
                  <h6>Defect Injection</h6>
                </div>
              </div>
              <Box
                marginLeft={6}
                className={classes.viewMore}
                onClick={() => viewMoreHandler("Defect Injection")}
              >
                View More
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={jiraIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "21px" }}>
                    {projectLevelMetrics[0]?.NoOfDefects}/
                    {projectLevelMetrics[0]?.PreReleaseDefects}
                    <Box style={{ fontSize: "14px" }}>
                      {" "}
                      (Number of Defect vs Total Number Of Pre-Release Defect)
                    </Box>
                  </h3>
                  <h6>Defect Slippage</h6>
                </div>
              </div>
              <Box
                marginLeft={6}
                className={classes.viewMore}
                onClick={() => viewMoreHandler("Defect Slippage")}
              >
                View More
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={jiraIcon} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "21px" }}>
                    {projectLevelMetrics[0]?.WellFormedStories} %
                    <Box style={{ fontSize: "14px" }}>
                      {" "}
                      (No of Well-Formed Stories)
                    </Box>
                  </h3>
                  <h6>Well Formed Stories</h6>
                </div>
              </div>
              <Box
                marginLeft={6}
                className={classes.viewMore}
                onClick={() => viewMoreHandler("Well Formed Stories")}
              >
                View More
              </Box>
            </CardContent>
          </Card>
        </Grid> */}
        {config.project.projectLevelMetricsStoryBurnDown && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={jiraIcon} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "21px" }}>
                      {stroyBurnDown[0]?.SprintVelocity}/
                      {stroyBurnDown[0]?.RemainingWork}
                      <h6>
                        Task Burndown{" "}
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Task Burndown");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </h6>
                      <Box style={{ fontSize: "14px" }}>
                        {" "}
                        (Scope Item to be Burnt)
                      </Box>
                      <div style={{ fontSize: "14px" }}>
                        (
                        {stroyBurnDown[0]?.EstimatedCompletionDate
                          ? format(
                              new Date(
                                stroyBurnDown[0]?.EstimatedCompletionDate
                              ),
                              "dd-MMM-yyyy hh:mm a"
                            )
                          : "Date"}
                        )
                      </div>
                    </h3>
                  </div>
                </div>
                <Box
                  marginLeft={6}
                  className={classes.viewMore}
                  onClick={() => viewMoreHandler("Story Burndown")}
                >
                  View More
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricsDevAttentionTime && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {developerAttentionTimeValues?.totalTimeElapsed} Hrs
                    </h3>
                    <h6>
                      Dev Attention Time
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Dev Attention Time");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Dev Attention Time"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                              title={
                                <>
                                  <h6>
                                    Status: {riskAndRange?.speedyRateRisk?.risk}{" "}
                                    Risk
                                  </h6>
                                  <h6>
                                    Target: {riskAndRange?.speedyRateRisk?.from}
                                    % to {riskAndRange?.speedyRateRisk?.to}%
                                  </h6>
                                  <h6>Recommendations</h6>
                                </>
                              }
                            >
                              <div className="metricStatus">
                                <HealthColorWrapper
                                  status={riskAndRange?.speedyRateRisk?.risk}
                                ></HealthColorWrapper>
                              </div>
                            </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricsTicketWithoutEstimation && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {ticketWithoutEstimation || 0}
                    </h3>
                    <h6>
                      Ticket Without Estimation
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Ticket Without Estimation");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Ticket Without Estimation"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {ticketWithoutEstimate?.risk} Risk</h6>
                          <h6>
                            Target: {ticketWithoutEstimate?.from}% to{" "}
                            {ticketWithoutEstimate?.to}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using estimation for every
                              ticket , ensuring better delivery.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={ticketWithoutEstimate?.risk}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project
          .projectLevelMetricNumberOfEpicAndStoryAndTotalStoryPoint && (
          <>
            {" "}
            <Grid item xs={3}>
              <Card
                className={`${classes.card} customCard position-relative py-2`}
              >
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={wipImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {numberOfEpics?.count || "0"}
                      </h3>
                      <h6>
                        Number of Epics
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Number of Epics");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler(
                              "Number of Epics"
                              // resourceAllocationStatusListData
                            )
                          }
                        >
                          View More
                        </span>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                className={`${classes.card} customCard position-relative py-2`}
              >
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={wipImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {numberOfStoryPointsCompleted?.count} /{" "}
                        {numberOfStoryPointsCompleted?.totalIssues}
                      </h3>
                      <h6>
                        Number of Story Completed / Number of Story
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Number of Story Completed");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler(
                              "Number of Story Completed"
                              // resourceAllocationStatusListData
                            )
                          }
                        >
                          View More
                        </span>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                className={`${classes.card} customCard position-relative py-2`}
              >
                <CardContent>
                  <div className={classes.cardContaner}>
                    <div
                      className={classes.icon}
                      style={{ backgroundColor: "#fdeeee" }}
                    >
                      <img src={wipImg} alt="basket" />
                    </div>
                    <div className={classes.textContianer}>
                      <h3 style={{ fontSize: "24px" }}>
                        {totalStoryPoint.count || "0"}
                      </h3>
                      <h6>
                        Total Story Point
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Total Story Point");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                        <br />
                        <span
                          className={classes.viewMore}
                          onClick={() =>
                            viewMoreHandler(
                              "Total Story Point"
                              // resourceAllocationStatusListData
                            )
                          }
                        >
                          View More
                        </span>
                      </h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
        {config.project.projectLevelMetricKsi && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {isKsiIndex?.toFixed(2)}
                    </h3>
                    <h6>
                      Knowledge Sharing Index
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Knowledge Sharing Index");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Knowledge Sharing Index"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricHyginScore && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {hyginScore?.toFixed(2)} %
                    </h3>
                    <h6>
                      Hygin Score
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Hygin Score");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Hygin Score"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelPrSize && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prSize?.toFixed()} (Count)
                    </h3>
                    <h6>
                      Pr Size (Number of code changes)
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Pr Size");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Pr Size"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelPrSizeDefectResoultionTime && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {defectResolutionTime?.toFixed()} (Day's)
                    </h3>
                    <h6>
                      Defect resolution time
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Defect resolution time");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Defect resolution time"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelTestCaseEfficiency && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {testEfficiency?.defectResolutionPercentage?.toFixed()} %
                    </h3>
                    <h6>
                      Test Case Efficiency
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Test Case Efficiency");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Test Case Efficiency"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project?.projectLevelMetricDefectInBacklog && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {DefectInBacklog} (Count)
                    </h3>
                    <h6>
                      Defect In Backlog
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Defect In Backlog");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Defect In Backlog"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project?.projectLevelMetricDefectInProgress && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {defectInProgress} (Count)
                    </h3>
                    <h6>
                      Defect In Progress
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Defects In Progress");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Defect In Progress"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project?.projectLevelMetricTicketWithoutCommitRef && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {ticketWithoutCommitReference || 0}
                    </h3>
                    <h6>
                      Ticket Without Commit Reference
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Ticket Without Commit Reference");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Ticket Without Commit Reference"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {/* =====================> Gofyend <================== */}
        {config.project.projectLevelBugIntroduceAndFixed && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {bugIntroAndDone?.totalBugsDone || 0}/
                      {bugIntroAndDone?.totalBugsIntroduced || 0} (
                      {bugIntroAndDone?.bugDoneRatio?.toFixed(2) || 0})
                    </h3>
                    <h6>
                      Bug Fixed / Bug Introduced
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Bug Introduced / Bug Fixed");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Bug Introduced / Bug Fixed"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricProdBugIntroAndFeature && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prodBugIntroAndFeature?.totalBugsIntroduced || 0}/
                      {prodBugIntroAndFeature?.totalTicketIntroduced || 0} (
                      {prodBugIntroAndFeature?.bugRatio?.toFixed(2) || 0})
                    </h3>
                    <h6>
                      Production Bugs Introduce / Features
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI(
                            "Production Bugs Introduce / Features"
                          );
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Production Bugs Introduce / Features"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricTotalBugIntroduce && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {noOfBugIntroduce?.totalBugsIntroduced || 0}
                    </h3>
                    <h6>
                      No of Prod Bugs introduced in a week
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI(
                            "No of Prod Bugs introduced in a week"
                          );
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "No of Prod Bugs introduced in a week"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricTotalProdBugFixed && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {noOfProdBugFixed?.totalBugsIntroduced || 0}
                    </h3>
                    <h6>
                      No of Prod Bugs Fixed
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("No of Prod Bugs Fixed");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "No of Prod Bugs Fixed"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricTotalNumberFeature && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {totalNumberOfFeature?.totalBugsIntroduced || 0}
                    </h3>
                    <h6>
                      Total Number of Features
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Total Number of Features");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Total Number of Features"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricProdBugIntroCheckIn && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prodBugVsCheckIn?.totalBugsIntroduced || 0}/
                      {prodBugVsCheckIn?.totalBugsCheckedIn || 0}(
                      {prodBugVsCheckIn?.ratio?.toFixed(2) || 0})
                    </h3>
                    <h6>
                      Production Bugs Introduce / Check in
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI(
                            "Production Bugs Introduce / Check in"
                          );
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Production Bugs Introduce / Check in"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.projectLevelMetricTotalCheckIn && (
          <Grid item xs={3}>
            <Card
              className={`${classes.card} customCard position-relative py-2`}
            >
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={wipImg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {totalNumberOfBugCheckIn?.totalBugsIntroduced || 0}
                    </h3>
                    <h6>
                      Total Number of Checkins in a week
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI(
                            "Total Number of Checkins in a week"
                          );
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Total Number of Checkins in a week"
                            // resourceAllocationStatusListData
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    {/* <Tooltip
                      title={
                        <>
                          <h6>Status: {hygineScore?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {hygineScore?.From?.$numberDecimal}% to{" "}
                            {hygineScore?.To?.$numberDecimal}%
                          </h6>
                          <h6>Recommendations</h6>
                          <ul>
                            <li>
                              Make sure that you are using your project
                              manageement tool properly , ensuring consistent
                              data hygiene.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        <HealthColorWrapper
                          status={hygineScore?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      {/* ======================================> All Issue level Graph and Table <======================================= */}
      {issueType && (
        <>
          {![
            ...excudePermission,
            "First Time Pass Issue",
            "Story Burndown",
            "WIP Average Age",
            "Defect Slippage",
            "Defect Injection",
            "Well Formed Stories",
            "Knowledge Sharing Index",
          ].includes(issueType) && (
            <Card style={{ marginTop: 20 }}>
              <Trend
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}
          <>
            {[...excudePermission, "Pr Review Time"].includes(issueType) && (
              <Card style={{ marginTop: 20 }}>
                <Trend
                  issueType={issueType}
                  projectId={projectId}
                  epic={epicData?.url}
                />
              </Card>
            )}
          </>
          {/* WIP Trend */}
          {["WIP Average Age"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <WIPTrend issueType={issueType} projectId={projectId} />
            </Card>
          )}
          {["First Time pass issue"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <IssueLevelTable
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}
          {[
            "Cycle Time Breakdown",
            "Dev Attention Time",
            "Production Bugs Introduce / Check in",
            "Total Number of Checkins in a week",
            "Total Number of Features",
          ].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <IssueLevelCycle
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}
          {["Knowledge Sharing Index"].includes(issueType) && (
            <MultipleTooltipIndex
              epic={epicData?.url}
              issueType={issueType}
              projectId={projectId}
            />
          )}
          {["Speeding Transitions Rate"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <IssueLevelSpeeding
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}

          {[
            "Story Burndown",
            "Defect Slippage",
            "Defect Injection",
            "Well Formed Stories",
          ].includes(issueType) && (
            <RelevanceLabSprintTrend
              metricName={issueType}
              projectId={projectId}
              date={date}
              allTeamList={[]}
            />
          )}

          {[
            "Average Flow Efficiency",
            "Pr Review Time",
            "Story Burndown",
            "Speeding Transitions Rate",
            "Well Formed Stories",
            "Dev Attention Time",
            "Cycle Time Breakdown",
            "Knowledge Sharing Index",
            "Production Bugs Introduce / Check in",
            "Total Number of Checkins in a week",
            "Total Number of Features",
            // "Hygin Score",
          ].includes(issueType) ? null : (
            <Card style={{ marginTop: 20 }}>
              <IssueLevel
                issueType={issueType}
                assigneeList={
                  ![
                    ...excudePermission,
                    "Unreviewed PR",
                    "WIP Average Age",
                    "Pr Size",
                    "Test Case Efficiency",
                  ].includes(issueType)
                    ? assigneeList
                    : []
                }
                statusList={
                  ![
                    ...excudePermission,
                    "WIP Average Age",
                    // "Ticket Without Epics/Stories",
                    "Defect Slippage",
                    "Defect Injection",
                    "Pr Size",
                    "Test Case Efficiency",
                  ].includes(issueType)
                    ? statusList
                    : []
                }
                subStatus={selectedSubStatus}
                projectId={projectId}
                autoScroll={[
                  ...excudePermission,
                  "Unreviewed PR",
                  "WIP Average Age",
                ].includes(issueType)}
                isDate={["Work Breakdown"].includes(issueType) ? false : true}
                isCheckList={
                  ["Work Allocation"].includes(issueType) ? true : false
                }
                epic={epicData?.url}
              />
            </Card>
          )}
        </>
      )}
      <Drawer
        anchor={"right"}
        open={isSummary}
        onClose={() => setIsSummary(false)}
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <h4 className="text-center mb-4 border-bottom pb-3 border-dark">
            Project Level Analysis Report
          </h4>
          <section style={{ marginBottom: "20px" }}>
            <h5>1. Project Level Investment:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: Workload distribution shows significant disparities.
              </li>
              <li>
                Inference: Uneven distribution might lead to burnout for heavily
                loaded members and underutilization of others.
              </li>
              <li>
                Alert: Potential delays or quality issues for overburdened team
                members.
              </li>
              <li>
                Decision: Reallocate tasks to balance workloads effectively.
              </li>
              <li>Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Balanced workloads improve team morale, efficiency,
                  and reduce errors. o Negative (if unaddressed): Increased
                  attrition, lower productivity, and potential project delays.
                </li>
                <li>
                  Negative (if unaddressed): Increased attrition, lower
                  productivity, and potential project delays.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>2. Planning Accuracy:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>Summary: 71% of planned tasks completed, 27% in progress.</li>
              <li>
                Inference: Backlog requires attention to prevent accumulation.
              </li>
              <li>Alert: Risk of missed deadlines if backlog persists.</li>
              <li>
                Decision: Prioritize backlog resolution and investigate
                blockers.
              </li>
              <li>Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Resolving the backlog enhances on-time delivery and
                  client satisfaction.
                </li>
                <li>
                  Negative (if unaddressed): Overdue tasks could damage
                  stakeholder confidence.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>3. Cycle Time Breakdown:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: Longest stage is "Open" (3.9 days); 76% of tasks are
                within average durations.
              </li>
              <li>
                Inference: Inefficiencies in the "Open" stage delay task
                initiation.
              </li>
              <li>Alert: Bottlenecks in "Open" can reduce throughput.</li>
              <li>Decision: Streamline task prioritization and assignment.</li>
              <li> Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Faster task initiation accelerates overall cycle
                  time.
                </li>
                <li>
                  Negative (if unaddressed): Cumulative delays in task pipelines
                  slow project delivery.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>4. Work Breakdown:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: Effort heavily spent on rework (25.08%) and helping
                others (35.25%).
              </li>
              <li>
                Inference: High rework indicates process inefficiencies or
                unclear requirements.
              </li>
              <li>Alert: Rework reduces capacity for new tasks.</li>
              <li>
                Decision: Improve task specifications and process clarity.
              </li>
              <li> Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Reduced rework increases productivity and task
                  velocity.
                </li>
                <li>
                  Negative (if unaddressed): Persistent rework could lead to
                  resource exhaustion.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>5. Pull Request Cycle Time:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>Summary: Extended idle and review times.</li>
              <li>
                Inference: Delays in workflow due to inefficient pipeline
                management.
              </li>
              <li>Alert: Idle time reduces overall efficiency.</li>
              <li>
                Decision: Set stricter deadlines for reviews and improve task
                handoffs.
              </li>
              <li> Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Optimized pipeline reduces time-to-market for
                  changes.
                </li>
                <li>
                  Negative (if unaddressed): Bottlenecks extend delivery times,
                  impacting client satisfaction.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>6. Speedy Transition:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>Summary: 97.58% of tasks transitioned quickly.</li>
              <li>Inference: Workflow transitions are efficient overall.</li>
              <li>Decision: Maintain and monitor current standards.</li>
              <li> Impact Analysis:</li>
              <ul>
                <li>
                  Positive: High transition efficiency maintains project
                  momentum.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>7. Tickets Without Metadata:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: Missing metadata for 939 tickets (Story/Epic) and 152
                tickets (Estimation).
              </li>
              <li>
                Inference: Lack of metadata hampers tracking and planning
                accuracy.
              </li>
              <li>
                Alert: Inefficiencies in prioritization and resource allocation.
              </li>
              <li>Decision: Enforce mandatory metadata completion.</li>
              <li> Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Improved tracking leads to better forecasting and
                  planning.
                </li>
                <li>
                  Negative (if unaddressed): Delays in resolving critical issues
                  due to poor visibility.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>8. Commit Statistics:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: 88.69% of commits lack PR, limiting peer reviews.
              </li>
              <li>
                Inference: Insufficient reviews may lower code quality and
                increase defects.
              </li>
              <li>Alert: Higher risk of undetected bugs.</li>
              <li>Decision: Mandate peer reviews for all commits.</li>
              <li> Impact Analysis:</li>
              <ul>
                <li>
                  Positive: Increased code quality reduces post-release defects.
                </li>
                <li>
                  Negative (if unaddressed): Low-quality code can lead to rework
                  and client dissatisfaction.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>9. Dev Attention:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: Total effort spent is ~100 days (~8.66M seconds).
              </li>
              <li>
                Inference: Large resource allocation with potential
                inefficiencies.
              </li>
              <li>Decision: Conduct time audits and optimize focus areas.</li>
              <li> Impact Analysis:</li>
              <ul>
                <li>Positive: Time audits increase resource utilization.</li>
                <li>
                  Negative (if unaddressed): Misallocated time leads to waste.
                </li>
              </ul>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>10. Pull Request Reviews:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Summary: 8.54% of PRs unreviewed; average review time is 1.69
                days.
              </li>
              <li>
                Inference: Moderately efficient reviews with scope for
                improvement.
              </li>
              <li>Decision: Implement stricter SLAs for reviews.</li>
              <li> Impact Analysis:</li>
              <ul>
                <li>Positive: Faster reviews enhance delivery speed.</li>
                <li>
                  Negative (if unaddressed): Delayed reviews slow down the
                  pipeline.
                </li>
              </ul>
            </ul>
          </section>
        </div>
      </Drawer>
    </>
  );
}

export default ProjectDashboard;
