import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import Layout from "./hoc/layout";
import RiskAdvisor from "./pages/riskAdvisor";
import MaterialLayout from "./hoc/layout/materialLayout";
import Auth from "./pages/auth";
import { GlobalData } from "./context/globalData";
import ScrollToTop from "./components/scroll/scrollToTop";
import { I18nextProvider } from "react-i18next";
import common_en from "../src/translater/en.json";
import i18next from "i18next";

import axios from "../src/utils/axiosHelper";
// import Azure from "./screens/authentication/azure/azure";
// import BitBucket from "./screens/authentication/bitBucket/bitBucket";
// import GitHub from "./screens/authentication/gitHub/gitHub";
// import GitLab from "./screens/authentication/gitLab/gitLab";
// import Jira from "./screens/authentication/jira/jira";
// import Sonarqube from "./screens/authentication/sonarqube/sonarqube";
import Routes from "./pages/auth/routes/Routes";
import Loader from "./components/loader/Loader";
import DefinaionPopup from "./components/definationPopup/DefinaionPopup";

function App() {
  const [lenguage, setLenguage] = useState([common_en]);

  useEffect(() => {
    axios
      .get("/api/translation/get/EN")
      .then((res) => {
        setLenguage(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: "en", // language to use
    resources: {
      en: {
        common: lenguage, // 'common' is our custom namespace
      },
      // de: {
      //   common: common_de,
      // },
    },
  });
  // theme.js

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#5EBDCC",
      },
      // secondary: {
      //   main: '#000000',
      // },
      error: {
        main: "#cc5e6b",
      },
    },
    // Other theme configuration options...
  });

  const { isLogin, currentRender, definationKPI } = useContext(GlobalData);
  if (isLogin) {
    return (
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18next}>
          <Loader />
          <Routes />
          {definationKPI && <DefinaionPopup />}
        </I18nextProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <MaterialLayout>
          <Layout>
            <Switch>
              <Route path="/" component={Auth} />
            </Switch>
          </Layout>
        </MaterialLayout>
      </ThemeProvider>
    );
  }
}

export default App;
